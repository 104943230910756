import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Light, H3, Scrollbar, HBox, Spinner} from '@bakkt/components'
import {FundingAccount} from 'components/FundingAccount'
import {ApiHook, embeddedPartnerApi} from 'api'
import {useStore, TransactTypeEnum, TransactType} from 'store'
import {showTransactionErrorDialog} from 'components/ErrorDialog'

type Props = {
	transactionType: TransactTypeEnum
}

export const SelectFundingAccount = ({transactionType}: Props) => {
	const {t} = useTranslation()
	const {fiatCurrencyPreference, setFundingAccounts, fundingAccounts} = useStore()

	const {loading, response, error, request} = ApiHook(() =>
		embeddedPartnerApi.fetchFundingAccounts(fiatCurrencyPreference as any),
	)

	useEffect(() => {
		request()
	}, [])

	useEffect(() => {
		if (error) {
			showTransactionErrorDialog()
		}
	}, [error])

	useEffect(() => {
		setFundingAccounts(response || [])
	}, [response])

	return (
		<>
			<H3 data-testid={'section-title'} style={{marginTop: 50, marginBottom: 20}}>
				{transactionType === TransactType.SELL ? t('label.destination') : t('label.fundingSource')}
			</H3>

			{(() => {
				if (loading) {
					return (
						<HBox style={{justifyContent: 'center'}}>
							<Spinner />
						</HBox>
					)
				} else if (fundingAccounts && fundingAccounts.length > 0) {
					return (
						<Scrollbar height={(response?.length || 0) > 3 ? '250px' : '100vh'}>
							{response?.map(account => (
								<FundingAccount key={account.externalAccountRef} source={account} />
							))}
						</Scrollbar>
					)
				} else {
					return <Light style={{textAlign: 'center'}}>{t('crypto.noFundingAccounts')}</Light>
				}
			})()}
		</>
	)
}
