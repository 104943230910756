import {useEffect, useState} from 'react'
import {showSpeedbumpDialog} from 'components/SpeedbumpDialog'
import {RoutesName} from 'constants/routes'
import {useStore} from 'store'
import {useNavigate} from 'hooks'
import {getSessionStorageKey} from 'utils/sessionStorage'
import {SessionStorageKeys} from 'utils/sessionStorageProperties'

let hasLoadedTheme = false

export const useSpeedbumpDialog = () => {
	const {partner} = useStore()
	const navigate = useNavigate()
	const hasAcceptedSpeedbumpDialog = getSessionStorageKey(SessionStorageKeys.ACCEPTED_SPEEDBUMP_MESSAGE)
	const [navigateToTerms, setNavigateToTerms] = useState<boolean>(false)

	useEffect(() => {
		if (partner?.speedbumpMessage && !hasAcceptedSpeedbumpDialog) {
			if (hasLoadedTheme) {
				showSpeedbumpDialog(partner, setNavigateToTerms)
			} else {
				// This timeout is necessary because theme updates aren't updated to the dialog after opening
				// so we need to wait for the theme to be applied before opening up the dialog
				setTimeout(() => showSpeedbumpDialog(partner, setNavigateToTerms), 50)
				hasLoadedTheme = true
			}
		}
	}, [partner, hasAcceptedSpeedbumpDialog])

	useEffect(() => {
		if (navigateToTerms) navigate(RoutesName.termsOfUse)
	}, [navigateToTerms])
}
