import React from 'react'
import NumberFormat from 'react-number-format'

const onValueChange = ({values, onChange, name}: any) => {
	onChange?.({
		target: {
			name,
			value: values.value,
		},
	})
}

export const NumberFormatter = ({inputRef, onChange, name, ...rest}: any) => {
	return (
		<NumberFormat
			{...rest}
			getInputRef={inputRef}
			onValueChange={values => onValueChange({values, onChange, name})}
			format='(###) ###-####'
		/>
	)
}

export const PostalCodeFormatter = ({inputRef, onChange, name, ...rest}: any) => {
	return (
		<NumberFormat
			{...rest}
			getInputRef={inputRef}
			onValueChange={values => onValueChange({values, onChange, name})}
			format='#####'
		/>
	)
}

export const OtpFormatter = ({inputRef, onChange, name, ...rest}: any) => {
	return (
		<NumberFormat
			{...rest}
			getInputRef={inputRef}
			onValueChange={values => onValueChange({values, onChange, name})}
			format='# # # # # #'
			allowEmptyFormatting
			mask='•'
		/>
	)
}

export const DateFormatter = ({inputRef, onChange, name, ...rest}: any) => {
	return (
		<NumberFormat
			{...rest}
			getInputRef={inputRef}
			onValueChange={values => onValueChange({values, onChange, name})}
			placeholder='MM / DD / YYYY'
			format='## / ## / ####'
			mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
		/>
	)
}

export const SsnFormatter = ({inputRef, onChange, name, ...rest}: any) => {
	return (
		<NumberFormat
			{...rest}
			getInputRef={inputRef}
			onValueChange={values => onValueChange({values, onChange, name})}
			format='# # # - # # - # # # #'
		/>
	)
}
