import {useEffect, useState} from 'react'
import axios from 'axios'

export const useGetTrackingId = () => {
	const [xTrackingId, setXTrackingId] = useState('')
	useEffect(() => {
		axios.interceptors.response.use(function (response) {
			const xTrackingId = response.headers['x-tracking-id']
			if (xTrackingId) {
				setXTrackingId(xTrackingId)
			}
			return response
		})
	})
	return xTrackingId
}
