import moment from 'moment'

export const toTitleCase = function (input: string) {
	return input.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
	})
}

export const formatPhoneNumberForServer = (phone: string) => {
	if (!phone) return
	return `+1${phone.replace(/[ ()-]/gi, '')}`
}

export const formatDobForServer = (dob: string) => {
	if (!dob) return

	if (moment(dob, 'YYYY-MM-DD').format('YYYY-MM-DD') === dob) {
		return dob
	}
	return moment(dob, 'MM/DD/YYYY').format('YYYY-MM-DD')
}

export const formatDobForFrontend = (dob: string) => {
	if (!dob) return

	if (moment(dob, 'MM/DD/YYYY').format('MM/DD/YYYY') === dob) {
		return dob
	}
	return moment(dob, 'YYYY-MM-DD').format('MM/DD/YYYY')
}

export const formatSsnForServer = (ssn: string) => {
	if (!ssn) return
	return ssn.replace(/[ -]/gi, '')
}

export const formatCamelCase = (input: string): string => {
	return input.toLowerCase().split(/\s+|_/).reduce((prev, curr, idx) => prev += (idx > 0) ? `${curr[0].toUpperCase()}${curr.slice(1)}` : curr)
}