import React, {useEffect, useRef, useState} from 'react'
import RenderDomServer from 'react-dom/server'
import {AgGridReact, AgGridReactProps} from 'ag-grid-react'
import './DataGridStyles.scss'
import {ReactElement} from 'react'
import {CellContextMenuEvent, GridApi, ColumnApi} from 'ag-grid-community'
import {Input} from '../input/Input'
import {ShadowContainer} from '../shadowContainer/ShadowContainer'
import {Button} from '../button/Button'
import {HBox} from '../container/HBox'
import {GridColumnProps} from './GridColumn'
import SetFilter from './SetFilter'
import CellContextMenu from './CellContextMenu'
import {Icons, Utils} from '../..'
import {doesExternalFilterPass, configureFilters} from './Filters'
import {Filter, SearchCriteria} from './FilterModel'
import {Badge} from '../badge/Badge'
import {color} from 'src/color/color'
import {Tooltip} from '@material-ui/core'
import moment from 'moment'
import {formatDateTimeWithTimeZone} from 'src/utils/DateUtils'
import Application from 'src/utils/Application'
import {Separator} from '../separator'
import {VBox} from '../container/VBox'
export interface DataGridProps extends AgGridReactProps {
	viewId?: string
	children: Array<ReactElement<GridColumnProps>>
	rootHeight?: string
	rootWidth?: string
	hideQuickFilter?: boolean
	leftToolbar?: () => JSX.Element
	renderToolbar?: () => JSX.Element
	quickFilterPlaceholderText?: string
	quickFilterDefaultText?: string
	fireRefresh?: (arg0: string) => void
	hideFilterConfiguration?: boolean
	onClearAllDefaultFilters?: Filter[]
	style?: any
	renderPopupMenu?: (e: CellContextMenuEvent) => JSX.Element
	hideShadow?: boolean
	align?: 'center' | 'left' | 'right'
	maxRowCountVisible?: number
	actionBar?: () => JSX.Element
	hideHeaderBottomBorder?: boolean
	allowColmnHideMoveAndRememberPreference?: boolean
}

const ICON_CARET_DOWN = RenderDomServer.renderToString(<Icons.ChevronDownSmll style={{height: 18}} />)
const ICON_CARET_RIGHT = RenderDomServer.renderToString(<Icons.ChevronRightSmll style={{height: 18}} />)
const ICON_SORT_ASC = RenderDomServer.renderToString(<Icons.ChevronUpSmll style={{height: 18}} />)
const ICON_SORT_DSC = RenderDomServer.renderToString(<Icons.ChevronDownSmll style={{height: 18}} />)

export const DataGrid: React.FC<DataGridProps> = (props: DataGridProps) => {
	const tFilterColumns = props.children.filter(f => f.props.tFilter != null).map(f => f.props)
	const apiRef = useRef<GridApi>()
	const columnApiRef = useRef<ColumnApi>()
	const [quickFilter, setQuickFilter] = useState<string>('')

	const initDefaultFilters = () => {
		const existingFilters = props.viewId && localStorage.getItem(props.viewId + '.tfilters')
		if (existingFilters !== null) {
			return existingFilters
		}
		return generateDefaultFilters()
	}
	const generateDefaultFilters = (): string => {
		const defaultFilterColProps: GridColumnProps[] = props.children
			.filter(
				f =>
					f.props.tFilter != null &&
					f.props.defaultFilterValues != null &&
					f.props.defaultFilterValues.length > 0,
			)
			.map(f => f.props)
		const defaultFilters = JSON.stringify(
			defaultFilterColProps.map(prop => {
				return {
					fieldName: prop.field || '',
					filterType: prop.tFilter,
					id: Math.random(),
					fieldValues: prop.defaultFilterValues,
					searchCriteria: prop.defaultFilterSearchCriteria,
					isUndeletable: true,
				}
			}),
		)
		localStorage.setItem(props.viewId + '.tfilters', defaultFilters)
		return defaultFilters
	}

	const initTFilters = initDefaultFilters()

	const [filters, setFilters] = useState<Filter[]>(initTFilters ? JSON.parse(initTFilters) : [])
	const filtersRef = useRef<Filter[]>()

	const [rowCount, setRowCount] = useState(
		(props.rowData ? props.rowData.length : props.datasource ? props.datasource.rowCount : undefined) || 0,
	)
	const [rowDataValue, setRowDataValue] = useState('')
	useEffect(() => {
		if (props.datasource) {
			// @ts-ignore
			props.datasource.onRowsLoad = rowCount => {
				setRowCount(rowCount || 1)
				setRowDataValue(rowCount)
			}
		} else {
			setRowCount(props.rowData?.length || 1)
		}
	}, [props.datasource, props.rowData])
	useEffect(() => {
		if (apiRef.current) {
			if (rowDataValue != '') {
				apiRef.current?.hideOverlay()
			} else {
				apiRef.current?.showNoRowsOverlay()
			}
		}
	}, [rowDataValue, filters])
	const rowCountForHeight = props.maxRowCountVisible ? Math.min(rowCount, props.maxRowCountVisible) : undefined

	const height =
		props.rootHeight ||
		(rowCountForHeight &&
			`${
				rowCountForHeight * (apiRef.current?.getSizesForCurrentTheme().rowHeight || 48) +
				(apiRef.current?.getSizesForCurrentTheme().headerHeight || 58)
			}px`) ||
		'max-content'
	const onGridReady = (params: any) => {
		apiRef.current = params.api
		columnApiRef.current = params.columnApi
		if (props.onGridReady) {
			props.onGridReady(params)
		}
		// @ts-ignore
		if (props.datasource) props.datasource.externalFilters = filters
		//@ts-ignore
		params.api.onFilterChanged()
		const sortModel = localStorage.getItem(props.viewId + '.sort')
		if (props.viewId && sortModel) {
			params.api?.setSortModel(sortModel ? JSON.parse(sortModel) : [])
		}
		if (props.viewId) {
			const colmnPreference = localStorage.getItem(`${props.viewId + '.columnPreference'}`)
			if (colmnPreference) {
				const parsedColmnPreference = JSON.parse(colmnPreference)
				params.columnApi?.setColumnState(parsedColmnPreference)
			}
		}
	}

	const handleFilterConfiguration = async (preSelectedFilter?: Filter) => {
		let filterColumns: GridColumnProps[] = tFilterColumns
		if (preSelectedFilter) {
			filterColumns = tFilterColumns.filter(f => f.field === preSelectedFilter.fieldName)
		}
		const newFilter = await configureFilters(
			filterColumns.map(c => {
				const {field, headerName, tFilter, filterCriterias, filterValues, dateRangeValidatorFn} = c
				return {
					dataField: field || '',
					headerText: headerName || field || '',
					filterType: tFilter || 'text',
					filterCriterias,
					filterValues,
					dateRangeValidatorFn,
				}
			}),
			preSelectedFilter,
		)
		if (newFilter && newFilter.fieldName && newFilter.fieldValues && newFilter.searchCriteria) {
			const newFilters = [...filters]
			if (preSelectedFilter) {
				newFilter.isUndeletable = preSelectedFilter.isUndeletable
				const originalFilterIndex = filters.findIndex(f => f.id === preSelectedFilter.id)
				newFilters.splice(originalFilterIndex, 1, newFilter)
			} else {
				newFilters.push(newFilter)
			}
			props.viewId && localStorage.setItem(props.viewId + '.tfilters', JSON.stringify(newFilters))
			setFilters(newFilters)
		}
		Utils.hideDialog()
	}

	useEffect(() => {
		const api = apiRef.current
		filtersRef.current = filters
		// @ts-ignore
		if (props.datasource) props.datasource.externalFilters = filters
		api?.onFilterChanged()
	}, [filters])

	const showToolBar =
		!props.hideQuickFilter ||
		!props.hideFilterConfiguration ||
		props.renderToolbar != null ||
		props.leftToolbar != null

	const renderLabel = (filter: Filter) => {
		const stringLabel = renderFieldValues(filter)
		return (
			<HBox>
				<div style={{marginTop: 'auto', marginBottom: 'auto'}}>{stringLabel}</div>
				<Icons.Edit style={{transform: 'scale(0.55)', marginTop: 1}} fill={color.white} />
			</HBox>
		)
	}
	const renderFieldValues = (filter: Filter): string => {
		const fieldValues = filter.fieldValues
		if (filter.searchCriteria === SearchCriteria.RELATIVETIME) {
			return fieldValues[0]
		}
		if (
			fieldValues.length === 2 &&
			filter.searchCriteria === SearchCriteria.BETWEEN &&
			filter.filterType === 'datetime'
		) {
			return (
				formatDateTimeWithTimeZone(fieldValues[0], Application.timezone) +
				' - ' +
				formatDateTimeWithTimeZone(fieldValues[1], Application.timezone)
			)
		} else if (fieldValues.length === 2 && filter.searchCriteria === SearchCriteria.BETWEEN) {
			return moment(fieldValues[0]).format('MM-DD-YYYY') + ' - ' + moment(fieldValues[1]).format('MM-DD-YYYY')
		} else if (filter.filterType === 'set' && filter.searchCriteria === SearchCriteria.IN) {
			return fieldValues.join(' | ')
		} else if (
			filter.searchCriteria === SearchCriteria.AFTER ||
			filter.searchCriteria === SearchCriteria.BEFORE ||
			filter.searchCriteria === SearchCriteria.BEFOREORNULL ||
			filter.searchCriteria === SearchCriteria.AFTERORNULL
		) {
			return fieldValues[0]
		} else if (fieldValues.length === 2) {
			return fieldValues[0] + ' - ' + fieldValues[1]
		} else if (fieldValues.length === 1 && filter.filterType === 'datetime') {
			return formatDateTimeWithTimeZone(fieldValues[0], Application.timezone)
		} else if (fieldValues.length === 1) {
			return fieldValues[0]
		}
		return ''
	}

	const renderTooltipTitle = (filter: Filter): string => {
		const column = tFilterColumns.find(col => col.field === filter.fieldName)
		if (column) {
			const columnDisplayName = column.headerName ? column.headerName : column.field
			return columnDisplayName + ' - ' + filter.searchCriteria
		}
		return ''
	}

	const renderQuickFilter = () => (
		<React.Fragment>
			<HBox horizontalGap={10}>
				<HBox horizontalGap={10}>
					<Input
						type='text'
						label={props.quickFilterPlaceholderText || 'Search'}
						style={{width: 400}}
						value={quickFilter}
						onChange={e => {
							setQuickFilter(e.target.value)
						}}
					/>
					{props.fireRefresh && (
						<Button
							outline
							onClick={() => {
								const api = apiRef.current
								if (api == null) {
									console.error('api is missing')
								} else {
									api.setQuickFilter(quickFilter)
									api.onFilterChanged()
								}
								if (props.fireRefresh) {
									props.fireRefresh(quickFilter)
								}
							}}>
							Search
						</Button>
					)}
				</HBox>
			</HBox>
		</React.Fragment>
	)

	const updateFilterState = (filterData: Filter[]) => {
		props.viewId && localStorage.setItem(props.viewId + '.tfilters', JSON.stringify(filterData))
		setFilters(filterData)
	}
	const renderFilter = () => (
		<HBox horizontalGap={10} style={{marginTop: 'auto', marginBottom: 'auto'}}>
			{filters.length === 0 && (
				<Badge
					variant={'outlined'}
					label={'Filters'}
					style={{border: `1px solid ${color.success}`}}
					textColor={color.blue900}
					onClick={() => handleFilterConfiguration()}
				/>
			)}
			{filters.length !== 0 && (
				<Badge
					label={'Filters'}
					backgroundColor={color.green900}
					textColor={color.green400}
					onClick={() => handleFilterConfiguration()}
				/>
			)}
			<HBox horizontalGap={5} style={{display: 'flex', flexWrap: 'wrap'}}>
				{filters.map((filter, index) => (
					<Tooltip key={index + '-tooltip'} title={renderTooltipTitle(filter)} placement='top'>
						<div style={{paddingBottom: '5px'}}>
							<Badge
								key={index}
								label={renderLabel(filter)}
								backgroundColor={color.blue900}
								textColor={color.white}
								onClick={() => {
									handleFilterConfiguration(filter)
								}}
								onDelete={
									filter.isUndeletable
										? undefined
										: () => {
												const filtersCopy = [...filters]
												filtersCopy.splice(index, 1)
												updateFilterState(filtersCopy)
										  }
								}
								deleteIcon={
									filter.isUndeletable ? undefined : (
										<Icons.X style={{transform: 'scale(0.65)', marginTop: 1}} fill={color.white} />
									)
								}
							/>
						</div>
					</Tooltip>
				))}

				{filters.length !== 0 && (
					<Badge
						variant={'outlined'}
						label={'Clear All'}
						textColor={color.blue900}
						style={{border: `1px solid ${color.success}`}}
						onClick={() => {
							updateFilterState(JSON.parse(generateDefaultFilters()))
						}}
					/>
				)}
			</HBox>
		</HBox>
	)

	const renderToolBar = () => (
		<>
			<HBox>
				{props.leftToolbar && props.leftToolbar()}
				{!props.hideQuickFilter && renderQuickFilter()}
				{props.hideFilterConfiguration != true && renderFilter()}
				{props.renderToolbar && (
					<HBox style={{marginLeft: 'auto', paddingBottom: '5px'}}>
						<div style={{flexGrow: 1}}></div>
						{props.renderToolbar()}
					</HBox>
				)}
			</HBox>
			<div style={{height: 10}} />
		</>
	)

	return (
		<div
			style={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				margin: 0,
				flex: 1,
				flexGrow: 1,
				...props.style,
			}}>
			{showToolBar && renderToolBar()}
			<ShadowContainer
				hideShadow={props.hideShadow}
				className={`${props.hideHeaderBottomBorder ? 'header-border-bottom' : ''}`}
				style={{flexGrow: 1, paddingLeft: props.align && 0, paddingRight: props.align && 0}}>
				<div
					className='ag-theme-material'
					onContextMenu={CellContextMenu(props, apiRef, columnApiRef)}
					style={{height: height, width: props.rootWidth || '100%', flexGrow: 1}}>
					<AgGridReact
						isExternalFilterPresent={() => tFilterColumns.length > 0}
						doesExternalFilterPass={node => doesExternalFilterPass(node, filtersRef.current || [])}
						getRowNodeId={data => data.id}
						paginationAutoPageSize
						onSortChanged={params => {
							const sortModel = params.api.getSortModel()
							props.viewId && localStorage.setItem(props.viewId + '.sort', JSON.stringify(sortModel))
						}}
						paginationPageSize={50}
						onFilterChanged={e => {
							if (props?.rowData != undefined) {
								if (e.api.getDisplayedRowCount() > 0) {
									e.api.hideOverlay()
								} else {
									e.api.showNoRowsOverlay()
								}
							}
						}}
						cacheOverflowSize={2}
						maxConcurrentDatasourceRequests={1}
						cacheBlockSize={50}
						maxBlocksInCache={5}
						rowSelection='single'
						rowDeselection={true} //ctrl-click to deselect a row
						overlayNoRowsTemplate={`<span style="margin-top:40px;">No Rows To Show</span>`}
						defaultColDef={{
							sortable: true,
							suppressMovable: props?.allowColmnHideMoveAndRememberPreference ? false : true,
							tooltipValueGetter: params => `${params.colDef.headerName}: ${params.value}`,
							resizable: true,
							cellClass: props.align && `ag-cell-align-${props.align}`,
							headerClass: props.align && `ag-header-align-${props.align} padding-0`,
						}}
						frameworkComponents={{set: SetFilter}}
						icons={{
							// filter: ICON_FILTER,
							sortAscending: ICON_SORT_ASC,
							sortDescending: ICON_SORT_DSC,
							groupExpanded: ICON_CARET_DOWN,
							groupContracted: ICON_CARET_RIGHT,
						}}
						onColumnMoved={e => {
							if (props?.allowColmnHideMoveAndRememberPreference) {
								const currentColumnState = e.columnApi.getColumnState()
								props.viewId &&
									localStorage.setItem(
										`${props.viewId + '.columnPreference'}`,
										JSON.stringify(currentColumnState),
									)
							}
						}}
						suppressDragLeaveHidesColumns={props?.allowColmnHideMoveAndRememberPreference ? false : true}
						{...props}
						quickFilterText={quickFilter}
						onGridReady={onGridReady}>
						{props.children}
					</AgGridReact>
				</div>
				{props.actionBar && (
					<VBox style={{width: '100%'}}>
						<Separator thickness={3} />
						{props.actionBar()}
					</VBox>
				)}
			</ShadowContainer>
		</div>
	)
}
