import {useTranslation} from 'react-i18next'
import {Icons, color, HBox, useTheme, Light, Logo} from '@bakkt/components'
import {ReceiptItem} from 'components/ReceiptItem'
import {Receipt} from 'components/Receipt'
import {
	ApiEmbeddedPartnerTransaction,
	ApiEmbeddedPartnerTransactionOperationTypeEnum as CryptoCurrencyTransactTypeEnum,
	ApiAmount,
} from '@bakkt/api'
import {formatCrypto, formatUSD} from 'utils/currencyFormatters'
import {FundingRow} from 'components/fundingRows/FundingRow'
import {formatDate} from 'utils/formatters'
import {Theme} from '@material-ui/core'
import {CryptoCurrencyConfig, useStore} from 'store'

interface DetailSectionProps {
	currencyDetails: CryptoCurrencyConfig | null | undefined
	transactionInfo: ApiEmbeddedPartnerTransaction | null | undefined
	transactionType: CryptoCurrencyTransactTypeEnum | undefined
	fiatAmount: ApiAmount | null | undefined
	crypto: ApiAmount | null | undefined
}
export const DetailSection = ({
	currencyDetails,
	transactionInfo,
	fiatAmount,
	transactionType,
	crypto,
}: DetailSectionProps) => {
	const {t} = useTranslation()
	const theme = useTheme() as Theme
	const date = formatDate(transactionInfo?.created) || {}

	return (
		<>
			<Receipt>
				<ReceiptItem
					name={t('label.asset')}
					style={{padding: '0.4rem 0.1rem 0.8rem 0.1rem'}}
					value={
						<HBox horizontalGap={4}>
							{currencyDetails?.iconURL && <Logo currencyDetail={currencyDetails} width='20' />}
							{currencyDetails?.name}
						</HBox>
					}
				/>
				<ReceiptItem
					name={t('label.transaction')}
					style={{padding: '0.4rem 0.1rem 0.8rem 0.1rem'}}
					value={
						<HBox style={{alignItems: 'center'}} horizontalGap={2}>
							<div
								style={{
									backgroundColor: theme?.palette?.primary?.contrastText || color.blueKolibri,
									borderRadius: '50%',
									height: '20px',
									width: '20px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									textAlign: 'center',
									marginRight: 5,
								}}>
								{transactionInfo?.operationType === CryptoCurrencyTransactTypeEnum.BUY && (
									<Icons.BuyCrypto
										fill={theme?.palette?.primary?.main || color.white}
										height={18}
										width={18}
										viewBox='-10 -10 40 40'
									/>
								)}
								{transactionInfo?.operationType === CryptoCurrencyTransactTypeEnum.SELL && (
									<Icons.SellCrypto
										fill={theme?.palette?.primary?.main || color.white}
										height={18}
										width={18}
										viewBox='-8 -10 40 40'
									/>
								)}
							</div>
							{transactionType && t('' + transactionType?.toLocaleLowerCase())}
						</HBox>
					}
				/>
				<ReceiptItem
					name={t('label.date')}
					value={date.formattedDate}
					style={{padding: '0.4rem 0.1rem 0.8rem 0.1rem'}}
				/>
				<ReceiptItem
					name={t('label.time')}
					value={`${date.formattedTime} ${date.timeZoneAbbr}`}
					style={{
						borderBottom: '5px solid',
						padding: '0.4rem 0.1rem 0.8rem 0.1rem',
					}}
				/>
			</Receipt>
			<Receipt>
				<ReceiptItem
					name={t('label.amount')}
					style={{padding: '0.4rem 0.1rem 0.8rem 0.1rem'}}
					value={
						<HBox
							style={{
								padding: '0.4rem 0.1rem 0.8rem 0.1rem',
								alignItems: 'center',
							}}>
							{formatCrypto(crypto?.amount || 0, crypto?.currency, currencyDetails?.supportedPrecision)}
						</HBox>
					}
				/>
				<ReceiptItem
					name={t('label.value')}
					style={{padding: '0.4rem 0.1rem 0.8rem 0.1rem'}}
					value={fiatAmount?.currency && formatUSD(fiatAmount?.amount || 0, fiatAmount?.currency)}
				/>
				<ReceiptItem
					name={t('label.fee')}
					style={{padding: '0.4rem 0.1rem 0.8rem 0.1rem'}}
					value={fiatAmount?.currency && formatUSD(transactionInfo?.fee, fiatAmount?.currency)}
				/>
				<ReceiptItem
					name={t('label.total')}
					value={
						fiatAmount?.currency && (
							<HBox
								style={{
									padding: '0.4rem 0.1rem 0.8rem 0.1rem',
									alignItems: 'center',
									fontWeight: 'bold',
								}}
								horizontalGap={2}>
								{formatUSD(transactionInfo?.total || 0, fiatAmount?.currency)}
							</HBox>
						)
					}
					style={{
						borderBottom: '5px solid',
						fontWeight: 'bold',
						padding: '0.4rem 0.1rem 0.8rem 0.1rem',
					}}
				/>
			</Receipt>
			<FundingRow
				style={{
					borderBottom: '5px solid',
					padding: '0.4rem 0.1rem 0.8rem 0.1rem',
				}}
				label={
					transactionType === CryptoCurrencyTransactTypeEnum.BUY
						? t('label.fundingSource')
						: t('label.destination')
				}
				fundingName={transactionInfo?.fundingSource}
				fundingDetails={`********${transactionInfo?.formattedFundingAccountNumber}`}
				icon={Icons.BankCircle({
					fill: theme?.palette?.primary?.contrastText,
					stroke: theme?.palette?.primary?.main,
				})}
				dataTestId='funding'
			/>
			<Receipt>
				<HBox style={{padding: '0.4rem 0.1rem 0.8rem 0.1rem'}}>
					<Light style={{margin: 0}}>{t('label.transactionId')}</Light>
				</HBox>
				<HBox style={{fontWeight: '600', padding: '0.4rem 0.1rem 0.8rem 0.1rem'}}>
					<Light style={{margin: 0}}>{transactionInfo?.transactionId}</Light>
				</HBox>
				<ReceiptItem style={{borderBottom: '5px solid'}} />
			</Receipt>
			{/**TODO Will enable it later */}
			{/* <BottomBox className={'bottomBox'}>
				<p className={'needHelp'}>{t('needHelp')}?</p>
				<p className={'haveProblem'}>{t('needHelpDes')}</p>
				<Button className={'bntSP'}>{t('contactSP')}</Button>
			</BottomBox> */}
		</>
	)
}
