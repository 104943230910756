import * as React from 'react'
import Papa from 'papaparse'
import {Button, Dialog, ButtonProps} from '../..'

export type ImportButtonProps = {
	onImport?: (file: File, results?: {data: any[]}) => void
	csvParse?: boolean
} & Omit<ButtonProps, 'onClick'> & {children: any}
export const ImportButton = (all: ImportButtonProps) => {
	const {children, ...props} = all
	const [visible, setVisible] = React.useState(false)
	const [file, setFile] = React.useState<File | null>()
	const onHide = () => {
		setVisible(false)
	}
	const onOpen = () => {
		if (!file) {
			return
		}
		//Parse code & validate if prop true
		if (props.csvParse) {
			Papa.parse(file, {
				delimiter: ',',
				complete: (results: any) => {
					props.onImport && props.onImport(file, results)
					setVisible(false)
				},
			})
		} else {
			props.onImport && props.onImport(file)
			setVisible(false)
		}
	}
	return (
		<>
			<Button
				title='Import CSV File'
				intent='link'
				{...props}
				onClick={() => setVisible(true)}
				disabled={props.disabled}>
				{children}
			</Button>
			<Dialog
				title='Choose CSV File'
				open={visible}
				saveText='Open'
				cancelText='Close'
				onSave={onOpen}
				onCancel={onHide}>
				<input
					type='file'
					id='file-input'
					onChange={e => setFile(e.target.files ? e.target.files[0] : null)}
					accept='.csv,text/csv'
					style={{margin: 10}}
				/>
			</Dialog>
		</>
	)
}
