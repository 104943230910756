import {useTranslation} from 'react-i18next'
import {formatCrypto, formatUSD} from 'utils/currencyFormatters'
import {FundingRow} from './FundingRow'
import {useStore} from 'store'
import {ApiEmbeddedPartnerTransactionOperationTypeEnum as TransactionType} from '@bakkt/api'
import {useSelectedCryptoAccountInfo, useCryptoCurrencyExecutePrices, InputType} from 'hooks'
import {useEffect} from 'react'
import {useCryptoCurrencyDetails} from 'hooks/useCryptoCurrencyDetails'
import shallow from 'zustand/shallow'

type Props = {
	style?: React.CSSProperties
	isSellView?: boolean
}

export const CryptoAccountRow = ({style = {}, isSellView}: Props) => {
	const {t} = useTranslation()
	const {selectedCryptoAccount, setSyncExecutePrices, syncExecutePrices} = useStore(
		state => ({
			selectedCryptoAccount: state.selectedCryptoAccount,
			syncExecutePrices: state.syncExecutePrices,
			setSyncExecutePrices: state.setSyncExecutePrices,
		}),
		shallow,
	)
	const {selectedCryptoCurrency, selectedCryptoCurrencyName, selectedCryptoCurrencyPrecision} =
		useSelectedCryptoAccountInfo()
	const currencyDetail = useCryptoCurrencyDetails(selectedCryptoCurrency)

	const indicativeBalance = Number(selectedCryptoAccount?.indicativeBalance?.amount)
	const indicativeBalanceCurrency = selectedCryptoAccount?.indicativeBalance?.currency
	const accountBalance = Number(selectedCryptoAccount?.accountBalance?.amount)
	const inputType = InputType.Crypto
	const transactionType = TransactionType.SELL

	const {fiatAmount, setCryptoAmount} = useCryptoCurrencyExecutePrices({
		transactionType,
		inputType,
		forceTrigger: syncExecutePrices,
	})

	const fiatBalance = isSellView ? fiatAmount : indicativeBalance

	useEffect(() => {
		isSellView && setCryptoAmount(accountBalance?.toString() || '0')

		return () => {
			setSyncExecutePrices(false)
		}
	}, [syncExecutePrices])

	const formatedDetails = selectedCryptoAccount
		? `${formatUSD(fiatBalance, indicativeBalanceCurrency)} | ${formatCrypto(
				accountBalance,
				selectedCryptoCurrency,
				selectedCryptoCurrencyPrecision,
		  )}`
		: 'N/A'

	return (
		<FundingRow
			dataTestId='available'
			label={t('label.available')}
			fundingName={selectedCryptoCurrencyName}
			currencyDetail={currencyDetail}
			fundingDetails={formatedDetails}
			style={style}
		/>
	)
}
