import React from 'react'
import {H2} from '@bakkt/components'
import {formatUSD} from 'utils/currencyFormatters'
import {ApiAmount, ApiEmbeddedCryptoPriceResponse} from '@bakkt/api'

export interface IndicativePriceDisplayProps {
	selectedCurrency?: ApiAmount['currency']
	indicativePrice?: ApiEmbeddedCryptoPriceResponse['indicativePrice']
	showPriceOnly?: boolean
}

export const IndicativePriceDisplay = ({indicativePrice}: IndicativePriceDisplayProps) => {
	const indicativePriceSplit = indicativePrice
		? formatUSD(indicativePrice?.amount, indicativePrice?.currency).split('.')
		: ''

	return (
		<span style={{margin: 0}}>
			{`${indicativePriceSplit[0] || 'N/A'}`}.
			<span id='IndicativePriceDisplay-Cents'>{`${
				indicativePriceSplit[1] || (indicativePriceSplit[0] ? '00' : '')
			}`}</span>
		</span>
	)
}
