import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import './style.scss'
import './i18n/config'
import '@bakkt/components/src/font/font.css'
import {MemoryRouter} from 'components/MemoryRouter'
import {useStore} from 'store'
import {EmbeddedThemeProvider} from './EmbeddedThemeProvider'
;(window as any)['store'] = useStore

ReactDOM.render(
	<React.StrictMode>
		<MemoryRouter>
			<EmbeddedThemeProvider>
				<App />
			</EmbeddedThemeProvider>
		</MemoryRouter>
	</React.StrictMode>,
	document.getElementById('root'),
)
