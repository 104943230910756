import React, {HTMLAttributes, useEffect, useState} from 'react'
import {color} from 'src'
import {Value} from '../value'
import {useTimer} from 'src/hooks/useTimer'
import {Button} from '../button/Button'
import {useTheme} from '@material-ui/core'

export interface MaskedValueProps extends HTMLAttributes<HTMLDivElement> {
	value: string
	maskedValue?: string
	onClick?: (arg: any) => boolean | void
	unmasked?: boolean
	unMaskedIcon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
	maskedIcon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
	textStyle?: React.CSSProperties
	style?: React.CSSProperties
	timeVisible?: number // in Milliseconds
	loading?: boolean
}

export const MaskedValue: React.FC<MaskedValueProps> = prop => {
	const theme = useTheme()
	const [isMasked, setIsMasked] = useState<boolean>(!prop?.unmasked)
	const [setMaskTimer] = useTimer(prop.timeVisible, () => {
		setIsMasked(true)
	})

	// eslint-disable-next-line
	const handleMaskedValue = (e: any) => {
		if (prop?.onClick) {
			// if onClick returns false or undefined we want to end the function
			if (!prop?.onClick(setIsMasked)) return
		}

		setIsMasked(!isMasked)
	}

	useEffect(() => {
		if (!prop.loading && !isMasked) setMaskTimer()
	}, [prop.loading, isMasked])

	const getMaskedValue = () => {
		if (prop.maskedValue) return prop.maskedValue
		if (prop.value) {
			return prop.value.replace(/./g, '*')
		}
		return ''
	}

	const unMaskedText = (
		<Value
			data-testid={'masked-value'}
			style={{
				color: theme?.palette?.text?.background,
				...prop.textStyle,
				filter: prop.loading ? 'blur(.2rem)' : 'none',
			}}>
			{prop.value}
		</Value>
	)

	const maskedText = (
		<span
			style={{
				padding: 8,
				color: theme?.palette?.text?.background,
				background: color.green900,
				cursor: 'pointer',
				...prop.textStyle,
			}}
			data-testid={'masked-value'}
			title={`click to ${isMasked ? 'unmask' : 'mask'}`}
			onClick={prop.maskedIcon ? () => {} : handleMaskedValue}>
			{isMasked ? getMaskedValue() : prop.value}
		</span>
	)

	return (
		<span style={prop.maskedIcon && {display: 'inline-flex', alignItems: 'center'}}>
			{isMasked ? maskedText : unMaskedText}
			{prop.unMaskedIcon && prop.maskedIcon && (
				<Button
					className='maskedIconToggle'
					data-testid={isMasked ? 'unmask-button' : 'mask-button'}
					aria-label={isMasked ? 'Unmask Social Security Number' : 'Mask Social Security Number'}
					style={{
						height: 20,
						background: theme?.palette?.background?.paper,
					}}
					onClick={handleMaskedValue}
					intent='link'>
					{isMasked && prop.maskedIcon({fill: theme?.palette?.text?.newLink || color.blue700})}
					{!isMasked && prop.unMaskedIcon({fill: theme?.palette?.text?.newLink || color.blue700})}
				</Button>
			)}
		</span>
	)
}
