import {useTranslation} from 'react-i18next'
import {Theme} from '@material-ui/core'

import {H1, LI, Link, Medium, useTheme} from '@bakkt/components'

export const TermsOfuse = () => {
	const {t} = useTranslation()
	const theme = useTheme() as Theme
	const noListStyle = {
		listStyle: 'none',
		paddingLeft: 0,
	}

	const textColor = theme?.palette?.text?.primary
	const isLightMode = theme?.palette?.type === 'light'

	console.log(`text color: ${textColor}, isLightMode ${isLightMode}`)

	return (
		<div className='fullScreenModalContent'>
			<H1 className='title'> BAKKT TERMS OF SERVICE </H1>
			<Medium>
				These Bakkt Terms of Service (the "<b>Agreement</b>") constitute a legal contract between you ("
				<b>Customer</b>") and Bakkt Marketplace, LLC (together with its affiliates, "<b>Bakkt</b>") governing
				your use of the account which establishes your overall relationship with Bakkt (your "Account"), the
				wallet provided to you by Bakkt in which Bakkt will hold cash and digital Assets on your behalf (your "
				<b>Wallet</b>") and the associated services provided by Bakkt as described in this Agreement (the "
				<b>Services</b>"). In this Agreement, "you" or "your" means all persons responsible for complying with
				this Agreement, including any persons that you authorize to use your Wallet or your Account (as defined
				in Section 3.1), and "we," "our" or "us" means Bakkt.
			</Medium>
			<Medium>
				PLEASE READ THIS AGREEMENT CAREFULLY. Use of your Account, your Wallet or any Service constitutes
				acceptance of this Agreement, and you agree to be bound by these terms. By creating an account through
				your existing bank platform (the "<b>Platform</b>", you agree that you have read, understood and
				accepted all of the terms and conditions set forth in this Agreement, including Section 18.7 ("
				<b>Arbitration</b>"), and also including:
			</Medium>
			<ul>
				<LI>
					Bakkt's Privacy Policy, located at{' '}
					<Link target='_blank' href='https://www.bakkt.com/privacy-policy'>
						https://www.bakkt.com/privacy-policy
					</Link>{' '}
					(the "<b>Privacy Policy</b>"),
				</LI>
				<LI>
					Bakkt's Cookie Policy, located at{' '}
					<Link target='_blank' href='https://www.bakkt.com/cookie-policy'>
						https://www.bakkt.com/cookie-policy
					</Link>{' '}
					(the "<b>Cookie Policy</b>"),
				</LI>
				<LI>
					Bakkt's Acceptable Use Policy, located at{' '}
					<Link target='_blank' href='https://www.bakkt.com/acceptable-use-policy'>
						https://www.bakkt.com/acceptable-use-policy
					</Link>{' '}
					(the "<b>Acceptable Use Policy</b>"), and
				</LI>
				<LI>
					Bakkt's E-Sign Consent, located at{' '}
					<Link target='_blank' href='https://www.bakkt.com/e-sign-consent'>
						https://www.bakkt.com/e-sign-consent
					</Link>{' '}
					(the "<b>E-Sign Consent</b>").
				</LI>
			</ul>
			<Medium>
				By engaging in the purchase and sale of Cryptocurrencies (as defined in Section 7.2), you agree to be
				bound by the terms governing that service. In the event of a conflict between this Agreement and the
				terms governing that service, the terms governing that service shall control to the extent the conflict
				relates to such service.
			</Medium>
			<Medium>
				<b>
					The value of Digital Assets (as defined in Section 7.2) can increase and decrease quickly and
					significantly. There is substantial risk that you can lose money and all of the value of your
					Digital Assets by buying and selling Digital Assets. Some of the risks involved with buying,
					selling, holding or storing Digital Assets are included in Sections 18.1 and 18.2 of this Agreement.
					You should carefully consider whether engaging in Digital Asset transactions is suitable for you.
				</b>
			</Medium>
			<ol>
				<LI>
					<b> Eligibility </b>. You represent and warrant that you are at least 18 years of age (or the age of
					majority in your state of residence), that you are capable of entering into a legally binding
					agreement, and that you reside in the United States. Your right to use your Account, your Wallet and
					the Services is conditioned upon your acceptance and compliance with this Agreement. If you do not
					agree to be bound by this Agreement, you are not authorized to use your Wallet or the Services, and
					you agree to immediately discontinue any access to or use of your Wallet or the Services.
				</LI>
				<LI>
					<b> Amendments to this Agreement. </b> Bakkt may modify or amend this Agreement at any time and may
					impose new or additional terms or conditions on your use of your Account, your Wallet or the
					Services. Bakkt will notify you of such modification, amendment or new terms by (a) posting revised
					terms of use on the Platform, or (b) sending you an email notification to the email address you
					provide to us in connection with your Account. You are responsible for periodically reviewing this
					Agreement as posted on the Platform. Revisions to the Agreement will be deemed effective at the time
					of posting, unless otherwise noted. Your continued use of your Account, your Wallet or any of the
					Services or failure to cancel your Account will indicate your acceptance of the revised Agreement.
					If you do not agree with the revised Agreement, your sole and exclusive remedy is to discontinue use
					of your Wallet and Services and terminate your Account.
				</LI>

				<LI>
					<b> Accounts </b>

					<ul style={noListStyle}>
						<LI>
							3.1. <b>Creating Your Account.</b> You must create and register your Account to use your
							Wallet and the Services. By creating and registering an Account, you acknowledge and agree
							your use of your Account, Wallet and Services will be for personal use only, and not on
							behalf of any third party. Only one Account per person is allowed. You acknowledge and agree
							that you are responsible for all activity conducted through your Account. To create an
							Account, you must provide us with certain information, which may include your name, address,
							email address, date of birth, government identification, and other personal information. You
							permit us to keep a record of such information. Bakkt may use this information to verify
							your identity and your Account. You agree that you will provide accurate and complete
							information to Bakkt in response to the information Bakkt requests during the Account
							creation and verification process. Further, you agree that you will update the Account
							information you provide in order to maintain accurate Account information.
						</LI>
						<LI>
							3.2. <b>Inquiries.</b> You authorize Bakkt to make any inquiries it deems necessary and
							receive information about you related to such inquiries to verify your identity and the
							information you provide. You acknowledge and agree that Bakkt may use third parties in
							connection with the inquiries and verification. Further, you authorize Bakkt to take any
							action that it deems necessary based on the results of such inquiries. You acknowledge and
							agree that your personal information may be disclosed to certain third-party agencies, such
							as financial crime agencies, and that these agencies may respond to Bakkt's inquiries.
							Bakkt's use of such information will be subject to the Privacy Policy.
						</LI>
						<LI>
							3.3. <b>Device Registration.</b> Bakkt requires you to take certain authentication measures
							to help keep your Account secure. You may be required to use multiple forms and types of
							authentication when accessing your Account, as determined by Bakkt from time to time. In
							connection with your Account creation and registration process, you will be required to
							register the devices you use to access your Account.
						</LI>
						<LI>
							3.4. <b>Account Security.</b> You are responsible for maintaining security and control of
							any and all log-in credentials, passwords, personal identification numbers, and any other
							information you may use to access your Account, the Platform, your Wallet or the Services
							(the "<b>Credentials</b>"). Bakkt will provide you with transaction history that you can
							access through your Account at any time and confirmations for each transaction conducted
							through your Account. Bakkt assumes that you have authorized each transaction that occurs
							using your Credentials. You agree to cooperate with Bakkt during the investigation of any
							suspected unauthorized access to or use of your Account, your Wallet or the Services using
							your Credentials. You agree to provide Bakkt with accurate and current information
							throughout the investigation. You agree to perform any tasks Bakkt reasonably requires to
							manage or report a security breach or fraud.
						</LI>
					</ul>
				</LI>

				<LI>
					<b>Consumer Liability.</b> You agree to inform Bakkt by phone at 1-800-322-1719 or by email at{' '}
					<Link href='mailto:help@bakkt.com'>help@bakkt.com</Link> of any actual or suspected security breach
					of your Account or the Wallet and of any fraud or attempted fraud as soon as possible. You should
					tell us{' '}
					<b>
						<u>at once</u>
					</b>{' '}
					if you believe that an electronic fund transfer or transaction, as described in Section 5, has been
					made without your permission. (Telephoning us is the best way of keeping your possible losses down.)
					If you tell us within 2 business days after you learn of the loss or unauthorized transaction, you
					can lose no more than $50, or the value of $50 of the Digital Asset used in a transaction, if
					someone used your Account without your permission. If you do{' '}
					<b>
						<u>not</u>
					</b>{' '}
					tell us within 2 business days after you learn of the loss or unauthorized transaction, and we can
					prove we could have stopped someone from using your Account without your permission if you had told
					us, you could lose as much as $500, or the value of $500 of the Digital Asset used in a transaction.
					If you do not tell us within 60 days after we provided the statement to you, you may not get back
					any money you lost after the 60 days if we can prove that we could have stopped someone from taking
					the money or Digital Assets if you had told us in time. If a good reason (such as a long trip,
					hospital stay, natural disaster, etc.) kept you from telling us, we may extend these time periods.
					Our business days are Monday through Friday, not including holidays.
				</LI>
				<LI>
					<b>Error Resolution.</b> In case of errors or questions about your electronic transfers or
					transactions, telephone us at 1-800-322-1719 or email us at{' '}
					<Link href='mailto:help@bakkt.com'>help@bakkt.com</Link> as soon as you can. We must allow you to
					report an error until 60 days after the earlier of the date you electronically access your account,
					if the error could be viewed in your electronic history, or the date we sent the{' '}
					<b>
						<u>first</u>
					</b>{' '}
					written history on which the error appeared. You may request a written history of your transactions
					at any time by calling us at 1-800-322-1719 or writing us at{' '}
					<Link href='mailto:help@bakkt.com'>help@bakkt.com</Link> . You will need to tell us:
					<ul>
						<LI>Your name and Bakkt ID (found in your Bakkt profile).</LI>
						<LI>Why you believe there is an error, and the dollar amount involved.</LI>
						<LI>Approximately when the error took place.</LI>
					</ul>
					<Medium>
						If you tell us orally, we may require that you send us your complaint or question in writing
						within 10 business days.
					</Medium>
					<Medium>
						We will determine whether an error occurred within 10 business days after we hear from you and
						will correct any error promptly. If we need more time, however, we may take up to 45 days to
						investigate your complaint or question. If we decide to do this, we will credit your account
						within 10 business days for the amount you think is in error, so that you will have the use of
						the money during the time it takes us to complete our investigation. If we ask you to put your
						complaint or question in writing and we do not receive it within 10 business days, we may not
						credit your Account.
					</Medium>
					<Medium>
						For errors involving new Accounts, point-of-sale transactions, or foreign-initiated
						transactions, we may take up to 90 days to investigate your complaint or question. For new
						Accounts, we may take up to 20 business days to credit your account for the amount you think is
						in error.
					</Medium>
					<Medium>
						We will tell you the results within three business days after completing our investigation. If
						we decide that there was no error, we will send you a written explanation. You may ask for
						copies of the documents that we used in our investigation.
					</Medium>
					<Medium>
						If you need more information about our error-resolution procedures, call us at 1-800-322-1719 or
						visit{' '}
						<Link target='_blank' href='https://www.bakkt.com'>
							www.bakkt.com
						</Link>{' '}
						.
					</Medium>
				</LI>

				<LI>
					<b>Closing Your Account.</b> You may close your Account and terminate your relationship with Bakkt
					without cost by calling customer service at 1-800-322-1719 or [Bakkt and Partner to insert an
					alternate method contemplating the embedded relationship], but you will remain liable for all
					obligations related to your Account even after the Account is closed. You may not transfer any
					Cryptocurrencies (as defined in Section 7.2) from your Account to an external source. Prior to
					closing your Account, you must sell or transfer any and all Cryptocurrency you hold in your Wallet.
					Any incomplete transactions or transfers must be completed or canceled, and you must transfer any
					U.S. Dollars from your Account before closing it. In certain cases, you may not close your Account,
					including:
					<ul>
						<LI>To evade an investigation.</LI>
						<LI>If you have a pending transaction or an open dispute or claim.</LI>
						<LI>If you owe amounts to us.</LI>
						<LI>If your Account is subject to a hold, limitation or reserve.</LI>
					</ul>
				</LI>

				<LI>
					<b> Wallets and Digital Assets. </b>

					<ul style={noListStyle}>
						<LI>
							7.1. <b>Wallet.</b> Following the creation and validation of your Account, Bakkt will
							provide you with access to the Wallet. The Wallet will allow you to hold, store, transfer,
							receive and manage Digital Assets and U.S. Dollars (together, the "<b>User Assets</b>").
							Bakkt cannot transfer User Assets from the Wallet except as requested or instructed to do so
							through your Account or by law, a court order or a governmental authority.
						</LI>
						<LI>
							7.2. <b>Definitions.</b> "<b>Digital Assets</b>" refers to the Cryptocurrencies listed at{' '}
							<Link target='_blank' href='https://www.bakkt.com'>
								www.bakkt.com
							</Link>
							. "<b>Cryptocurrencies</b>" means bitcoin, ether and any other cryptocurrencies Bakkt may
							offer that may be purchased, sold or sent to other verified Bakkt account holders ("
							<b>Users</b>") through the Services.
						</LI>
						<LI>
							7.3. <b>Digital Assets Generally.</b> Bakkt has the right, in its sole discretion, to
							determine the types of Digital Assets that may be made available to your Wallet or through
							the Services. Bakkt may add or remove any Digital Asset class from the Services at any time
							and for any reason and will seek to provide you with advance notice of the addition or
							removal of any Digital Assets, unless such notice is prohibited by law, governmental
							authority or other legal process. You should not attempt to use the Wallet to hold, store,
							transfer, receive or manage assets Bakkt does not support. Bakkt is not responsible for any
							delays or losses associated with any attempt to use unsupported assets with the Wallet.
						</LI>
					</ul>
				</LI>

				<LI>
					<b>Ownership.</b> You are the owner of your Cryptocurrencies stored in the Wallet. All User Assets
					held in the Wallet are custodial assets held by Bakkt for your benefit. In the event of Bakkt's
					bankruptcy, you will be entitled to the return of Cryptocurrencies that are custodial assets held by
					Bakkt on your behalf, as reflected in Bakkt's records.
				</LI>
				<LI>
					<b>Updates.</b> You acknowledge and agree that Bakkt may, in its sole discretion, make unscheduled
					updates, enhancements or other changes to the Platform, Account, Wallet or the Services at any time.
					Bakkt may add or remove functionalities or features, or may discontinue the Platform, Wallet or
					Services altogether. Bakkt will seek to provide you with advance notice of such additions and
					removals, unless such notice is prohibited by law, governmental authority or other legal process.
				</LI>
				<LI>
					<b>U.S. Dollar Holdings.</b> Bakkt is not a bank and does not hold deposits itself. In general, the
					U.S. Dollar balances used to fund Cryptocurrency purchases will continue to be held by your existing
					bank whose Platform is being used for these Services.
				</LI>
				<LI>
					<b>Funding Transactions.</b> Your U.S. Dollars in your bank account with your existing bank whose
					Platform is being used for these Services will be debited immediately following the initiation of
					the funding transaction. You authorize Bakkt to debit your bank account at your instruction, as
					provided through the Platform. You understand that this authorization to access your bank account
					will remain in full force and effect until you revoke this authorization by terminating your
					Account.
				</LI>
				<LI>
					<b> Use of the Services. </b>

					<ul style={noListStyle}>
						<LI>
							12.1. <b>Transactions.</b> You may use your Wallet to buy and sell Digital Assets and
							convert certain classes of Digital Assets into U.S. Dollars. If you use your Wallet to buy
							and sell Cryptocurrency, you agree to also be bound by the terms included in the
							Cryptocurrency Purchase and Sale Terms.
						</LI>
						<LI>
							12.2. <b>Fees.</b> We reserve the right to adjust our fees at any time. We will notify you
							of any changes to our fees by (a) posting revised terms of use on the Platform, or (b)
							sending you an email notification to the email address you provide to us in connection with
							your Account. Any applicable fees payable by you will also be disclosed before you complete
							a transaction. By using your Account or the Services, you agree to pay all applicable fees.
						</LI>
						<LI>
							12.3. <b>Taxes.</b> Your transactions do not include any taxes, levies, duties or similar
							governmental assessments of any nature, assessable by any jurisdiction whatsoever
							(collectively, "<b>Taxes</b>"). You are responsible for determining whether any Taxes may
							apply to the transactions you complete using the Services, and it is your responsibility to
							report and remit the relevant Taxes to the appropriate taxing authorities. You agree that
							Bakkt is not responsible for determining whether any Taxes apply, or the amount of any Taxes
							that may apply, to transactions you complete using the Services.
						</LI>
						<LI>
							12.4. <b>Transaction Limits.</b> Limitations on the volume or value of your transactions may
							apply. You may view any applicable limitations through your Account. Bakkt may change
							applicable limitations at any time by posting the limitations to your Account and without
							any other notice to you. Bakkt may base its decision on confidential criteria and may be
							restricted by law, a court order or a governmental authority from disclosing certain
							information to you regarding such limits.
						</LI>
						<LI>
							12.5. <b>Finality.</b> When you give us instructions to purchase, sell or transfer Digital
							Assets or to transfer U.S. Dollars in connection with any transaction, including transfers
							to other Users, you cannot change, cancel or withdraw your authorization for Bakkt to
							complete that transaction. You cannot cancel, reverse, or change any transaction following
							your submission of the instructions to Bakkt.
						</LI>
						<LI>
							12.6. <b>Conversion.</b> Payments made with Digital Assets will be authorized in U.S.
							Dollars at the current selling price of the Digital Asset selected for that payment. Any
							refunds or credits to payments, regardless of funding source, will be returned in U.S.
							Dollars.
						</LI>
						<LI>
							12.7. <b>Transaction History.</b> Transaction history will be reflected in your Account and
							accessible through the Platform. Your transaction history will display the amount and
							currency of each transaction you conduct using the Wallet, any fees charged to you and the
							date of the transaction.
						</LI>
					</ul>
				</LI>

				<LI>
					<b>Privacy.</b> You acknowledge that we receive and process personal information from you. Please
					review our Privacy Policy to understand our commitment to maintain your privacy. You represent and
					warrant that you have read and understood our Privacy Policy before providing us with your personal
					information. You consent to the collection, use and disclosure of information as described in the
					Privacy Policy.
				</LI>
				<LI>
					<b>Account Communications.</b> Except as otherwise required by U.S. law, Bakkt may provide any
					notice permitted or required under this Agreement via mail, email, SMS text message (if you opt in),
					posting on the Platform, Account notification, or any other reasonable means. By using your Account,
					your Wallet or the Services, you agree that Bakkt may communicate with you regarding your Account,
					your Wallet and Services electronically and consent to receiving such communications electronically.
					These communications will include notices about your Account (e.g., change in password or Account
					information and transaction confirmation alerts). Such communications, as well as notices,
					disclosures, agreements and other communications that Bakkt provides to you electronically, are
					equivalent to communications in writing and shall have the same force and effect as if they were in
					writing and signed by Bakkt. You should maintain copies of electronic communications by printing a
					paper copy or saving an electronic copy. Please see the E-Sign Consent for more details regarding
					electronic communications.
				</LI>
				<LI>
					<b>Short Code Terms/SMS Terms.</b> You may be able to receive the following types of text messages
					from Bakkt, depending on your opt-in status and subscription settings:
					<ul>
						<LI>One-time password</LI>
						<LI>Important alerts and new terms and privacy updates</LI>
						<LI>Account alerts, including changes to your Bakkt account</LI>
						<LI>Transaction alerts, including financial activity on your Bakkt account</LI>
						<LI>Promotions, including new Bakkt features and offers.</LI>
					</ul>
					<Medium>
						If applicable and you opt in to text messages for your account, you can cancel that election at
						any time by texting "STOP" to 22558 ("BAKKT"). We will then send you an SMS message to confirm
						that you have been unsubscribed. After this, you will not receive SMS messages from us unless
						you opt in again. If you want to opt in again, just visit the Notification Preferences section
						in Settings in Platform (if applicable) and enable the text message subscriptions you'd like to
						receive.
					</Medium>
					<Medium>
						If you are experiencing issues with the messaging program, you can reply with the keyword HELP
						for more assistance, or you can get help directly by emailing{' '}
						<Link href='mailto:help@bakkt.com'>help@bakkt.com</Link> or calling 1-800-322-1719. Carriers are
						not liable for delayed or undelivered messages. As always, message and data rates may apply for
						any messages sent to you from us and to us from you. Messages per month vary according to your
						subscription settings and account activity. If you have any questions about your text plan or
						data plan, it is best to contact your wireless provider. If you have any questions regarding
						privacy, please read our Privacy Policy.
					</Medium>
				</LI>
				<LI>
					<b>Suspension and Termination.</b> Bakkt may suspend or terminate your Account or restrict your
					access to your Wallet or Services, at any time in its sole discretion, with or without cause, and
					with or without notice, without incurring liability of any kind. If Bakkt suspends or closes your
					Account, or terminates your use of your Wallet or Services for any reason, Bakkt will provide you
					with notice of its actions unless a court order, governmental authority, law, regulation or other
					legal process prohibits Bakkt from providing you with such notice. Bakkt may also suspend, freeze,
					delay, decline or reverse any transaction you conduct through the Services for any reason, including
					suspected illegal activity, suspected fraud, or an erroneous transaction. You acknowledge that
					Bakkt's decision to take any of the actions described in this Section 16 may be based on
					confidential standards that are essential to Bakkt's risk management and security protocols and
					procedures. You agree that Bakkt is under no obligation to disclose any details regarding these
					protocols and procedures to you.
				</LI>
				<LI>
					<b>Acceptable Use.</b> By using your Account, your Wallet and the Services, you agree and represent
					that you will not engage in any practices that are prohibited. Prohibited uses can be found in the
					Acceptable Use Policy, located at{' '}
					<Link target='_blank' href='https://www.bakkt.com/acceptable-use-policy'>
						https://www.bakkt.com/acceptable-use-policy
					</Link>
					. Bakkt may review, monitor, store and disclose any information necessary to satisfy any applicable
					law, regulation, request from a governmental authority or request or order by a court. Bakkt may
					suspend or terminate your Account, or freeze the funds stored in the Wallet immediately and without
					notice if Bakkt determines, in its discretion, you have violated the Acceptable Use Policy. You
					represent and warrant that you have read and understood our Acceptable Use Policy before using your
					Wallet or the Services.
				</LI>
				<LI>
					<b> General Terms. </b>

					<ul style={noListStyle}>
						<LI>
							18.1. <b>Acknowledgement of Risk.</b> You acknowledge that buying, selling, holding or
							storing Digital Assets involves risks. It is your responsibility to learn about the risks
							associated with Digital Assets, including with certain Digital Asset protocols. These risks
							include, but are not limited to, the following: (i) Digital Asset transactions may not be
							reversible, and losses you experience due to fraudulent or accidental transactions may not
							be recoverable; (ii) Digital Assets are not legal tender and are not backed by a government
							or central bank; (iii) Digital Assets are not subject to any protections or any insurance
							provided by the Federal Deposit Insurance Corporation or the Securities Investor Protection
							Corporation; (iv) legal and regulatory changes at the international, federal, state and/or
							local level may adversely impact the use, transfer, exchange and value of Digital Assets;
							(v) blockchains are maintained by unidentified private computer networks and you are
							responsible for understanding and accepting the risks associated with the blockchain; (vi)
							some Cryptocurrency transactions shall be deemed to be made when recorded on a public
							ledger, which is not necessarily the date or time that the customer initiates a transaction
							with Bakkt; (vii) hackers target Digital Assets, and hacking can occur despite strong
							security measures, especially as the nature of Digital Assets may lead to an increased risk
							of fraud or cyber-attack; (viii) your Digital Assets may be stolen and irretrievable; (ix)
							Digital Asset values may fluctuate substantially relative to fiat currency, and this could
							result in a total loss or other significant loss of the value of the Digital Assets held in
							the Wallet, even over a short period of time; (x) the value of Digital Assets may be derived
							from the continued willingness of market participants to exchange fiat currency for such
							Digital Assets, which may result in the potential for permanent and total loss of value of a
							particular Digital Asset should the market for that Digital Asset disappear; (xi) there is
							no assurance that a person or business which accepts certain Digital Assets as payment today
							will continue to do so in the future; (xii) the nature of Digital Assets means that
							technological difficulties may prevent the access or use of a customer's Digital Assets; and
							(xiii) any insurance, bonds, or trust accounts held by Bakkt may not be sufficient to cover
							all of the losses you incur in using the Digital Assets.
						</LI>
						<LI>
							18.2. <b>Digital Asset Protocols; Forks.</b> By using your Account, your Wallet and the
							Services, you acknowledge and agree that Bakkt does not have any control over the underlying
							software protocols governing the operation of certain Digital Assets. Bakkt is not liable
							for the operation of these protocols, and does not guarantee the functionality or security
							of these operations. These protocols are subject to sudden changes, and these changes may
							materially affect the value, function, availability and name of the Digital Assets you hold
							in the Wallet. Changes to the protocol governing certain Digital Assets are generally
							referred to as "forks." In the event of any changes to any Digital Asset protocols, Bakkt
							may take any measures it deems necessary, in its sole discretion, to protect the Digital
							Assets available through Bakkt's platform. These measures may include temporarily suspending
							operations for the impacted Digital Assets. Bakkt will use its best efforts to notify you of
							its response to any material operating changes, but you acknowledge that these changes are
							outside of Bakkt's control and may occur without notice to Bakkt. By using your Account,
							your Wallet and the Services, you acknowledge and accept the risks of operating changes to
							Digital Asset protocols, including forks, and agree Bakkt is not responsible for any such
							changes or any losses you may experience as a result of such changes.
						</LI>
						<LI>
							18.3. <b>No Investment Advice or Brokerage.</b> Bakkt does not provide financial,
							investment, trading, tax, or legal advice. You are solely responsible for determining
							whether any investment, investment strategy or transaction is appropriate for you based on
							your personal investment objectives, financial circumstances and risk tolerance. You should
							consult your financial adviser, legal or tax professional regarding your specific situation.
							<Medium>
								Bakkt may provide educational information about Digital Assets (including but not
								limited to those Digital Assets supported by Bakkt), to assist you in learning more.
								Information may include, but is not limited to, blog posts, articles, links to
								third-party content, news feeds, tutorials, and videos. Bakkt may offer promotions that
								include, among other things, incentives to purchase Digital Assets, including but not
								limited to cryptocurrencies. The information provided on the Platform, or on any
								third-party site, and the promotions which include incentives to purchase Digital
								Assets, do not constitute investment advice, financial advice, trading advice, or any
								other sort of advice, and you should not treat any of the Platform's, or promotions'
								content as such. Bakkt does not recommend that any Digital Asset should be bought,
								earned, sold, or held by you. Before making the decision to buy, sell or hold any
								Digital Asset, you should conduct your own due diligence and consult your financial
								advisors. Bakkt will not be held responsible for the decisions you make to buy, sell, or
								hold Digital Assets based on such information we provide.
							</Medium>
						</LI>

						<LI>
							18.4. <b>Indemnity.</b> You agree to indemnify, defend and hold harmless Bakkt and its
							directors, managers, officers, owners, agents, co-branders or other partners, employees,
							information providers, licensors, licensees, consultants, contractors and other applicable
							third parties (collectively "<b>Indemnified Parties</b>") from and against any and all
							claims, demands, investigations, causes of action (the "<b>Claims</b>"), debt or liability,
							including reasonable attorney's fees, including without limitation attorney's fees and costs
							incurred by the Indemnified Parties arising out of, related to, or which may arise from:
							<ul>
								<LI>
									your use, including the use by any third party you authorize, of your Account, the
									Services or your Wallet;
								</LI>
								<LI>any breach or non-compliance by you of any of the terms of this Agreement; or</LI>
								<LI>any dispute or litigation caused by your actions or omissions.</LI>
							</ul>
							<Medium>
								Bakkt will use commercially reasonable efforts to notify you of any such Claims that are
								subject to your indemnification obligation.
							</Medium>
						</LI>
						<LI>
							18.5. <b>Disclaimer.</b> THE PLATFORM, ACCOUNT, WALLET AND SERVICES ARE PROVIDED ON AN "AS
							IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY REPRESENTATION OR WARRANTIES OF ANY KIND, EITHER
							EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE,
							MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGMENT. NO WARRANTY IS
							PROVIDED THAT THE PLATFORM, ACCOUNT, WALLET OR THE SERVICES WILL BE FREE FROM DEFECTS OR
							VIRUSES OR THAT OPERATION OF THE SERVICE WILL BE UNINTERRUPTED, TIMELY OR ERROR-FREE. BAKKT
							DOES NOT GUARANTEE THAT ANY ORDER WILL BE EXECUTED, ACCEPTED, RECORDED OR REMAIN OPEN. YOU
							HEREBY UNDERSTAND AND AGREE THAT BAKKT WILL NOT BE LIABLE FOR ANY LOSSES OR DAMAGES ARISING
							OUT OF OR RELATING TO (A) ANY INACCURACY, DEFECT OR OMISSION OF DIGITAL ASSET PRICE DATA,
							(B) ANY ERROR OR DELAY IN THE TRANSMISSION OF SUCH DATA, OR (C) INTERRUPTION IN ANY SUCH
							DATA. THE PLATFORM, ACCOUNT, WALLET AND SERVICES ARE GENERALLY AVAILABLE 24 HOURS A DAY,
							SEVEN DAYS A WEEK, WITH THE EXCEPTION OF OUTAGES FOR MAINTENANCE AND CIRCUMSTANCES BEYOND
							BAKKT'S CONTROL. YOUR USE OF THE PLATFORM, ACCOUNT, WALLET OR THE SERVICES AND ANY OTHER
							MATERIAL OR SERVICES DOWNLOADED OR MADE AVAILABLE TO YOU THROUGH THE PLATFORM, OR SERVICES
							IS AT YOUR OWN DISCRETION AND RISK, AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE RESULTING
							FROM THEIR USE.
						</LI>
						<LI>
							18.6. <b>Limitation of Liability.</b> TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN
							NO EVENT WILL BAKKT, ANY OF ITS CONTRACTORS OR PROVIDERS, OR ANY OF ITS OR THEIR RESPECTIVE
							AFFILIATES BE LIABLE FOR (i) ANY LOST PROFITS, REDUCTION IN VALUE OR LOSS OF DIGITAL ASSETS
							OR BUSINESS OPPORTUNITY, (ii) ANY LOSS, DAMAGE, CORRUPTION OR BREACH OF DATA OR ANY OTHER
							INTANGIBLE PROPERTY, (iii) ANY DAMAGES ARISING OUT OF THE USE OR INABILITY TO USE THE
							PLATFORM, ACCOUNT, WALLET OR THE SERVICES, INCLUDING ANY DIMINUTION OF VALUE THAT MAY OCCUR
							DURING PERIODS WHEN THE PLATFORM, ACCOUNT, WALLET OR THE SERVICES ARE UNAVAILABLE FOR ANY
							REASON, (iv) ANY UNAUTHORIZED ACCESS TO THE PLATFORM, ACCOUNT, WALLET OR THE SERVICES, OR
							ANY HARM CAUSED BY SUCH UNAUTHORIZED ACCESS UNLESS CAUSED SOLELY AND DIRECTLY BY OUR WILLFUL
							MISCONDUCT, OR (v) ANY GENERAL, SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES, EVEN
							IF ADVISED OF THE POSSIBILITY THEREOF, AND REGARDLESS OF THE LEGAL OR EQUITABLE THEORY
							(CONTRACT, TORT OR OTHERWISE) UPON WHICH ANY CLAIM IS BASED. IN ANY CASE, THE LIABILITY OF
							BAKKT OR ANY OF THE OTHER PERSONS OR ENTITIES DESCRIBED IN THIS PARAGRAPH ARISING OUT OF
							THIS AGREEMENT SHALL NOT EXCEED THE SUM OF THE FEES PAID BY YOU FOR THE SERVICES IN THE
							TWELVE MONTHS BEFORE YOUR CLAIM AROSE.
						</LI>
						<LI>
							18.7. <b>Arbitration.</b> YOU AND BAKKT AGREE TO ARBITRATE, RATHER THAN LITIGATE IN COURT,
							any and all claims or disputes between you and Bakkt (including any subsidiaries and
							affiliates, and their officers, directors, managers, employees, or agents of the foregoing)
							that arise out of or in any way relate to this Agreement, the Platform, Wallet, your Account
							or the Services ("<b>Disputes</b>"), under any legal theory (the "
							<b>Arbitration Agreement</b>"). All Disputes shall be decided by an arbitrator and not by a
							court. Notwithstanding this agreement to arbitrate, you and Bakkt may bring Disputes against
							each other in small claims court, if the Disputes fall within the small claims court's
							jurisdiction.
							<Medium>
								Arbitrator Authority: The arbitration between you and Bakkt will be binding. In
								arbitration, there is no judge and no jury. Instead, disputes will be resolved by an
								arbitrator, whose authority is governed by this Agreement. You and Bakkt agree that an
								arbitrator may only award such relief as a court of competent jurisdiction could award,
								limited to the same extent as a court would limit relief pursuant to this Agreement. An
								arbitrator may award attorneys' fees and costs if a court would be authorized to do so,
								and may issue injunctive or declaratory relief if that relief is required or authorized
								by the applicable law, but that injunctive or declaratory relief may not extend beyond
								you and your dealings with Bakkt. Review of arbitration decisions in the courts is very
								limited.
							</Medium>
							<Medium>
								Arbitration Procedures: You and Bakkt agree that this Agreement affects interstate
								commerce and that the Federal Arbitration Act applies. All arbitrations shall be
								conducted by the American Arbitration Association ("<b>AAA</b>") under the AAA's
								Consumer Arbitration Rules in effect at the time the arbitration is commenced. The AAA's
								rules are available on its website at{' '}
								<Link target='_blank' href='https://www.adr.org'>
									https://www.adr.org
								</Link>{' '}
								or by calling 1-800-778-7879. If there is a conflict between the AAA's rules and this
								Arbitration Agreement, this Arbitration Agreement shall control. To initiate
								arbitration, you must send a letter requesting arbitration and describing your Dispute
								to Bakkt at <Link href='mailto:help@bakkt.com'>help@bakkt.com</Link> or via U.S. mail to
								Bakkt – Attn: Legal/Arbitration, 10000 Avalon Boulevard, Suite 1000, Alpharetta, GA
								30009. You must also comply with the AAA's rules regarding initiation of arbitration,
								which can be found on the AAA's website. Bakkt will pay the AAA's Case Management Fee
								and any arbitrator fees. Each party shall be responsible for paying its own attorneys'
								fees. The arbitration will be held in a mutually convenient location.
							</Medium>
							<Medium>
								You may opt out of this Arbitration Agreement, and the Class Action Waiver, by notifying
								us in writing of your intention to do so. Written notice should be provided to Bakkt –
								Attn: Legal/Arbitration, 10000 Avalon Boulevard, Suite 1000, Alpharetta, GA 30009 AND
								MUST BE POSTMARKED WITHIN 30 DAYS OF YOUR ACCEPTANCE OF THESE TERMS. Any opt-out request
								postmarked after that date will not be effective, and you will continue to be bound by
								the Arbitration Agreement, including the Class Action Waiver.
							</Medium>
							<Medium>
								Class Action Waiver: You and Bakkt agree that all Disputes will be arbitrated
								individually, and that there will be no class, representative, or consolidated actions
								in arbitration. If you or Bakkt brings a Dispute in small claims court, the class action
								waiver will apply, and neither party can bring a Dispute on a class or representative
								basis against the other. Furthermore, neither party may participate in a class or
								representative action as a class member if the class action asserts Disputes that would
								fall within the scope of this Arbitration Agreement if they were directly asserted by
								you or Bakkt. If a court or arbitrator determines in an action between you and Bakkt
								that any part of this Class Action Waiver is unenforceable with respect to some claims,
								the Arbitration Agreement and Class Action Waiver will not apply to those claims only
								but will remain in force with respect to all other claims. Except for this express
								exception, this Class Action Waiver may not be severed from our Arbitration Agreement.
							</Medium>
							<Medium>
								Jury Trial Waiver: If for any reason this Arbitration Agreement is found to be
								unenforceable, you and Bakkt expressly and knowingly WAIVE THE RIGHT TO TRIAL BY JURY.
								This means that a judge, rather than a jury, will decide disputes between you and Bakkt
								if for any reason the Arbitration Agreement is not enforced.
							</Medium>
						</LI>
						<LI>
							18.8. <b>Intellectual Property.</b> Bakkt's intellectual property, including our techniques,
							methods, trade secrets, proprietary rights, trademarks, patents, trade names, logos,
							servicemarks, product names and descriptions and any and all other intellectual property or
							proprietary notices ("
							<b>Intellectual Property</b>"), together with any goodwill associated therewith, is the sole
							property of Bakkt. You are not authorized to use our Intellectual Property or any variations
							thereof other than as expressly set forth in this Agreement, and Bakkt does not grant you
							any express or implied rights to any Intellectual Property. All express or implied ownership
							or other rights are retained by Bakkt. Bakkt is the sole owner of any derivatives,
							modifications, enhancements, updates and changes to its Intellectual Property, even if such
							changes are based, in whole or in part, on your ideas, comments, suggestions, questions,
							requests, and other feedback.
						</LI>
						<LI>
							18.9. <b>Compliance with Law.</b> You agree that you will use your Account, the Platform,
							the Wallet and the Services in accordance with applicable law, including any regulations.
							You further agree that you will not use your Account, the Platform, the Wallet or the
							Services in connection with or in furtherance of any activity that would violate applicable
							law and/or any related regulations.
						</LI>
						<LI>
							18.10. <b>Choice of Law.</b> This Agreement is governed by and construed in accordance with
							the laws of the State of Delaware, excluding that body of laws pertaining to conflict of
							laws. If any provision of this Agreement is determined by a court of law to be illegal or
							unenforceable, such provision will be enforced to the maximum extent possible and the other
							provisions will remain effective and enforceable.
						</LI>
						<LI>
							18.11. <b>Survival.</b> All provisions of this Agreement, which by their nature extend
							beyond the termination of this Agreement, including, without limitation, sections related to
							suspension and arbitration, shall survive the termination or expiration of this Agreement.
						</LI>
						<LI>
							18.12. <b>No Waiver.</b> The failure by Bakkt to enforce any right or provision of this
							Agreement will not constitute a waiver of future enforcement of that right or provision.
						</LI>
						<LI>
							18.13. <b>Severability.</b> If any provision of this Agreement is determined to be invalid,
							illegal, void or unenforceable, under any rule, law, or regulation of any local, state, or
							federal government agency, such provision will be changed and interpreted to accomplish the
							objectives of the provision to the greatest extent possible under any applicable rule, law,
							or regulation and the validity or enforceability of any other provision of this Agreement
							shall not be affected.
						</LI>
						<LI>
							18.14. <b>Force Majeure.</b> Bakkt will not be liable or responsible to you, nor will Bakkt
							be deemed to have defaulted or breached this Agreement, for any failure or delay in our
							performance under this Agreement when and to the extent such failure or delay is caused by
							or results from acts or circumstances beyond our reasonable control, including, without
							limitation, acts of God, flood, fire, earthquake, explosion, governmental actions, war,
							invasion or hostilities (whether war is declared or not), terrorist threats or acts, riot or
							other civil unrest, national emergency, revolution, insurrection, epidemic, pandemic,
							lockouts, strikes or other labor disputes (whether or not relating to our workforce), or
							restraints or delays affecting carriers or inability or delay in obtaining supplies of
							adequate or suitable materials, materials or telecommunication breakdown or power outage.
						</LI>
						<LI>
							18.15. <b>Entire Agreement.</b> This Agreement, together with any other terms and
							conditions, policies, appendices, or agreements referenced herein, constitutes the entire
							agreement between the parties concerning your Wallet and the Services and governs your use
							of your Wallet and the Services.
						</LI>
					</ul>
				</LI>

				<LI>
					<b>For Louisiana Residents.</b> Bakkt Marketplace, LLC is licensed by the Louisiana Office of
					Financial Institutions as a money transmitter. The Louisiana Office of Financial Institutions does
					not license or regulate services related to virtual currency, including but not limited to
					transmission or exchange which may be conducted by Bakkt Marketplace, LLC.
				</LI>
			</ol>

			<Medium>
				The section headings used in this Agreement are for convenience only and have no legal effect.
			</Medium>
			<Medium>
				<b>CRYPTOCURRENCY PURCHASE AND SALE TERMS OF SERVICE</b>
			</Medium>
			<Medium>
				These Cryptocurrency Purchase and Sale Terms of Service ("<b>Cryptocurrency Purchase and Sale Terms</b>
				") govern your use of your Account, Wallet or Services offered by Bakkt to conduct the transactions
				contemplated herein. By accessing or using any services provided by Bakkt, you agree to be bound by
				these Cryptocurrency Purchase and Sale Terms, the General Terms of Service, the Privacy Policy, the
				Cookie Policy, the Acceptable Use Policy, and the E-Sign Consent.
			</Medium>
			<Medium>
				Capitalized terms not otherwise defined in these Cryptocurrency Purchase and Sale Terms shall have the
				meanings set forth in the Bakkt Terms of Service.
			</Medium>

			<ol>
				<LI>
					<b>Buying Cryptocurrency.</b> You can initiate a purchase of Cryptocurrency using U.S. Dollars in
					your linked bank account. If you initiate a purchase via your linked bank account, you may be able
					to immediately sell your Cryptocurrency, but you may not withdraw, spend or send the proceeds of
					such a sale until the bank transfer has cleared, which may take several business days. You may
					purchase Cryptocurrency from Bakkt at the exchange rate provided by Bakkt at the time of sale. You
					may not transfer Cryptocurrencies from any external source into the Wallet. Once the transaction is
					complete, the balance in the Wallet will be updated to reflect the amount of Cryptocurrency you own.
				</LI>
				<LI>
					<b>Selling Cryptocurrency.</b> Bakkt does not guarantee that you will be able to sell your
					Cryptocurrency for a profit. If you choose to sell your Cryptocurrency, you may do so from your
					Wallet. You choose the amount of Cryptocurrency you would like to sell and Bakkt will purchase the
					Cryptocurrency from you, subject to its right to refuse to purchase such Cryptocurrency, which it
					may exercise in the event (a) that Bakkt believes that (i) the Cryptocurrency may be derived from
					illicit or illegal activity, or (ii) the transaction may arise from or implicate fraud, (b) of
					technical platform disruptions or similar issues, or (c) of market disruption. When Bakkt purchases
					your Cryptocurrency, it will deposit the value of the Cryptocurrency into your linked bank account
					in U.S. Dollars. The price you receive for the Cryptocurrency you sell is based on the exchange rate
					provided by Bakkt and volume of Cryptocurrency you attempt to sell.
				</LI>
				<LI>
					<b>Sending Cryptocurrency.</b> You may not transfer Cryptocurrencies to any external wallets or
					other sources (except that in the event of Bakkt's bankruptcy, you will be entitled to the return of
					Cryptocurrencies from your Wallet). You authorize us to deduct from your Account fees, costs,
					expenses and claims due and unpaid by you. All Cryptocurrency transactions are at your sole risk.
				</LI>
				<LI>
					<b>Transaction Timing.</b> You understand and agree that when you initiate a payment instruction to
					us through your Wallet, we will begin processing the payment instruction and the designated
					Cryptocurrencies may leave your Wallet as early as the moment of the initiation of such payment
					instruction.
				</LI>
				<LI>
					<b>Exchange Rate.</b> You may buy or sell Cryptocurrencies through the Platform. Bakkt provides the
					exchange rates for the transactions. Exchange rates are the value of the Cryptocurrencies expressed
					in U.S. Dollars as displayed on the Platform. You agree, as a condition of buying or selling
					Cryptocurrencies through the Platform, to accept Bakkt's exchange rate as the sole conversion
					metric.
					<Medium>
						Bakkt will determine the exchange rates for Cryptocurrencies in its discretion. The exchange
						rate may take into account a number of factors determined by Bakkt, including data from external
						price sources selected by Bakkt, price quotations obtained by Bakkt from market makers or
						liquidity providers, and Bakkt's internal models. Exchange rates offered by Bakkt may differ
						from prices that may be available from other providers or markets. In establishing exchange
						rates, Bakkt is not required to act in your interest, and Bakkt has no obligation to seek or
						provide the best available price at any given time. The exchange rates at which Bakkt will buy a
						given Cryptocurrency, and the exchange rates at which Bakkt will sell that Cryptocurrency, at
						any given time will generally differ. In addition, the exchange rate offered to you may also
						include a markup, spread or other fee charged by Bakkt as compensation with respect to the
						transaction.
					</Medium>
				</LI>
				<LI>
					<b>Role of Bakkt.</b> In buying or selling Cryptocurrencies, Bakkt will act as your counterparty,
					meaning it will act as seller when you buy Cryptocurrency, and it will act as buyer when you sell
					Cryptocurrency. Bakkt will not be your agent or broker. Bakkt's role may present a conflict of
					interest between you and Bakkt. Bakkt may enter into transactions for its own account in
					Cryptocurrencies with third parties (such as market makers and liquidity providers), including to
					hedge its own exposure to transactions with customers and/or manage its inventory of
					Cryptocurrencies for customer transactions. Although you will have no direct relationship with these
					third parties, Bakkt's transactions with such parties may affect the exchange rates established by
					Bakkt for transactions in Cryptocurrencies with you and other customers. Bakkt may enter into
					arrangements with market makers, liquidity providers or other market participants that give Bakkt a
					financial or other incentive to transact with such persons, for the benefit of Bakkt, which may
					affect the exchange rates available for buying or selling Cryptocurrencies.
				</LI>
				<LI>
					<b>Cryptocurrency Holding.</b> Following your purchase of Cryptocurrency from Bakkt, all rights in
					the Cryptocurrency pass to you from Bakkt, but Bakkt is not obligated to reflect the transaction on
					any network or platform outside of Bakkt's ledger. Bakkt may hold the Cryptocurrency in your account
					and the accounts of other Bakkt customers through one or more of its affiliates or other persons
					acting as custodian. You will be subject to the risk of any failure of the custodian or loss or
					theft of Cryptocurrency held with the custodian.
				</LI>
				<LI>
					<b>Cryptocurrency Standards.</b> Bakkt engages in certain due diligence measures prior to listing
					Cryptocurrencies. Bakkt uses proprietary risk scoring processes to determine whether the
					Cryptocurrencies it offers for sale on its platform have met its due diligence standards. You
					acknowledge that Bakkt's due diligence standards may impact the value of your Cryptocurrency. Bakkt
					is not liable for fluctuations in the value of your Cryptocurrency that may result from Bakkt's risk
					scoring measures. Bakkt offers ETH, which is drawn from the New York Department of Financial
					Services (NYDFS) Greenlist, and BTC, which has been added through specific NYDFS approval.
				</LI>
				<LI>
					<b>Market Manipulation.</b> Bakkt prohibits any type of fraud or market manipulation in the buying
					or selling of Cryptocurrencies. Bakkt will suspend and close any accounts engaging in these types of
					activities and, if appropriate, notify the appropriate authorities. Market manipulation activities
					include, but are not limited to:
					<ul style={noListStyle}>
						<LI>
							9.1. <b>Pump and Dumps:</b> Drumming up enthusiasm for a Cryptocurrency by evangelizing it
							on multiple channels, including social media, with the goal of instigating a coordinated
							purchasing frenzy to drive the Cryptocurrency's price higher before dumping the
							Cryptocurrency for a profit.
						</LI>
						<LI>
							9.2. <b>Wash Trading:</b> Selling and repurchasing the same Cryptocurrency at or about the
							same price to generate trading activity and without an intent to take a bona fide position.
						</LI>
						<LI>
							9.3. <b>Front-Running:</b> Entering a trade with prior knowledge of a future transaction by
							another person that will affect the price of the Cryptocurrency.
						</LI>
						<LI>
							9.4. <b>Quote Stuffing:</b> Quickly entering and withdrawing large quantities of orders
							attempting to flood the market, thereby gaining an advantage over slower market
							participants.
						</LI>
						<LI>
							9.5. <b>Spoofing/Layering:</b> Making and then canceling orders that a trader never intends
							to have executed in hopes of influencing a Cryptocurrency's price.
						</LI>
					</ul>
				</LI>
			</ol>
		</div>
	)
}
