import styled from '@emotion/styled'
import {ReactNode} from 'react'
import {useEffect, useState} from 'react'
import {makeStyles, useMediaQuery, useTheme} from '@material-ui/core'
import {ThemeOptions} from '@material-ui/core'
import {PoweredBy} from '../poweredBy/PoweredBy'
import {Logo} from '../logo/Logo'

const TitleHeader = styled.h1`
	display: flex;
	justify-content: center;
	color: ${props => props?.theme?.palette?.text?.background || 'black'};
	font-family: ${props => props?.theme?.typography?.fontFamily || 'BrownPro'};
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0.005em;
	@media (min-width: 720px) {
		color: ${props => props?.theme?.palette?.text?.externalBackground || '#100722'};
		font-weight: 700;
		font-size: 24px;
	}
`

const useStyles = makeStyles(theme => ({
	centerIcon: {
		marginTop: 8,
		fill: theme?.palette?.text?.background || 'black',
		[theme.breakpoints.up(720)]: {
			fill: theme?.palette?.text?.externalBackground || 'black',
		},
	},
}))

export type HeaderRowCenterProps = {
	centerIcon?: (props: any) => JSX.Element
	id?: string
	titleHeader?: string
	TitleHeaderComp?: ReactNode
	withLogo?: boolean
	showJointLogo?: boolean
}

export const HeaderRowCenter = ({
	centerIcon,
	id = 'header',
	titleHeader,
	showJointLogo,
	TitleHeaderComp,
	withLogo = true,
}: HeaderRowCenterProps) => {
	const onDesktopScreen = useMediaQuery('(min-width:720px)')
	const onMobileScreen = useMediaQuery('(max-width:719px)')

	const theme = useTheme() as ThemeOptions
	const styles = useStyles()
	const titleHeaderId = titleHeader?.toLocaleLowerCase().replace(/\s/g, '-')

	const headerRowCenter = () => {
		if (TitleHeaderComp) return TitleHeaderComp
		if (theme.logoUrl && onMobileScreen && showJointLogo)
			return (
				<>
					<div style={{display: 'flex', marginBottom: '50px'}}>
						<div style={{display: 'flex', alignItems: 'center'}}>
							<Logo id={`${id}-center-aligned`} style={{margin: '2px 10px 0px 0px'}} />
						</div>
						<PoweredBy style={{margin: 0}} usePlusSign={true} />
					</div>
				</>
			)
		if (theme.logoUrl && onMobileScreen && !titleHeader && !showJointLogo)
			return <Logo id={`${id}-center-aligned`} style={{marginTop: '10px'}} />
		if (titleHeader && (onDesktopScreen || !showJointLogo || !theme.logoUrl))
			return (
				<TitleHeader id={`${titleHeaderId}-text`} data-testid={`${id}-navigation-title`}>
					{titleHeader}
				</TitleHeader>
			)
		if (centerIcon)
			return centerIcon({
				className: styles.centerIcon,
			})
	}

	return <div id={`${id}-row-center`}>{headerRowCenter()}</div>
}
