import React, {ReactElement} from 'react'

import {act, fireEvent, render, RenderOptions, RenderResult, within} from '@testing-library/react'
import userEvent from '@testing-library/user-event'
import Enzyme, {shallow, ShallowRendererProps} from 'enzyme'
import Adapter from 'enzyme-adapter-react-16'

type ExpectAllData = [string, number]

const isExpectAllData = (data: any[]): data is ExpectAllData => {
	return data.length === 2 && typeof data[0] === 'string' && typeof data[1] === 'number'
}

interface RenderResultWithHelpers extends RenderResult {
	blurInput: (accessibilityLabel: string) => void
	changeInput: (id: string, value: string) => void
	expectCheckBoxToBeChecked: (testId: string) => void
	expectCheckBoxToBeUnchecked: (testId: string) => void
	expectDisplayValue: (displayValue: string) => void
	expectDisplayValueToBeAbsent: (displayValue: string) => void
	expectLabel: (accessibilityLabel: string[] | string) => void
	expectLabelToBeAbsent: (accessibilityLabel: string[] | string) => void
	expectId: (id: string | string[]) => void
	expectTestId: (testId: string | string[]) => void
	expectIdToBeAbsent: (id: string | string[]) => void
	expectTestIdToBeAbsent: (testId: string | string[]) => void
	expectAllText: (text: ExpectAllData | Array<ExpectAllData>) => void
	expectTextAtLeastOnce: (text: string | string[]) => void
	expectTextTimes: (text: string | string[], times?: number) => void
	expectTextTimesExact: (text: string, times?: number) => void
	expectText: (text: string | string[]) => void
	expectTextWithinTestId: (text: string | string[], testId: string) => void
	expectTextToBeAbsent: (text: string | string[]) => void
	expectToBeDisabled: (text: string | string[]) => void
	clickElement: (id: string) => void
	clickText: (text: string) => void
}

export const generateTestHelpers = (renderArgs: RenderResult): RenderResultWithHelpers => {
	const blurInput = (accessibilityLabel: string) =>
		act(() => {
			fireEvent.blur(renderArgs.getByLabelText(accessibilityLabel))
		})

	const changeInput = (id: string, value: string) =>
		act(() => {
			const element = renderArgs.baseElement.querySelector(`#${id}`)
			if (element) {
				fireEvent.change(element, {target: {value}})
			}
		})

	const expectCheckBoxToBeChecked = (testId: string) =>
		expect(renderArgs.getByTestId(testId)).toHaveProp('accessibilityLabel', 'Checked')

	const expectCheckBoxToBeUnchecked = (testId: string) =>
		expect(renderArgs.getByTestId(testId)).toHaveProp('accessibilityLabel', 'Unchecked')

	const expectDisplayValue = (displayValue: string) => expect(renderArgs.getByDisplayValue(displayValue)).toBeTruthy()

	const expectDisplayValueToBeAbsent = (displayValue: string) =>
		expect(renderArgs.queryByDisplayValue(displayValue)).toBeFalsy()

	const expectLabel = (accessibilityLabel: string[] | string) =>
		typeof accessibilityLabel === 'string'
			? expect(renderArgs.getByLabelText(accessibilityLabel)).toBeTruthy()
			: accessibilityLabel.forEach(expectLabel)

	const expectLabelToBeAbsent = (accessibilityLabel: string[] | string) =>
		typeof accessibilityLabel === 'string'
			? expect(renderArgs.queryByLabelText(accessibilityLabel)).toBeFalsy()
			: accessibilityLabel.forEach(expectLabelToBeAbsent)

	const expectId = (id: string | string[]) =>
		Array.isArray(id) ? id.forEach(expectId) : expect(renderArgs.baseElement.querySelector(`#${id}`)).toBeTruthy()

	const expectTestId = (testId: string | string[]) =>
		Array.isArray(testId) ? testId.forEach(expectTestId) : expect(renderArgs.getByTestId(testId)).toBeTruthy()

	const expectIdToBeAbsent = (id: string | string[]) =>
		Array.isArray(id)
			? id.forEach(expectIdToBeAbsent)
			: expect(renderArgs.baseElement.querySelector(`#${id}`)).toBeFalsy()

	const expectTestIdToBeAbsent = (testId: string | string[]) =>
		Array.isArray(testId)
			? testId.forEach(expectTestIdToBeAbsent)
			: expect(renderArgs.queryByTestId(testId)).toBeFalsy()

	const expectAllText = (text: ExpectAllData | Array<ExpectAllData>) => {
		isExpectAllData(text)
			? expect(renderArgs.getAllByText(text[0]).length).toEqual(text[1])
			: text.forEach(expectAllText)
	}

	const expectTextAtLeastOnce = (text: string | string[]) => {
		expectTextTimes(text)
	}

	const expectTextTimes = (text: string | string[], times = 1) =>
		Array.isArray(text)
			? text.forEach(expectTextAtLeastOnce)
			: expect(renderArgs.getAllByText(text).length).toBeGreaterThanOrEqual(times)

	const expectTextTimesExact = (text: string, times = 1) =>
		expect(renderArgs.getAllByText(text).length).toEqual(times)

	const expectText = (text: string | string[]) =>
		Array.isArray(text) ? text.forEach(expectText) : expect(renderArgs.getByText(text)).toBeTruthy()

	const expectTextWithinTestId = (text: string | string[], testId: string): void =>
		Array.isArray(text)
			? text.forEach(t => expectTextWithinTestId(t, testId))
			: expect(within(renderArgs.getByTestId(testId)).getByText(text)).toBeTruthy()

	const expectTextToBeAbsent = (text: string | string[]) =>
		Array.isArray(text)
			? text.forEach(expectTextToBeAbsent)
			: expect(renderArgs.queryAllByText(text)).toHaveLength(0)

	const expectToBeDisabled = (text: string | string[]) =>
		Array.isArray(text) ? text.forEach(expectToBeDisabled) : expect(renderArgs.getByText(text)).toBeDisabled()

	const clickElement = (id: string) => {
		const element = renderArgs.baseElement.querySelector(`${id}`) || renderArgs.baseElement.querySelector(`#${id}`)
		if (!element) throw `Could not find element with id: ${id}`
		userEvent.click(element)
	}

	const clickText = (text: string) => {
		act(() => {
			fireEvent.click(renderArgs.getByText(text))
		})
	}

	return {
		...renderArgs,
		blurInput,
		changeInput,
		expectCheckBoxToBeChecked,
		expectCheckBoxToBeUnchecked,
		expectDisplayValue,
		expectDisplayValueToBeAbsent,
		expectLabel,
		expectLabelToBeAbsent,
		expectAllText,
		expectTestId,
		expectId,
		expectTestIdToBeAbsent,
		expectIdToBeAbsent,
		expectText,
		expectTextTimes,
		expectTextTimesExact,
		expectTextAtLeastOnce,
		expectTextWithinTestId,
		expectTextToBeAbsent,
		expectToBeDisabled,
		clickElement,
		clickText,
	}
}

interface shallowRenderProps {
	wrapper: Enzyme.ShallowWrapper
	expectChild: (child: any) => void
	expectChildToBeAbsent: (child: any) => void
	expectChildHasProps: (child: any, props: any) => void
}

export const shallowRender = (component: ReactElement, options?: ShallowRendererProps): shallowRenderProps => {
	Enzyme.configure({adapter: new Adapter()})
	const wrapper = shallow(component, options)

	const expectChild = (child: any) => expect(wrapper.find(child).length).toBeGreaterThanOrEqual(1)
	const expectChildToBeAbsent = (child: any) => expect(wrapper.find(child).length).toEqual(0)

	const expectChildHasProps = (child: any, expectedProps: any) => {
		const actualProps = (wrapper.find(child).props() as {[key: string]: any}) || {}
		Object.keys(expectedProps).forEach(key => expect(actualProps[key]).toEqual(expectedProps[key]))
	}

	return {
		wrapper,
		expectChild,
		expectChildToBeAbsent,
		expectChildHasProps,
	}
}

export const renderWithProviders = (
	component: React.ReactElement,
	options: RenderOptions = {},
): RenderResultWithHelpers => {
	const RenderContainer: React.FC = ({children}) => <>{children}</>
	const renderArgs = render(component, {wrapper: RenderContainer, ...options})
	return generateTestHelpers(renderArgs)
}
