import {useEffect} from 'react'
import {BasicPartyInfo} from '@bakkt/api'

export const useSegment = (user?: BasicPartyInfo | null) => {
	useEffect(() => {
		const setUpSegmentIdentify = () => {
			let analytics = (window.analytics = window.analytics || [])
			analytics.identify(user?.brazePartyRef)
		}
		if (user?.brazePartyRef) {
			setUpSegmentIdentify()
		}
	}, [user?.brazePartyRef])
}
