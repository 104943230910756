export const RoutesName = {
	home: '/',
	onboarding: {
		home: '/onboarding/',
		kyc: 'kyc',
		taxpayerCertification: 'taxpayer/certification',
		success: 'success',
		accountExists: 'accountExists',
		hawaiiError: 'hawaiiError',
	},
	crypto: {
		buy: 'buy',
		confirm: 'confirm',
		success: 'success',
		sell: 'sell',
		funding: 'funding',
		overview: 'overview',
		transaction: {
			detail: 'detail',
		},
	},
	debitCard: {
		main: 'debit-card',
		child: {
			general: 'general',
			shippingAddress: 'shipping-address',
			cryptoDebitDetail: 'crypto-debit-detail',
		},
	},
	cryptoActivated: 'crypto-activated',
	activity: {
		main: 'activity',
		child: {
			boughtBTC: 'bought-btc',
		},
	},
	myCrypto: 'my-crypto',
	termsOfUse: '/terms-of-use',
}
