import {useTranslation} from 'react-i18next'
import {useEffect, useState} from 'react'
import {apiCall, embeddedPartnerApi} from 'api'
import {
	ApiEmbeddedPartnerTransaction,
	ApiEmbeddedPartnerTransactionOperationTypeEnum as CryptoCurrencyTransactTypeEnum,
} from '@bakkt/api'
import {DetailSection} from './DetailSection'
import {useStore} from 'store'
import {ActivityHeader, Icons, Main} from '@bakkt/components'
import Page from 'components/Page'
import {formatUSD} from 'utils/currencyFormatters'
import {logger} from 'utils/logger/datadogInit'
import {useCryptoCurrencyDetails} from 'hooks/useCryptoCurrencyDetails'

export const TransactionDetails = () => {
	const {t} = useTranslation()
	const {activePartnerTransactionId} = useStore()
	const [transactionInfo, setTransactionInfo] = useState<ApiEmbeddedPartnerTransaction | null>(null)
	const [loading, setLoading] = useState(true)
	const transactionType = transactionInfo?.operationType
	const crypto =
		transactionType === CryptoCurrencyTransactTypeEnum.BUY
			? transactionInfo?.target
			: transactionType === CryptoCurrencyTransactTypeEnum.SELL
			? transactionInfo?.source
			: null

	const fiatAmount =
		transactionType === CryptoCurrencyTransactTypeEnum.BUY
			? transactionInfo?.source
			: transactionType === CryptoCurrencyTransactTypeEnum.SELL
			? transactionInfo?.target
			: null
	const currencyDetails = useCryptoCurrencyDetails(crypto?.currency)

	useEffect(() => {
		logger.info('Over view page, Transaction details - initilized', {filename: 'TransactionDetails.tsx'})
	}, [])

	useEffect(() => {
		if (activePartnerTransactionId) {
			;(async () => {
				const {response} = await apiCall(() =>
					embeddedPartnerApi.getTransactionDetails(activePartnerTransactionId),
				)
				setLoading(false)
				setTransactionInfo(response || null)
				logger.info('Over view page, Transaction details - api call success', {
					status: response?.status,
					currencyType: response?.operationType,
					sourceCurrency: response?.source?.currency,
					targetCurrency: response?.target?.currency,
				})
			})()
		}
	}, [activePartnerTransactionId])

	return (
		<Page
			withBackIcon
			titleHeader={`${transactionType === CryptoCurrencyTransactTypeEnum.BUY ? t('purchased') : t('sold')} ${
				currencyDetails?.name
			}`}
			loading={loading}>
			<Main>
				<ActivityHeader
					icon={transactionType === CryptoCurrencyTransactTypeEnum.BUY ? Icons.BuyCrypto : Icons.SellCrypto}
					activityText={
						transactionType === CryptoCurrencyTransactTypeEnum.BUY
							? t('activity.youPurchased')
							: t('activity.youSold')
					}
					amount={`${crypto?.amount} ${crypto?.currency}`}
					conversionAmount={`${t('for')}  ${
						fiatAmount?.amount && formatUSD(fiatAmount?.amount || 0, fiatAmount?.currency)
					}`}
				/>
				<DetailSection
					currencyDetails={currencyDetails}
					transactionInfo={transactionInfo}
					fiatAmount={fiatAmount}
					transactionType={transactionType}
					crypto={crypto}
				/>
			</Main>
		</Page>
	)
}
