import {HBox, color, Icons, Light, useTheme, FKGrotesk} from '@bakkt/components'
import {Theme} from '@material-ui/core'
import {ReactElement, ReactNode} from 'react'
import {handleKeyPress} from 'utils/handleKeyPress'

export interface ReceiptItemProps {
	style?: React.CSSProperties
	name?: string
	value?: ReactElement | ReactNode | JSX.Element
	children?: ReactElement[]
	onInfoClicked?: () => void
}

export const ReceiptItem = ({name, value, children, onInfoClicked, style = {}}: ReceiptItemProps) => {
	const theme = useTheme() as Theme

	return (
		<HBox
			style={{
				borderBottom: '1px solid',
				borderBottomColor: theme?.palette?.text?.background || color.grey100,
				padding: '0.4rem 0 0.8rem 0',
				marginTop: '0.3rem',
				marginBottom: '0.3rem',
				alignItems: 'center',
				...style,
			}}
		>
			{name && (
				<Light data-testid={`${name}-label`} style={{margin: 0}}>
					{name}
				</Light>
			)}
			{onInfoClicked && (
				<Icons.InfoCircle
					id={name ? `${name.toLowerCase()}-info-button` : 'info-button'}
					onClick={onInfoClicked}
					style={{marginLeft: 5, transform: 'scale(0.7, 0.7)', cursor: 'pointer'}}
					fill={theme?.palette?.text?.background || color.grey700}
					tabIndex={0}
					onKeyPress={event => handleKeyPress(event, onInfoClicked)}
				/>
			)}
			{value && (
				<div style={{marginLeft: 'auto'}}>
					<div
						data-testid={`${name}-value`}
						style={{
							fontWeight: 400,
							margin: 0,
							color: theme?.palette?.text?.background || color.grey500,
							fontSize: '14px',
							fontFamily: theme?.typography?.fontFamily || FKGrotesk,
						}}
					>
						{value}
					</div>
				</div>
			)}
			{children || [].map((child, i) => child)}
		</HBox>
	)
}
