import React, {HTMLAttributes} from 'react'
import styled from '@emotion/styled'
import {color} from 'src/color/color'
import {Theme} from '@material-ui/core'

export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
	color?: string
	card?: boolean
	hideShadow?: boolean
	borderRadius?: number
	theme?: Theme
}

const DefaultContainer = styled.div`
	display: inline-flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: flex-start;
	padding: 0.5rem;
	background: ${color.white};
	box-shadow: ${(props: ContainerProps) =>
		props.hideShadow ? 'none' : `0px 5px 18px ${props?.theme?.palette?.text?.background || '#0f2b29'}26`};
	border-radius: ${(props: ContainerProps) => (props.card ? '8px' : props.borderRadius || '4px')};
	overflow: visible;
`

export const ShadowContainer: React.FC<ContainerProps> = ({children, ...prop}) => (
	<DefaultContainer {...prop}>{children}</DefaultContainer>
)
