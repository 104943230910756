import {useState, useEffect} from 'react'
import {color, Spinner, Icons, BottomBox, Main, toTitleCase} from '@bakkt/components'
import Button from 'components/Button'
import {CurrencyFlipper} from 'components/CurrencyFlipper'
import {FundingAccountRow, CryptoAccountRow} from 'components/fundingRows'
import Page from 'components/Page'
import {TransactType, TransactTypeEnum, useStore} from 'store'
import {formatCurrency} from 'utils/currencyFormatters'
import {logger} from 'utils/logger/datadogInit'
import {ReceiptItem} from 'components/ReceiptItem'
import {Receipt} from 'components/Receipt'
import {apiCall, embeddedPartnerApi} from 'api'
import {showDialog} from 'components/Dialog'
import {RoutesName} from 'constants/routes'
import {t} from 'i18next'
import {ApiAmountCurrencyEnum, ApiEmbeddedCryptoTransactionRequestTransactTypeEnum, FiatAmount} from '@bakkt/api'
import {useSelectedCryptoAccountInfo} from 'hooks/useSelectedCryptoAccountInfo'
import {useNavigate, useCryptoCurrencyPrices} from 'hooks'
import {showTransactionErrorDialog} from 'components/ErrorDialog'

type Props = {
	transactionType: TransactTypeEnum
}
export const CryptoTransactionConfirm = ({transactionType}: Props) => {
	const {cryptoTransactionConfirm, partner, selectedFundingAccount, setCryptoTransaction} = useStore()
	const {selectedCryptoCurrencyName, selectedCryptoCurrency, selectedCryptoCurrencyPrecision} =
		useSelectedCryptoAccountInfo()
	const {cryptoCurrencyPrice} = useCryptoCurrencyPrices(selectedCryptoCurrency)
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		logger.info('Crypto transaction confirm page - initilized', {
			filename: 'CryptoTransactionConfirm.tsx',
			currencyName: selectedCryptoCurrencyName,
			transactionType,
		})
	}, [])

	const doTransaction = async () => {
		logger.info(`Crypto transaction confirm page - ${transactionType} ${selectedCryptoCurrencyName} clicked`, {
			currencyName: selectedCryptoCurrencyName,
			transactionType,
		})

		setLoading(true)
		const {response, error} = await apiCall(() =>
			embeddedPartnerApi.transactCryptoCurrency({
				transactType: (cryptoTransactionConfirm?.transactType ||
					'BUY') as ApiEmbeddedCryptoTransactionRequestTransactTypeEnum,
				quantity: {
					amount:
						cryptoTransactionConfirm?.quantity?.amount &&
						Number(cryptoTransactionConfirm?.quantity?.amount?.toFixed(8)),
					currency: cryptoTransactionConfirm?.quantity?.currency as ApiAmountCurrencyEnum,
				},
				price: cryptoTransactionConfirm?.totalPrice as FiatAmount,
				externalAccountRef: selectedFundingAccount?.externalAccountRef || '',
			}),
		)

		setLoading(false)
		if (error) {
			if (error.code !== 'PARTY_SUSPENDED') {
				showTransactionErrorDialog(partner)
			}
		} else {
			setCryptoTransaction(response as any)
			navigate(
				`/${transactionType === TransactType.BUY ? RoutesName.crypto.buy : RoutesName.crypto.sell}/${
					RoutesName.crypto.success
				}`,
				{replace: true, ignore: true},
			)
		}
	}

	return (
		<Page
			withBackIcon
			titleHeader={
				transactionType === TransactType.SELL
					? `Sell ${selectedCryptoCurrencyName}`
					: `Buy ${selectedCryptoCurrencyName}`
			}
			title={t('titles.transaction.overview', {
				type: toTitleCase(transactionType),
				coin: selectedCryptoCurrencyName,
			})}
		>
			{loading ? (
				<div style={{marginTop: 'auto', marginLeft: 'auto', marginRight: 'auto', marginBottom: '-50px'}}>
					<Spinner />
				</div>
			) : (
				<Main>
					<CurrencyFlipper
						disabledInput
						fiat={cryptoTransactionConfirm?.totalPrice?.amount || 0}
						crypto={cryptoTransactionConfirm?.quantity?.amount || 0}
					/>

					{transactionType === TransactType.BUY && (
						<Receipt>
							<FundingAccountRow
								style={{padding: '0.4rem 0 0.8rem 0'}}
								label={t('label.fundingSource')}
							/>
							<ReceiptItem
								name={`${selectedCryptoCurrency} ${t('crypto.confirmation.price')}`}
								value={formatCurrency(
									cryptoCurrencyPrice?.indicativePrice?.amount || 0,
									cryptoTransactionConfirm?.totalPrice?.currency,
									selectedCryptoCurrencyPrecision,
								)}
							/>
							<ReceiptItem
								name={`${selectedCryptoCurrency} ${t('crypto.confirmation.valuePurchased')}`}
								value={formatCurrency(
									cryptoTransactionConfirm?.price?.amount || 0,
									cryptoTransactionConfirm?.price?.currency,
									selectedCryptoCurrencyPrecision,
								)}
							/>
							<ReceiptItem
								name={t('crypto.confirmation.fee')}
								value={formatCurrency(
									cryptoTransactionConfirm?.transactionFee?.amount || 0,
									cryptoTransactionConfirm?.transactionFee?.currency,
									selectedCryptoCurrencyPrecision,
								)}
								onInfoClicked={() =>
									showDialog({
										title: t('crypto.confirmation.fees'),
										message: t('crypto.confirmation.spreadInfo'),
										hideSave: true,
										hideCancel: true,
										skip: true,
										skipText: t('btnOkGotIt'),
										skipButtonStyle: {color: color.blue700},
										icon: Icons.DialogInfo,
									})
								}
							/>
							<ReceiptItem
								style={{borderBottom: 'none'}}
								name={t('crypto.confirmation.totalCost')}
								value={formatCurrency(
									cryptoTransactionConfirm?.totalPrice?.amount || 0,
									cryptoTransactionConfirm?.totalPrice?.currency,
									selectedCryptoCurrencyPrecision,
								)}
							/>
						</Receipt>
					)}
					{transactionType === TransactType.SELL && (
						<Receipt>
							<CryptoAccountRow style={{padding: '0.4rem 0 0.8rem 0'}} />
							<ReceiptItem
								name={t('crypto.confirmation.cryptoToSell', {
									cryptoCurrencyName: selectedCryptoCurrencyName,
								})}
								value={formatCurrency(
									cryptoTransactionConfirm?.quantity?.amount || 0,
									cryptoTransactionConfirm?.quantity?.currency,
									selectedCryptoCurrencyPrecision,
								)}
							/>
							<ReceiptItem
								name={`${selectedCryptoCurrency} ${t('crypto.confirmation.price')}`}
								value={formatCurrency(
									cryptoCurrencyPrice?.indicativePrice?.amount || 0,
									cryptoTransactionConfirm?.totalPrice?.currency,
									selectedCryptoCurrencyPrecision,
								)}
							/>
							<ReceiptItem
								name={t('crypto.confirmation.sellPrice')}
								value={formatCurrency(
									cryptoTransactionConfirm?.price?.amount || 0,
									cryptoTransactionConfirm?.price?.currency,
									selectedCryptoCurrencyPrecision,
								)}
							/>
							<ReceiptItem
								name={t('crypto.confirmation.fee')}
								value={formatCurrency(
									cryptoTransactionConfirm?.transactionFee?.amount || 0,
									cryptoTransactionConfirm?.transactionFee?.currency,
									selectedCryptoCurrencyPrecision,
								)}
								onInfoClicked={() =>
									showDialog({
										title: t('crypto.confirmation.fees'),
										message: t('crypto.confirmation.spreadInfo'),
										hideSave: true,
										hideCancel: true,
										skip: true,
										skipText: t('btnOkGotIt'),
										skipButtonStyle: {color: color.blue700},
										icon: Icons.DialogInfo,
									})
								}
							/>
							<ReceiptItem
								style={{borderBottom: 'none'}}
								name={t('crypto.confirmation.totalProceeds')}
								value={formatCurrency(
									cryptoTransactionConfirm?.totalPrice?.amount || 0,
									cryptoTransactionConfirm?.totalPrice?.currency,
									selectedCryptoCurrencyPrecision,
								)}
							/>
							<FundingAccountRow
								label={t('label.destination')}
								style={{
									borderBottom: 'none',
									borderTop: '5px solid',
									borderTopColor: color.grey100,
									padding: '0.4rem 0.4rem 0.8rem 0.8rem',
								}}
							/>
						</Receipt>
					)}
				</Main>
			)}
			<BottomBox>
				<Button
					id='crypto-transaction-confirm-button'
					onClick={doTransaction}
					loading={loading}
					disabled={loading || !selectedFundingAccount || !cryptoTransactionConfirm?.price}
					hidden={loading}
				>
					{transactionType === TransactType.BUY
						? t('crypto.confirmation.buyNow')
						: t('crypto.confirmation.sellNow')}
				</Button>
			</BottomBox>
		</Page>
	)
}
