import {Suspense, useEffect, useState} from 'react'
import {Routes, Route, Navigate, useLocation} from 'react-router-dom'
import {ApiEmbeddedPartnerTransactionOperationTypeEnum as TransactionType} from '@bakkt/api'
import {Progress} from '@bakkt/components'
import {useStore} from 'store'
import Consent from 'apps/onboarding/pages/Consent'
import ExistingAccountErrorPage from 'apps/onboarding/pages/ExistingAccountErrorPage'
import {CoinOverview} from 'apps/main/pages/crypto/overview'
import {CryptoTransactionInput} from 'apps/main/pages/crypto/transaction/CryptoTransactionInput'
import {CryptoTransactionSuccess} from 'apps/main/pages/crypto/transaction/CryptoTransactionSuccess'
import CryptoAccountActivationSuccess from 'apps/onboarding/pages/ActivationSuccess/ActivationSuccess'
import {HawaiiStateErrorPage} from 'apps/onboarding/ErrorPage/HawaiiStateError'
import TaxpayerCertification from 'apps/onboarding/pages/Taxpayer/TaxpayerCertification'
import {PersonalInformationInput} from 'apps/onboarding/pages/PersonalInformation/PersonalInformationInput'
import {RoutesName} from 'constants/routes'
import {CryptoTransactionConfirm} from 'apps/main/pages/crypto/transaction/CryptoTransactionConfirm'
import {SelectCryptoAccount} from 'apps/main/pages/crypto/cryptoAccounts/selectCryptoAccounts'
import {useSetupSardine, useSift, useSegment} from 'hooks'
import {TransactionDetails} from 'apps/main/pages/crypto/transaction/detail/TransactionDetails'
import TermsOfUse from 'apps/main/pages/Speedbump/TermsOfUse'
import {t} from 'i18next'
import {IdleTimerProvider} from 'react-idle-timer'
import {showErrorDialog} from 'components/ErrorDialog'
import {logout} from 'api'
import {useTranslation} from 'react-i18next'
import {logger} from 'utils/logger/datadogInit'

const defaultIdleTimeOutInSeconds = 1800
const milliSeconds = 1000

const App = () => {
	const [idle, setIdle] = useState<boolean>(false)
	const [actionAfterIdle, setActionAfterIdle] = useState<boolean>(true)
	const {loadOnStartup, party, partner} = useStore()
	const {t} = useTranslation()

	useSetupSardine(party?.sardinePartyRef)
	useSegment(party)
	useSift(party?.siftPartyRef)

	useEffect(() => {
		logger.info('App initilized', {filename: 'App.tsx'})
		loadOnStartup()
	}, [])

	const onIdle = () => {
		setIdle(true)
		logout()
	}

	const onAction = (event: Event | undefined) => {
		if (idle && actionAfterIdle && event?.type === 'mousedown') {
			setActionAfterIdle(false)
			showErrorDialog({
				message: t('errors.idleMessage'),
				saveText: t('crypto.returnToPartnerButton', {partnerName: partner?.name}),
			})
		}
	}
	return (
		<Suspense fallback={<p>Loading...</p>}>
			<IdleTimerProvider
				timeout={(partner?.inactivityTimeoutInSeconds ?? defaultIdleTimeOutInSeconds) * milliSeconds}
				onIdle={onIdle}
				onAction={onAction}>
				<Routes>
					<Route
						path={`${RoutesName.onboarding.home}*`}
						element={
							<RequireNotEnrolled>
								<Routes>
									<Route element={<Consent />} path='' />
									<Route element={<PersonalInformationInput />} path={RoutesName.onboarding.kyc} />
									<Route
										element={<TaxpayerCertification />}
										path={RoutesName.onboarding.taxpayerCertification}
									/>
									<Route
										element={<CryptoAccountActivationSuccess />}
										path={RoutesName.onboarding.success}
									/>
									<Route
										element={<ExistingAccountErrorPage />}
										path={RoutesName.onboarding.accountExists}
									/>
									<Route
										element={<HawaiiStateErrorPage />}
										path={RoutesName.onboarding.hawaiiError}
									/>
								</Routes>
							</RequireNotEnrolled>
						}
					/>
					<Route element={<TransactionDetails />} path={RoutesName.crypto.transaction.detail} />
					<Route element={<TermsOfUse />} path={RoutesName.termsOfUse} />
					<Route
						path={`${RoutesName.home}*`}
						element={
							<RequireAuth>
								<Routes>
									<Route element={<SelectCryptoAccount />} path='/' />
									<Route
										element={<TaxpayerCertification />}
										path={RoutesName.onboarding.taxpayerCertification}
									/>
									<Route element={<CoinOverview />} path={RoutesName.crypto.overview} />
									<Route path='buy'>
										<Route
											element={<CryptoTransactionInput transactionType={TransactionType.BUY} />}
											path=''
										/>
										<Route
											element={<CryptoTransactionConfirm transactionType={TransactionType.BUY} />}
											path='confirm'
										/>
										<Route
											element={<CryptoTransactionSuccess transactionType={TransactionType.BUY} />}
											path='success'
										/>
									</Route>
									<Route path='sell'>
										<Route
											element={<CryptoTransactionInput transactionType={TransactionType.SELL} />}
											path=''
										/>
										<Route
											element={
												<CryptoTransactionConfirm transactionType={TransactionType.SELL} />
											}
											path='confirm'
										/>
										<Route
											element={
												<CryptoTransactionSuccess transactionType={TransactionType.SELL} />
											}
											path='success'
										/>
									</Route>
								</Routes>
							</RequireAuth>
						}
					/>
				</Routes>
				<div id='popupArea' />
			</IdleTimerProvider>
		</Suspense>
	)
}

export default App

const RequireAuth = ({children}: {children: JSX.Element}) => {
	const {isEnrolled, partnerPartyLink, isSuspended, party} = useStore()
	const location = useLocation()

	if (party?.ew9CertificationRequired && !location.pathname.includes(RoutesName.onboarding.taxpayerCertification)) {
		return <Navigate to={RoutesName.onboarding.taxpayerCertification} state={{from: location}} replace />
	}

	if (!partnerPartyLink) {
		// Not Authenticated SSO yet
		return <Progress title={t('action.checkingEligibility')} />
	}

	if (!isEnrolled) {
		// Authenticated but not Enroll
		return <Navigate to={RoutesName.onboarding.home} state={{from: location}} replace />
	}

	if (isSuspended && location.pathname !== RoutesName.home) {
		return <Navigate to={RoutesName.home} state={{from: location}} replace />
	}

	return children
}

export const RequireNotEnrolled = ({children}: {children: JSX.Element}) => {
	const {isEnrolled, partnerPartyLink} = useStore()
	const location = useLocation()

	if (!partnerPartyLink) {
		// Not Authenticated SSO yet
		return <Progress title={t('action.checkingEligibility')} />
	}

	if (isEnrolled) {
		// Authenticated but partner is Enrolled
		return <Navigate to={RoutesName.home} state={{from: location}} replace />
	}

	return children
}
