import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {IndicativePriceDisplay} from 'components/IndicativePriceDisplay'
import Page from 'components/Page'
import Button from 'components/Button'
import {
	HBox,
	Icons,
	Large,
	Light,
	Separator,
	TimeSeriesChart,
	VBox,
	color,
	Main,
	H2,
	useTheme,
	Logo,
} from '@bakkt/components'
import {
	useCryptoAccount,
	useCryptoAccountBalance,
	useCryptoCurrencyPrices,
	useCryptoHistoricalCurrency,
	useNavigate,
} from 'hooks'
import {logger} from 'utils/logger/datadogInit'
import {formatCrypto, formatUSD} from 'utils/currencyFormatters'
import {RoutesName} from 'constants/routes'
import {HistoricalPeriod, useStore} from 'store'
import {useSelectedCryptoAccountInfo} from 'hooks/useSelectedCryptoAccountInfo'
import TransactionActivity from '../transaction/activity/TransactionActivity'
import {Theme} from '@material-ui/core'
import {cryptoPercentChange} from 'utils/percentChange'
import {useCryptoCurrencyDetails} from 'hooks/useCryptoCurrencyDetails'

export const CoinOverview = () => {
	const {t} = useTranslation()
	const navigate = useNavigate()
	const {selectedCryptoAccount, setCryptoTransactionConfirm, cryptoAccounts} = useStore()
	const {
		selectedCryptoCurrency,
		selectedCryptoCurrencyName,
		selectedCryptoCurrencyImage,
		selectedCryptoCurrencyPrecision,
	} = useSelectedCryptoAccountInfo()
	const currencyDetail = useCryptoCurrencyDetails(selectedCryptoCurrency)
	const {historicalCryptoCurrency, changeTimeRange, timeRange} = useCryptoHistoricalCurrency(selectedCryptoCurrency)
	useCryptoAccountBalance()
	const {cryptoCurrencyPrice} = useCryptoCurrencyPrices(selectedCryptoCurrency)
	const {accountBalance, indicativeBalance} = selectedCryptoAccount || {}
	const isBuyDisabled = selectedCryptoAccount?.isDisabled || !indicativeBalance
	const isSellDisabled = !indicativeBalance || !accountBalance?.amount
	const [change, setChange] = useState<number>(0)
	const {loading} = useCryptoAccount()
	const theme = useTheme() as Theme

	const BUTTON_ARRAYS = [
		{label: '1D', value: 'DAY', ariaLabel: t('ariaLabels.overview.dayButton', {coin: selectedCryptoCurrencyName})},
		{
			label: '1W',
			value: 'WEEK',
			ariaLabel: t('ariaLabels.overview.weekButton', {coin: selectedCryptoCurrencyName}),
		},
		{
			label: '1M',
			value: 'MONTH',
			ariaLabel: t('ariaLabels.overview.monthButton', {coin: selectedCryptoCurrencyName}),
		},
		{
			label: '1Y',
			value: 'YEAR',
			ariaLabel: t('ariaLabels.overview.yearButton', {coin: selectedCryptoCurrencyName}),
		},
	]

	useEffect(() => {
		logger.info('Over view page - initilized', {
			filename: 'overview/index.tsx',
			currencyName: selectedCryptoCurrencyName,
		})
		setCryptoTransactionConfirm(null)
	}, [])

	useEffect(() => {
		if (!historicalCryptoCurrency.length) return
		setChange(Number(cryptoPercentChange(historicalCryptoCurrency)))
	}, [historicalCryptoCurrency])

	//TODO cryptoBalance can have ETH, and other crypto balance data as well, which we can decide based on url like crypto/btc/overview or crypto/eth/overview
	//TODO currently mocking it with some data. once api is there, we can integrate
	//TODO we need to refactor it with TimeRange enum, when we actually work on this
	const handlePriceChartTimeRange = (timeRange: HistoricalPeriod) => {
		logger.info('Over view page - Chart time range selected', {timeRange})
		changeTimeRange(timeRange)
	}

	return (
		<Page
			withBackIcon
			onIconPress={() => (cryptoAccounts.length > 1 ? navigate(RoutesName.home) : window.history.go(-1))}
			titleHeader={selectedCryptoCurrencyName}
			loading={loading}
		>
			<Main>
				{accountBalance?.currency && (
					<VBox style={{alignItems: 'center', marginBottom: 46}}>
						<Large className='coinName' style={{margin: '10px 0px', fontWeight: 700}}>
							{`${accountBalance?.currency || ''} ${t(`label.value`)} `}
						</Large>
						<H2 style={{margin: 0, fontSize: '48px'}} id='IndicativePriceDisplay'>
							<IndicativePriceDisplay
								selectedCurrency={accountBalance?.currency}
								indicativePrice={cryptoCurrencyPrice?.indicativePrice}
							/>
						</H2>
						<Light
							className='changeIcon'
							style={{color: change >= 0 ? theme?.palette.success.main : theme?.palette.warning.main}}
						>
							{change >= 0 ? (
								<Icons.GoesUp id='UpArrow' style={{fill: theme?.palette.success.main}} />
							) : (
								<Icons.GoesDown id='DownArrow' style={{fill: theme?.palette.warning.main}} />
							)}
							&nbsp;{' '}
							{`${change}% ${
								timeRange === 'YEAR'
									? t('crypto.priceLabelYear')
									: timeRange === 'MONTH'
									? t('crypto.priceLabelMonth')
									: timeRange === 'WEEK'
									? t('crypto.priceLabelWeek')
									: t('crypto.priceLabelDay')
							}`}
						</Light>
					</VBox>
				)}

				<TimeSeriesChart
					data={historicalCryptoCurrency}
					timeRangeButtons={BUTTON_ARRAYS}
					onTimeRangeChange={handlePriceChartTimeRange}
				/>

				{accountBalance?.amount ? (
					<HBox
						style={{
							alignItems: 'center',
							padding: '16px 16px',
							borderBottom: `1px solid ${color.grey100}`,
						}}
					>
						<HBox style={{alignItems: 'center'}} horizontalGap={12}>
							{selectedCryptoCurrencyImage && <Logo currencyDetail={currencyDetail} width='32' />}

							<Light style={{fontSize: 18}}>
								{t('crypto.myTotalCrypto', {currency: accountBalance?.currency})}
							</Light>
						</HBox>

						<VBox style={{marginLeft: 'auto'}}>
							<Large
								data-testid={'usd-balance'}
								style={{
									margin: 0,
									textAlign: 'right',
									paddingBottom: '8px',
									lineHeight: '19px',
								}}
							>
								{indicativeBalance
									? formatUSD(indicativeBalance?.amount, indicativeBalance?.currency)
									: '-'}
							</Large>
							<Light
								data-testid={'crypto-balance'}
								style={{
									margin: 0,
									textAlign: 'right',
									fontSize: '12px',
									lineHeight: '15.6px',
								}}
							>
								{accountBalance
									? formatCrypto(
											accountBalance?.amount,
											accountBalance?.currency,
											selectedCryptoCurrencyPrecision,
									  )
									: 'N/A'}
							</Light>
						</VBox>
					</HBox>
				) : (
					''
				)}

				<HBox style={{justifyContent: 'center', marginTop: 30, marginBottom: 40}}>
					<VBox style={{alignItems: 'center', marginRight: 15}}>
						<Button
							id='overview-buy-button'
							style={{minWidth: 164}}
							onAction={() => {
								logger.info('Overview page - Buy button clicked', {navigateTo: RoutesName.crypto.buy})
								!isBuyDisabled && navigate(`/${RoutesName.crypto.buy}`, {ignore: true})
							}}
							disabled={isBuyDisabled}
							aria-label={`Buy ${accountBalance?.currency}`}
						>
							{t('buy')}
						</Button>
					</VBox>
					<VBox style={{alignItems: 'center'}}>
						<Button
							id='overview-sell-button'
							style={{minWidth: 164}}
							onAction={() => {
								logger.info('Overview page - Sell button clicked', {
									navigateTo: RoutesName.crypto.sell,
								})
								!isSellDisabled && navigate(`/${RoutesName.crypto.sell}`, {ignore: true})
							}}
							disabled={isSellDisabled}
							aria-label={`Sell ${accountBalance?.currency}`}
						>
							{t('sell')}
						</Button>
					</VBox>
				</HBox>
				<Separator />

				<TransactionActivity />
			</Main>
		</Page>
	)
}
