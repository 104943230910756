import {CellContextMenuEvent as AgCellContextMenuEvent} from 'ag-grid-community'
import {AgGridColumnProps} from 'ag-grid-react'
import {AgGridColumn} from 'ag-grid-react/lib/agGridColumn'
import React from 'react'
import {SearchCriteria} from './FilterModel'
export interface GridColumnProps extends AgGridColumnProps {
	tFilter?: 'number' | 'date' | 'set' | 'text' | 'datetime' | 'guid'
	filterCriterias?: SearchCriteria[]
	filterValues?: string[]
	defaultFilterValues?: string[]
	defaultFilterSearchCriteria?: SearchCriteria
	dateRangeValidatorFn?: (
		filterStartDate: Date | string | undefined,
		filterEndDate: Date | string | undefined,
	) => boolean
}
export type CellContextMenuEvent = AgCellContextMenuEvent

export const GridColumn: React.FC<GridColumnProps> = (props: GridColumnProps) => {
	return <AgGridColumn {...props} />
}
