import {useEffect} from 'react'
import {embeddedPartnerApi, ApiHook} from 'api'
import {showOnboardingErrorDialog} from 'components/ErrorDialog'
import {useStore} from 'store'

const {getParty, unmaskTaxId} = embeddedPartnerApi

export const usePartyDetails = () => {
	const {partner} = useStore()

	const {
		request: getPartyRequest,
		response: partyResponse,
		loading: partyLoading,
		error: partyError,
	} = ApiHook(() => getParty())

	const {
		request: unmaskTaxIdRequest,
		response: TinResponse,
		loading: TinLoading,
		error: unmaskError,
	} = ApiHook(() => unmaskTaxId())

	useEffect(() => {
		if (partyError) showOnboardingErrorDialog(partner)
	}, [partyError, unmaskError])

	return {
		getParty: getPartyRequest,
		unmaskTaxId: unmaskTaxIdRequest,
		partyResponse,
		TinResponse,
		TinLoading,
		partyLoading,
	}
}
