import {
	ApiEmbeddedPartnerTransactionSummary,
	ApiEmbeddedPartnerTransactionSummaryOperationTypeEnum as CryptoTransactionTypeEnum,
	ApiAmount,
	PageApiEmbeddedPartnerTransactionSummary,
} from '@bakkt/api'
import {Light, Large, H3, Icons, color, VBox, HBox, CircleIcon} from '@bakkt/components'
import {apiCall, embeddedPartnerApi} from 'api'
import {useNavigate} from 'hooks'
import {t} from 'i18next'
import {useEffect, useState} from 'react'
import {useStore} from 'store'
import {formatCrypto, formatUSD} from 'utils/currencyFormatters'
import {logger} from 'utils/logger/datadogInit'
import {Link} from '@bakkt/components'
import {LinearProgress, makeStyles} from '@material-ui/core'
import {handleKeyPress} from 'utils/handleKeyPress'
import {useCryptoCurrencyDetails} from 'hooks/useCryptoCurrencyDetails'

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiLinearProgress-barColorPrimary': {
			backgroundColor: 'gray',
		},
	},
}))
const size = 10
const TransactionActivity = () => {
	const classes = useStyles()
	const [transactionActivity, setTransactionActivity] = useState<ApiEmbeddedPartnerTransactionSummary[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const [currentPage, setCurrentPage] = useState<number>(0)
	const [transactionSummary, setTransactionSummary] = useState<PageApiEmbeddedPartnerTransactionSummary | undefined>(
		{},
	)
	const {setActivePartnerTransactionId} = useStore()
	const navigate = useNavigate()

	const fetchTransaction = async () => {
		setLoading(true)
		const {response} = await apiCall(() =>
			embeddedPartnerApi.getTransactionSummary(undefined, undefined, currentPage, size),
		)
		setLoading(false)
		setTransactionSummary(response)
		setCurrentPage(old => old + 1)
		setTransactionActivity(old => [...old, ...(response?.content || [])])
	}

	useEffect(() => {
		logger.info('Over view page, Transaction activity - initilized', {filename: 'TransactionActivity.tsx'})
		fetchTransaction()
	}, [])

	const handleActivityRowClick = (partnerTransactionId: string) => {
		logger.info('Over view page, Transaction activity - Select activity item', {id: partnerTransactionId})
		setActivePartnerTransactionId(partnerTransactionId)
		navigate(`/detail`, {ignore: true})
	}

	return (
		<div className='activityWrapper'>
			{(transactionActivity || []).length > 0 && (
				<>
					<H3>{t('activity.main')}</H3>
					<VBox>
						{(transactionActivity || []).map((element, index) => (
							<div
								className='transactionHistory'
								style={{cursor: 'pointer', paddingLeft: 3, paddingRight: 3}}
								onClick={() => handleActivityRowClick(element?.transactionId)}
								key={element?.transactionId}
								tabIndex={0}
								onKeyPress={event =>
									handleKeyPress(event, handleActivityRowClick(element?.transactionId))
								}>
								{element?.operationType === CryptoTransactionTypeEnum.BUY && (
									<ActivityRow
										icon={Icons.BuyCrypto}
										transactionLabel={`${t('purchased')}`}
										cryptoQuantity={element?.target}
										fiatAmount={element?.source}
										showBorder={index !== transactionActivity.length - 1}
									/>
								)}
								{element?.operationType === CryptoTransactionTypeEnum.SELL && (
									<ActivityRow
										icon={Icons.SellCrypto}
										transactionLabel={`${t('sold')}`}
										cryptoQuantity={element?.source}
										fiatAmount={element?.target}
										showBorder={index !== transactionActivity.length - 1}
									/>
								)}
							</div>
						))}
						{!loading && !transactionSummary?.last && (
							<Link data-testid='more-activity-link' onClick={fetchTransaction}>
								More
							</Link>
						)}
					</VBox>
				</>
			)}
			{loading && <LinearProgress data-testid='loading-more-activity' className={classes.root} />}
		</div>
	)
}

export type ActivityRowProps = {
	icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
	transactionLabel: string
	cryptoQuantity: ApiAmount
	fiatAmount: ApiAmount
	showBorder?: boolean
}

export const ActivityRow = ({icon, transactionLabel, cryptoQuantity, fiatAmount, showBorder}: ActivityRowProps) => {
	const currencyDetails = useCryptoCurrencyDetails(cryptoQuantity?.currency)
	return (
		<HBox
			style={{
				alignItems: 'center',
				padding: '16px 0px',
				borderBottom: showBorder ? '1px solid #e7e6e9' : undefined,
			}}
		>
			<HBox style={{alignItems: 'center'}}>
				{icon && (
					<CircleIcon
						data-testid={'activity-icon'}
						icon={icon}
						iconColor={color.black}
						style={{background: color.crypto, marginRight: 8, padding: 10}}
					/>
				)}
				<Large
					data-testid={'activity-description'}
					style={{fontSize: '14px', lineHeight: '18.2px', fontWeight: '400'}}
				>{`${transactionLabel} ${currencyDetails?.name}`}</Large>
			</HBox>

			<VBox style={{marginLeft: 'auto'}}>
				<Light
					data-testid={'activity-value-crypto'}
					style={{
						margin: 0,
						fontSize: '14px',
						fontWeight: '400',
						lineHeight: '18.2px',
					}}
				>
					{formatCrypto(
						cryptoQuantity?.amount || 0,
						cryptoQuantity?.currency,
						currencyDetails?.supportedPrecision,
					)}
				</Light>
				<Light
					data-testid={'activity-value-usd'}
					style={{
						margin: 0,
						textAlign: 'right',
						fontSize: '11px',
						fontWeight: '400',
						lineHeight: '13.13px',
					}}
				>
					Value: {formatUSD(fiatAmount?.amount, fiatAmount?.currency)}
				</Light>
			</VBox>
		</HBox>
	)
}

export default TransactionActivity
