import {Dispatch, SetStateAction} from 'react'
import {hideDialog, showDialog} from 'components/Dialog'
import {t} from 'i18next'
import {Icons, DialogText} from '@bakkt/components'
import {EmbeddedWebState} from 'store'
import {Link} from 'components/Link'
import {returnToPartner} from 'utils/returnToPartner'
import {setSessionStorageKey} from 'utils/sessionStorage'
import {SessionStorageKeys} from 'utils/sessionStorageProperties'

export const showSpeedbumpDialog = async (
	partner?: EmbeddedWebState['partner'],
	setNavigate?: Dispatch<SetStateAction<boolean>>,
) => {
	await showDialog({
		title: t('auth.speedbumpTitle', {partner: partner?.name}),
		WrappedComponent: () => (
			<DialogText data-testid='alert-dialog-description' style={{textAlign: 'center'}}>
				{t('auth.speedbumpMessage')}
				<Link
					data-testid={'speedbump-terms-button'}
					aria-label={t('auth.speedbumpTerms')}
					withTextDecoration
					onEvent={() => {
						hideDialog()
						setNavigate?.(true)
					}}
					style={{fontSize: 16}}
				>
					{t('auth.speedbumpTerms')}
				</Link>
				.
			</DialogText>
		),
		saveText: t('auth.speedbumpAcceptButton'),
		onSave: () => hideDialog(),
		onCancel: () => returnToPartner(partner),
		icon: Icons.DialogInfo,
		cancelText: t('auth.speedbumpNoThanksButton'),
		preventHideDialog: true,
	})
	setSessionStorageKey(SessionStorageKeys.ACCEPTED_SPEEDBUMP_MESSAGE, 'true')
}
