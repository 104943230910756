import React, {HTMLAttributes, ReactNode} from 'react'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import {color} from 'src/color/color'
import {Label} from 'src/components/label/Label'
import {Link, LinkProps} from 'src/components/link/Link'
import {Copy} from '../icons'
import {useState} from 'react'
import {useLayoutEffect} from 'react'
import {useRef} from 'react'
import {useEffect} from 'react'
import {VBox, Value} from 'src'
import withWidth from '@material-ui/core/withWidth'
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints'

interface Value {
	value?: string | string[]
	content?: ReactNode
	title?: string
}

export interface DetailViewProps extends HTMLAttributes<HTMLDivElement> {
	fields: {
		[key: string]: Value
	}
	isSplitPaneView?: boolean
	isBorderBottom?: boolean
	enableCopy?: boolean
	width?: Breakpoint
}

const useStyles = makeStyles({
	gridWrapper: {
		display: 'flex',
		padding: '5px 0 5px 0',
	},
	gridSpacing: {
		padding: '10px 10px 0 10px',
	},
	gridDisplay: {
		display: 'grid',
	},
	flexDisplay: {
		'&:hover > span:last-child': {
			display: 'contents',
		},
		display: 'flex',
	},
	cursorPointer: {
		cursor: 'pointer',
		display: 'none',
	},
	ellipsis: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
})

const DetailViewComponent: React.FC<DetailViewProps> = prop => {
	const classes = useStyles()
	const {isSplitPaneView, isBorderBottom, enableCopy} = prop

	const showBorderBottom = (index: number) => {
		const items = Object.keys(prop.fields).length
		if (isSplitPaneView) {
			return index < items - 1
		}
		const breakPoint = prop.width
		if (breakPoint) {
			const noOfCols = {
				xs: 1,
				sm: 2,
				md: 3,
				lg: 3,
				xl: 3,
			}
			let modulus = items % noOfCols[breakPoint]
			if (modulus === 0) {
				modulus = noOfCols[breakPoint]
			}
			return index < items - modulus
		}
		return true
	}

	return (
		<Grid
			container
			spacing={2}
			className={classes.gridSpacing}
			style={{borderBottom: isBorderBottom ? `5px solid ${color.grey100}` : 'none', margin: 0, width: 'auto'}}>
			{Object.keys(prop.fields).map((key, index) => {
				return (
					<Grid
						item
						key={key}
						xs={12}
						lg={isSplitPaneView ? 12 : 4}
						md={isSplitPaneView ? 12 : 4}
						sm={isSplitPaneView ? 12 : 6}
						style={{borderBottom: showBorderBottom(index) ? `1px solid ${color.grey100}` : 'none'}}>
						<Grid item xs={12} className={classes.gridWrapper}>
							<Grid item xs={4} title={key} className={classes.ellipsis} style={{paddingRight: 10}}>
								<Label>{key}</Label>
							</Grid>

							<Grid item xs={8} className={classes.flexDisplay}>
								<div
									className={classes.ellipsis}
									title={(prop.fields[key].title || prop.fields[key].value) as string}>
									{prop.fields[key].content || <Value>{prop.fields[key].value}</Value>}
								</div>
								{enableCopy && prop.fields[key].value && (
									<CopyView value={prop.fields[key].value as string} />
								)}
							</Grid>
						</Grid>
					</Grid>
				)
			})}
		</Grid>
	)
}

export const DetailView = withWidth()(DetailViewComponent)

export const CopyView = (props: {value: string}) => {
	const classes = useStyles()
	return (
		<span
			className={classes.cursorPointer}
			title='click to copy'
			onClick={() => navigator.clipboard.writeText(props.value)}>
			<Copy style={{marginLeft: 5}} />
		</span>
	)
}

export const LinkViewWithCopy = (props: {value: string} & LinkProps) => {
	const classes = useStyles()
	return (
		<div className={classes.flexDisplay}>
			<div className={classes.ellipsis} title={props.value}>
				<Link style={{background: color.grey40, marginRight: 5, marginTop: 0}} {...props} />
			</div>
			<CopyView value={props.value} />
		</div>
	)
}

export const ExpandableView = (props: {children: any; value?: string}) => {
	const [expanded, setExpanded] = useState(false)
	const classes = useStyles()
	if (expanded) {
		return <VBox>{props.children}</VBox>
	} else {
		if (props.value) {
			return (
				<div className={classes.flexDisplay}>
					<div title={props.value} style={{overflow: 'hidden', whiteSpace: 'nowrap'}}>
						<Value>{props.value}</Value>
					</div>
					<a onClick={() => setExpanded(true)} style={{marginLeft: 5, border: 'none', cursor: 'pointer'}}>
						...
					</a>
				</div>
			)
		} else {
			return <TabView handleExpand={() => setExpanded(true)}>{props.children}</TabView>
		}
	}
}

const TabView = (props: {children: any; handleExpand: () => void}) => {
	const [measuringRender, setMeasuringRender] = useState(false)
	const containerRef = useRef<HTMLDivElement>(null)
	const buttonRef = useRef<HTMLAnchorElement>(null)
	const [isMounted, setIsMounted] = useState(false)
	const [visibleTabIndices, setVisibleTabIndices] = useState([] as number[])
	useEffect(() => {
		setIsMounted(true)
		setMeasuringRender(true)
	}, [])
	useLayoutEffect(() => {
		if (measuringRender) {
			// Get the child tab elements
			const tabElements = Array.from(containerRef?.current?.children || [])
			let stopWidth = 0
			const visible: number[] = []
			tabElements.forEach((tab: any, index) => {
				// Don't count the width of the More button unless it will be visible
				if (visible.length === tabElements.length - 1) {
					stopWidth -= buttonRef?.current?.offsetWidth || 0
				}

				stopWidth += tab.offsetWidth
				if ((containerRef?.current?.offsetWidth || 0) >= stopWidth) {
					visible.push(index)
				}
			})
			setVisibleTabIndices(visible)
			setMeasuringRender(false)
		}
	}, [measuringRender])

	useEffect(() => {
		setMeasuringRender(true)
	}, [props.children])

	// Add props to each tab
	const allTabs = React.Children.map(props.children, (tab: any, index: number) => {
		return React.cloneElement(tab, {
			key: index,
		})
	})

	// Collect the visible and overflow tabs for rendering
	let visibleTabs = []
	if (!isMounted || measuringRender) {
		visibleTabs = allTabs
	} else {
		allTabs.forEach((tab: any, index: number) => {
			if (visibleTabIndices.includes(index)) {
				visibleTabs.push(tab)
			}
		})
	}
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'stretch',
				flex: 1,
				minWidth: '1px',
			}}
			ref={containerRef}>
			{visibleTabs}
			{allTabs.length != visibleTabs.length && (
				<a ref={buttonRef} onClick={() => props.handleExpand()} style={{border: 'none', cursor: 'pointer'}}>
					...
				</a>
			)}
		</div>
	)
}
