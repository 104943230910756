import {FC} from 'react'

type RoundLockIconProps = {
	height?: string
	width?: string
}

export const RoundLockIcon: FC<RoundLockIconProps> = ({height = '103', width = '103'}) => {
	return (
		<svg width={width} height={height} viewBox='0 0 103 103' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M48.0822 2C22.3893 3.76786 2 25.2768 2 51.4411C2 78.7839 24.2161 101 51.559 101C77.8411 101 99.4089 80.375 101 54.5054'
				stroke='#D93C30'
				stroke-width='3.09375'
			/>
			<path
				d='M101.001 48.6125C99.5863 23.3911 79.197 3.17857 53.9756 2'
				stroke='#585265'
				stroke-width='3.09375'
			/>
			<path
				d='M51.9418 64.4643C53.7318 64.4643 55.1828 63.0132 55.1828 61.2232C55.1828 59.4332 53.7318 57.9821 51.9418 57.9821C50.1518 57.9821 48.7007 59.4332 48.7007 61.2232C48.7007 63.0132 50.1518 64.4643 51.9418 64.4643Z'
				fill='#100722'
			/>
			<path
				d='M51.942 27.2656C43.0327 27.2656 35.8103 34.488 35.8103 43.3973V46.7121H29.3281V75.7344H74.5558V46.7121H68.0737V43.3973C68.0737 34.488 60.8513 27.2656 51.942 27.2656ZM64.9799 43.3973V46.7121H38.904V43.3973C38.904 36.1967 44.7413 30.3594 51.942 30.3594C59.1426 30.3594 64.9799 36.1967 64.9799 43.3973ZM32.4219 72.6406V49.8058H71.4621V72.6406H32.4219Z'
				fill='#100722'
			/>
		</svg>
	)
}
