import styled from '@emotion/styled'
import {Spinner} from 'src'

const ProgressContainerRelative = styled.div`
	display: flex;
	padding: 12px 0;
`

const ProgressContainer = styled.div`
	z-index: 200;
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`

const ProgressContainerFullScreen = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	opacity: 0.93;
	background-color: white;
`

const ProgressContentFiller = styled.div`
	flex: 1;
`

const ProgressContentImageWrapper = styled.div`
	margin: 0 auto;
	background: white;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const ProgressText = styled.p`
	margin-top: 42px;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 23px;
	text-align: center;
`

interface ProgressContentProps {
	title?: string
}

const ProgressContent = ({title}: ProgressContentProps) => (
	<>
		<ProgressContentFiller />
		<ProgressContentImageWrapper>
			<Spinner />
			{!!title && <ProgressText>{title}</ProgressText>}
		</ProgressContentImageWrapper>
		<ProgressContentFiller />
	</>
)

export interface ProgressProps {
	isRelativelyPositioned?: boolean
	isFullScreen?: boolean
	title: string
}

export const Progress = ({isRelativelyPositioned, isFullScreen, title}: ProgressProps) => {
	if (isFullScreen)
		return (
			<ProgressContainerFullScreen aria-hidden='false' aria-label='Loading'>
				<ProgressContent title={title} />
			</ProgressContainerFullScreen>
		)
	return isRelativelyPositioned ? (
		<ProgressContainerRelative aria-hidden='false' aria-label='Loading'>
			<ProgressContent title={title} />
		</ProgressContainerRelative>
	) : (
		<ProgressContainer aria-hidden='false' aria-label='Loading'>
			<ProgressContent title={title} />
		</ProgressContainer>
	)
}

export default Progress
