import {Input, makeStyles, Theme, useTheme} from '@material-ui/core'
import {max} from 'lodash'
import {CSSProperties, useEffect, useMemo, useState} from 'react'
import {color} from 'src'
import {BreakPointOptions, DEFAULT_BREAK_POINTS, responsiveStyler} from '../text/ResponsiveHeader'
import {H1} from '../text/Text'
import {getTextWidth} from '../../utils/Text'

type ResponsiveInputProps = {
	value?: string | number
	preLabel?: string
	postLabel?: string
	id?: string
	ariaLabel?: string
	style?: CSSProperties
	breakPoints?: BreakPointOptions[]
	onValueChange?: (value: string | number) => string
	inputType?: string
	defaultSize?: number
	inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined
}

const useStyles = makeStyles(theme => ({
	wrapper: {
		color: theme?.palette?.text?.background || '#353b50',
		position: 'relative',
		padding: '0 1ch',
		borderBottom: `${theme?.palette?.text?.background || color.blue900} solid 2px`,
		'&:after': {
			content: '""',
			position: 'absolute',
			display: 'block',
			left: 0,
			bottom: '-2px',
			width: '100%',
			height: '2px',
			backgroundColor: `${theme?.palette?.background?.paper || color.white}`,
			opacity: 1,
			transform: 'scale(0)',
			transformOrigin: 'center',
			transition: 'opacity 200ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
		},
	},
	input: {
		color: theme?.palette?.text?.background || '#353b50',
		borderBottom: 'none',
		'&.MuiInput-underline:hover:not(.Mui-disabled)&:before': {
			borderBottom: 'none',
		},
		'&:before': {
			borderBottom: 'none',
		},
		'&:after': {
			borderBottom: 'none',
		},
	},
	focused: {
		'&:after': {
			transform: 'scaleX(1) !important',
		},
	},
}))

export const ResponsiveInput = ({
	id = 'reactive-input',
	value = 0,
	preLabel = '',
	postLabel = '',
	style = {},
	breakPoints = DEFAULT_BREAK_POINTS,
	onValueChange,
	ariaLabel,
	inputType = 'text',
	defaultSize = 1,
	inputMode = 'numeric',
}: ResponsiveInputProps) => {
	const [currentValue, setValue] = useState(value)
	const [focused, setFocused] = useState<boolean>(false)
	const [fontsLoaded, setFontsLoaded] = useState<boolean>(false)
	const classes = useStyles()
	const theme = useTheme() as Theme

	useEffect(() => {
		// This is necessary for the input to size correctly to the given font onload
		document?.fonts?.ready?.then(() => setFontsLoaded(true))
	}, [])

	const getSize = (valueString: string) => {
		return max([valueString?.length, defaultSize]) || 1
	}

	const responsiveStyle = useMemo(
		() => responsiveStyler(currentValue?.toString(), breakPoints),
		[currentValue, breakPoints],
	)
	const combinedStyle = {...style, ...responsiveStyle}

	const responsiveStyleInput = useMemo(() => {
		return {width: getTextWidth({text: currentValue?.toString(), breakPoints, theme}) + 'px'}
	}, [currentValue, breakPoints, fontsLoaded])
	const combinedStyleInput = {...combinedStyle, ...responsiveStyleInput}

	const onChange = (event: any) => {
		let value = event.target.value || 0
		value = onValueChange ? onValueChange(value) : value
		setValue(value)
		event.target.style.width = getTextWidth({text: value.toString(), breakPoints, theme}) + 'px'
	}

	useEffect(() => {
		setValue(value)
	}, [value])

	return (
		<>
			<H1 id={id} style={combinedStyle}>
				<span className={`${classes.wrapper} ${focused ? classes.focused : ''}`} style={{paddingBottom: 3}}>
					{preLabel}
					<Input
						inputProps={{style: combinedStyleInput, 'aria-label': ariaLabel}}
						title={`${id}-input`}
						id={`${id}-input`}
						className={classes.input}
						onChange={onChange}
						style={combinedStyle}
						type={inputType}
						inputMode={inputMode}
						value={currentValue}
						onPaste={e => {
							e.preventDefault()
							return false
						}}
						onCopy={e => {
							e.preventDefault()
							return false
						}}
						onCut={e => {
							e.preventDefault()
							return false
						}}
						onFocus={e => {
							setFocused(true)
							const l = getSize(e.target.value)
							try {
								e.target.setSelectionRange(l, l)
							} catch (e) {
								console.error(e)
							}
							onChange(e)
						}}
						onBlur={e => {
							setFocused(false)
						}}
						autoFocus
					/>
					{postLabel}
				</span>
			</H1>
		</>
	)
}
