import React, {SVGProps} from 'react'
import MaterialDialog, {DialogProps} from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import {makeStyles} from '@material-ui/core/styles'
import {color} from 'src/color/color'
import {Button, ExtraButtonStyles} from 'src/components/button/Button'
import {Close} from 'src/components/icons/'
import {useTheme} from '@emotion/react'
import styled from '@emotion/styled'

export interface BakktDialogProps extends DialogProps {
	// Dialog content
	icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
	title?: string
	text?: string

	// Save button
	hideSave?: boolean
	disableSaveButton?: boolean
	onSave?: (arg: any) => void
	saveText?: string
	saveButtonStyle?: React.CSSProperties & ExtraButtonStyles

	// Skip button
	skip?: boolean
	onSkip?: (arg: any) => void
	skipText?: string
	skipButtonStyle?: React.CSSProperties & ExtraButtonStyles

	// Cancel button
	hideCancel?: boolean
	onCancel?: (arg: any) => void
	cancelText?: string
	cancelButtonStyle?: React.CSSProperties & ExtraButtonStyles

	useCloseBtn?: boolean

	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
	dialogStyle?: React.CSSProperties
	fullWidthButtons?: boolean
	buttonTextSize?: 'small' | 'medium' | 'large'
}

const useStyles = makeStyles(props => ({
	partialWidthButtons: {
		minWidth: 132,
		marginBottom: '1rem',
	},
	title: {
		fontFamily: props?.typography?.fontFamily || 'BrownPro',
		fontWeight: 700,
		fontStyle: 'normal',
		fontSize: '18px',
		color: props?.palette?.text?.background || color.blue900,
		lineHeight: '21.78px',
		paddingTop: '16px',
		paddingBottom: '8px',
		textAlign: 'center',
	},
	closeIcon: {
		position: 'fixed',
		top: '15px',
		right: '15px',
		cursor: 'pointer',
	},
}))

export const Dialog: React.FC<BakktDialogProps> = ({children, ...props}) => {
	const {
		title,
		text,
		skip,
		skipText = 'Skip',
		onSkip,
		useCloseBtn = false,
		icon,
		cancelText = 'Cancel',
		onCancel,
		saveText = 'Submit',
		onSave,
		hideSave,
		hideCancel,
		size = 'sm',
		dialogStyle,
		saveButtonStyle,
		disableSaveButton,
		cancelButtonStyle,
		skipButtonStyle,
		fullWidthButtons = false,
		buttonTextSize = 'medium',
		...rest
	} = props
	const classes = useStyles()
	const theme = useTheme()
	const showButtonContainer = !hideSave || !hideCancel || skip

	const handleKeyPress = (event: any, value: any) => {
		if (event.key === 'Enter') {
			value()
		}
	}
	const Icon: React.FC<SVGProps<SVGSVGElement>> = props => <>{icon && icon(props)}</>
	const StyledIcon = styled(Icon)`
		path:nth-of-type(n + 3) {
			fill: ${props => props?.theme?.palette?.text?.background || color.blue900};
		}
	`

	return (
		<MaterialDialog
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-dechildrenscription'
			PaperProps={{
				style: {
					borderRadius: 12,
					backgroundColor: theme?.palette?.background?.paper || color.white,
					display: 'flex',
					...dialogStyle,
				},
			}}
			BackdropProps={{style: {background: color.overlay, opacity: 0.85}}}
			fullWidth={true}
			maxWidth={size}
			{...rest}>
			<StyledIcon />
			{useCloseBtn && (
				<div
					className={classes.closeIcon}
					onClick={onCancel}
					onKeyPress={event => handleKeyPress(event, onCancel)}
					tabIndex={0}
					aria-label='Close'
					data-testid='close-modal-icon'
					role='button'
					data-dma='Close'>
					<Close />
				</div>
			)}
			{title && (
				<DialogTitle id='alert-dialog-title' disableTypography={true} classes={{root: classes.title}}>
					{title}
				</DialogTitle>
			)}
			<DialogContent
				style={{
					overflowY: 'visible',
					paddingRight: 35,
					borderBottom: skip ? `1px solid ${color.grey100}` : '',
				}}>
				{text && (
					<DialogContentText
						style={{color: theme?.palette?.text?.background || color.grey800}}
						id='alert-dialog-description'>
						{text}
					</DialogContentText>
				)}
				{children}
				{showButtonContainer && (
					<div
						style={{
							display: 'flex',
							flexDirection: fullWidthButtons ? 'column' : 'row-reverse',
							margin: '2rem 0 0 0',
						}}>
						{!hideSave && (
							<Button
								data-testid={'dialog-button-primary'}
								intent='dialog'
								fullWidth={fullWidthButtons}
								size={buttonTextSize}
								disabled={disableSaveButton}
								onClick={onSave}
								data-dma='Close'
								className={!fullWidthButtons ? classes.partialWidthButtons : ''}
								style={{marginBottom: '4px', ...saveButtonStyle}}>
								{saveText}
							</Button>
						)}
						{!hideCancel && (
							<Button
								data-testid={'dialog-button-secondary'}
								intent='dialog'
								size={buttonTextSize}
								fullWidth={fullWidthButtons}
								outline={true}
								onClick={onCancel}
								data-dma='Close'
								className={!fullWidthButtons ? classes.partialWidthButtons : ''}
								style={{
									marginRight: fullWidthButtons ? 0 : 10,
									color: theme?.palette?.text?.newLink || color.blue900,
									...cancelButtonStyle,
								}}>
								{cancelText}
							</Button>
						)}
					</div>
				)}
			</DialogContent>
			{skip && (
				<Button
					intent='dialog'
					size={buttonTextSize}
					fullWidth={fullWidthButtons}
					outline={true}
					onClick={onSkip}
					className={!fullWidthButtons ? classes.partialWidthButtons : ''}
					style={{
						border: 'none',
						...skipButtonStyle,
					}}>
					{skipText}
				</Button>
			)}
		</MaterialDialog>
	)
}
