import React, {useState} from 'react'
import {Tooltip} from '@material-ui/core'
import moment from 'moment'
import {Badge} from '../badge/Badge'
import {HBox, Icons} from 'src'
import {color} from 'src/color/color'
import Application from 'src/utils/Application'
import {formatDateTimeWithTimeZone} from 'src/utils/DateUtils'
import Utils from 'src/utils/Utils'
import {Filter, SearchCriteria, FilterField} from '../dataGrid/FilterModel'
import {GridColumnProps} from '../dataGrid/GridColumn'
import FilterConfiguration from '../dataGrid/FilterConfiguration'

export type FilterProps = {
	viewId: any
	tFilterColumns?: any
	updateFilter?: any
} & React.HTMLProps<any>

export const GenericFilter: React.FC<FilterProps> = (props: FilterProps) => {
	const {viewId, tFilterColumns, updateFilter} = props
	const initDefaultFilters = () => {
		const existingFilters = viewId && localStorage.getItem(viewId + '.tfilters')
		if (existingFilters !== null) {
			return existingFilters
		}
		return generateDefaultFilters()
	}
	const generateDefaultFilters = (): string => {
		const defaultFilters = JSON.stringify(
			tFilterColumns &&
				tFilterColumns.map((prop: {field: any; defaultFilterValues: any; defaultFilterSearchCriteria: any}) => {
					return {
						fieldName: prop.field,
						fieldValues: prop.defaultFilterValues,
						filterType: 'datetime',
						id: Math.random(),
						isDefaultFilter: true,
						isUndeletable: true,
						searchCriteria: prop.defaultFilterSearchCriteria,
					}
				}),
		)
		localStorage.setItem(props.viewId + '.tfilters', defaultFilters)
		return defaultFilters
	}

	const initTFilters: string = initDefaultFilters()
	const [filters, setFilters] = useState<Filter[]>(initTFilters ? JSON.parse(initTFilters) : [])

	const handleFilterConfiguration = async (preSelectedFilter?: Filter) => {
		let filterColumns: GridColumnProps[] = tFilterColumns
		if (preSelectedFilter) {
			filterColumns = tFilterColumns.filter((f: {field: string}) => f.field === preSelectedFilter.fieldName)
		}
		const newFilter = await configureFilters(
			filterColumns &&
				filterColumns.map(c => {
					const {field, headerName, tFilter, filterCriterias, filterValues, dateRangeValidatorFn} = c
					return {
						dataField: field || '',
						headerText: headerName || field || '',
						filterType: tFilter || 'text',
						filterCriterias,
						filterValues,
						dateRangeValidatorFn,
					}
				}),
			preSelectedFilter,
		)
		if (newFilter && newFilter.fieldName && newFilter.fieldValues && newFilter.searchCriteria) {
			const newFilters = [...filters]
			if (preSelectedFilter) {
				newFilter.isUndeletable = preSelectedFilter.isUndeletable
				const originalFilterIndex = filters.findIndex(f => f.id === preSelectedFilter.id)
				newFilters.splice(originalFilterIndex, 1, newFilter)
			} else {
				newFilters.push(newFilter)
			}
			updateFilterState(newFilters)
		}
		Utils.hideDialog()
	}

	const renderLabel = (filter: Filter) => {
		const stringLabel = renderFieldValues(filter)
		return (
			<HBox>
				<div style={{marginTop: 'auto', marginBottom: 'auto'}}>{stringLabel}</div>
				<Icons.Edit style={{transform: 'scale(0.55)', marginTop: 1}} fill={color.white} />
			</HBox>
		)
	}

	const renderFieldValues = (filter: Filter): string => {
		const fieldValues = filter.fieldValues
		if (filter.searchCriteria === SearchCriteria.RELATIVETIME) {
			return fieldValues[0]
		}
		if (
			fieldValues.length === 2 &&
			filter.searchCriteria === SearchCriteria.BETWEEN &&
			filter.filterType === 'datetime'
		) {
			return (
				formatDateTimeWithTimeZone(fieldValues[0], Application.timezone) +
				' - ' +
				formatDateTimeWithTimeZone(fieldValues[1], Application.timezone)
			)
		} else if (fieldValues.length === 2 && filter.searchCriteria === SearchCriteria.BETWEEN) {
			return moment(fieldValues[0]).format('MM-DD-YYYY') + ' - ' + moment(fieldValues[1]).format('MM-DD-YYYY')
		} else if (filter.filterType === 'set' && filter.searchCriteria === SearchCriteria.IN) {
			return fieldValues.join(' | ')
		} else if (
			filter.searchCriteria === SearchCriteria.AFTER ||
			filter.searchCriteria === SearchCriteria.BEFORE ||
			filter.searchCriteria === SearchCriteria.BEFOREORNULL ||
			filter.searchCriteria === SearchCriteria.AFTERORNULL
		) {
			return fieldValues[0]
		} else if (fieldValues.length === 2) {
			return fieldValues[0] + ' - ' + fieldValues[1]
		} else if (fieldValues.length === 1 && filter.filterType === 'datetime') {
			return formatDateTimeWithTimeZone(fieldValues[0], Application.timezone)
		} else if (fieldValues.length === 1) {
			return fieldValues[0]
		}
		return ''
	}

	const renderTooltipTitle = (filter: Filter): string => {
		const column = tFilterColumns.find((col: {fieldName: string}) => col.fieldName === filter.fieldName)
		if (column) {
			const columnDisplayName = column.fieldName
			return columnDisplayName + ' - ' + filter.searchCriteria
		}
		return ''
	}

	const updateFilterState = (filterData: Filter[]) => {
		setFilters(filterData)
		updateFilter(filterData)
		viewId && localStorage.setItem(viewId + '.tfilters', JSON.stringify(filterData))
	}

	return (
		filters && (
			<HBox horizontalGap={10} style={{marginTop: 'auto', marginBottom: 'auto'}}>
				{filters.length === 0 && (
					<Badge
						variant={'outlined'}
						label={'Filters'}
						style={{border: `1px solid ${color.success}`}}
						textColor={color.blue900}
						onClick={() => handleFilterConfiguration()}
					/>
				)}
				{filters.length !== 0 && (
					<Badge
						label={'Filters'}
						backgroundColor={color.green900}
						textColor={color.green400}
						onClick={() => handleFilterConfiguration()}
					/>
				)}
				<HBox horizontalGap={5} style={{display: 'flex', flexWrap: 'wrap'}}>
					{filters.map((filter, index) => (
						<Tooltip key={index + '-tooltip'} title={renderTooltipTitle(filter)} placement='top'>
							<div style={{paddingBottom: '5px'}}>
								<Badge
									key={index}
									label={renderLabel(filter)}
									backgroundColor={color.blue900}
									textColor={color.white}
									onClick={() => {
										handleFilterConfiguration(filter)
									}}
									onDelete={
										filter.isUndeletable
											? undefined
											: () => {
													const filtersCopy = [...filters]
													filtersCopy.splice(index, 1)
													updateFilterState(filtersCopy)
											  }
									}
									deleteIcon={
										filter.isUndeletable ? undefined : (
											<Icons.X
												style={{transform: 'scale(0.65)', marginTop: 1}}
												fill={color.white}
											/>
										)
									}
								/>
							</div>
						</Tooltip>
					))}

					{filters.length !== 0 && (
						<Badge
							variant={'outlined'}
							label={'Clear All'}
							textColor={color.blue900}
							style={{border: `1px solid ${color.success}`}}
							onClick={() => {
								updateFilterState(JSON.parse(generateDefaultFilters()))
							}}
						/>
					)}
				</HBox>
			</HBox>
		)
	)
}

export const configureFilters = (columns: FilterField[], preSelectedFilter?: Filter): Promise<Filter> => {
	return Utils.showFuncDialog('Filter Configuration', (props: any) => (
		<FilterConfiguration columns={columns} preSelectedFilter={preSelectedFilter} {...props} />
	))
}
