import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import CryptoAccountsItem from './CryptoAccountItem'
import {useNavigate} from 'react-router-dom'
import {RoutesName} from 'constants/routes'
import {useStore} from 'store'
import {H3, H2, VBox, Large, BottomBox, Main, PoweredBy, H1} from '@bakkt/components'
import {TestDialog} from 'components/TestDialog'
import Page from 'components/Page'
import {useCryptoAccount, useSpeedbumpDialog} from 'hooks'
import {showSuspendedErrorDialog} from 'components/ErrorDialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {handleKeyPress} from 'utils/handleKeyPress'
import {logger} from 'utils/logger/datadogInit'
import {formatUSD} from '../../../../../utils/currencyFormatters'

export const SelectCryptoAccount = () => {
	const {t} = useTranslation()
	const navigate = useNavigate()
	const {
		setSelectedCryptoAccount,
		partner,
		setSelectedCryptoAccountCurrency,
		cryptoAccounts: accounts,
		isSuspended,
	} = useStore()
	const {loading} = useCryptoAccount(true)
	const onMobileScreen = useMediaQuery('(max-width:719px)')
	useSpeedbumpDialog()

	function handleSelectCryptoAccount(index: number) {
		if (accounts) {
			logger.info('Crypto dashboard - select crypto account', {
				currencyType: accounts[index]?.accountBalance?.currency,
				currency: accounts[index]?.indicativeBalance?.currency,
				index,
			})
			setSelectedCryptoAccount(accounts[index])
			setSelectedCryptoAccountCurrency(accounts[index]?.accountBalance?.currency as string)
			navigate(RoutesName.crypto.overview)
		}
	}

	function getTotalIndicativeBalance(): number {
		let total = 0
		for (let i = 0; i < accounts.length; i++) {
			total = total + (accounts[i]?.indicativeBalance?.amount || 0)
		}
		return total
	}

	useEffect(() => {
		logger.info('Crypto dashboard - initilized', {filename: 'selectCryptoAccounts.tsx'})
	}, [])

	useEffect(() => {
		logger.info('Crypto dashboard - suspend', {suspend: isSuspended})
		if (isSuspended) {
			showSuspendedErrorDialog(partner)
		}
	}, [partner, isSuspended])

	return (
		<Page
			withBackIcon
			onIconPress={() => {
				logger.info('Crypto dashboard - back button clicked', {navTo: -1})
				window.history.go(-1)
			}}
			titleHeader={onMobileScreen ? '' : t('crypto.cryptoAccounts')}
			loading={loading}
		>
			<TestDialog />
			<VBox style={{paddingTop: '0'}}>
				<Main>
					{getTotalIndicativeBalance() !== 0 && (
						<VBox>
							<span data-testid={'crypto-balance-section'}>
								<H1
									data-testid={'crypto-balance-total'}
									style={{textAlign: 'center', marginBottom: 2, fontSize: 48, marginTop: 20}}
								>
									{formatUSD(getTotalIndicativeBalance(), accounts[0]?.indicativeBalance?.currency)}
								</H1>
								<H2
									data-testid={'crypto-balance-header'}
									style={{
										textAlign: 'center',
										margin: '10px 0 30px 0',
										fontSize: 16,
										lineHeight: '130.02%',
									}}
								>
									{t('crypto.cryptoBalance')}
								</H2>
							</span>
						</VBox>
					)}

					<H3 data-testid={'section-title'} style={{fontWeight: 700, marginBottom: 0}}>
						{t('action.myCrypto')}
					</H3>
					{accounts && accounts.length > 0 ? (
						<>
							{(accounts || [])
								.filter(item => !(!item?.accountBalance?.amount && item.isDisabled))
								.map((item, index) => (
									<CryptoAccountsItem
										key={index}
										disabled={item.isDisabled}
										onClick={() => handleSelectCryptoAccount(index)}
										accountBalance={item?.accountBalance}
										indicativeBalance={item?.indicativeBalance}
										tabIndex={0}
										onKeyPress={event => handleKeyPress(event, handleSelectCryptoAccount(index))}
									/>
								))}
						</>
					) : (
						<Large> No Crypto Accounts Found</Large>
					)}
				</Main>
			</VBox>
			{onMobileScreen && (
				<BottomBox style={{marginTop: 'auto'}}>
					<PoweredBy />
				</BottomBox>
			)}
		</Page>
	)
}
