import {useNavigate} from 'react-router-dom'
import {ApiAmountCurrencyEnum, ApiEmbeddedFundingAccount} from '@bakkt/api'
import {formatUSD} from 'utils/currencyFormatters'
import {VBox, HBox, Large, Light, Separator, Icons, color, toTitleCase, useTheme, CircleIcon} from '@bakkt/components'
import {handleKeyPress} from 'utils/handleKeyPress'
import {logger} from 'utils/logger/datadogInit'

import {useStore} from 'store'
import {Theme} from '@material-ui/core'

export type FundingSourceProps = {
	source: ApiEmbeddedFundingAccount
}

export const FundingAccount = ({source}: FundingSourceProps) => {
	const {setSelectedFundingAccount, setSelectedFundingAccountId, selectedFundingAccount} = useStore()
	const navigate = useNavigate()
	const theme = useTheme() as Theme

	const isSelected = source?.externalAccountRef === selectedFundingAccount?.externalAccountRef
	const fiatValue = formatUSD(source?.availableBalance?.amount, ApiAmountCurrencyEnum.USD)
	const accountNumber = source?.formattedAccountNumber

	const onSourceSelected = () => {
		logger.info('Crypto transaction input page - Funding account clicked', {selected: isSelected})
		setSelectedFundingAccount(source)
		setSelectedFundingAccountId(source?.externalAccountRef)
	}

	return (
		<div
			id={`funding-source-${source.externalAccountRef}`}
			style={{cursor: 'pointer', padding: '0 3px 1px 3px', margin: '2px'}}
			className='accountRow'
			onClick={onSourceSelected}
			tabIndex={0}
			onKeyPress={event => handleKeyPress(event, onSourceSelected)}
		>
			<VBox style={{marginRight: 15}}>
				<HBox style={{alignItems: 'center', padding: '15px 0px 15px'}}>
					<CircleIcon
						icon={Icons.BakktCard}
						iconColor={theme?.palette?.secondary?.main}
						style={{
							background: theme?.palette?.secondary?.contrastText,
							border: `1px ${theme?.palette?.secondary?.main} solid`,
							margin: 'auto 15px auto 0px',
							padding: 12,
						}}
					/>
					<Large
						data-testid={'funding-source-account-type'}
						id={`funding-source-account-type-${source.externalAccountRef}`}
						style={{color: theme?.palette?.text?.background || color.blue900}}
					>
						{toTitleCase(source.accountType)}
					</Large>
					<VBox
						data-testid={'funding-source-account-info'}
						id={`funding-source-account-info-${source.externalAccountRef}`}
						style={{
							color: theme?.palette?.text?.background || color.grey700,
							marginLeft: 'auto',
							textAlign: 'right',
						}}
					>
						<Light data-testid='funding-source-account-balance' style={{margin: 0}}>
							{fiatValue}
						</Light>
						<Light data-testid='funding-source-account-number' style={{margin: 0}}>
							{accountNumber}
						</Light>
					</VBox>
					<CircleIcon
						className={`check ${isSelected ? 'selected' : 'unselected'}`}
						id={`selected-funding-source-check-${source.externalAccountRef}`}
						icon={Icons.Check}
						iconColor={isSelected ? theme?.palette?.secondary?.contrastText || 'white' : 'transparent'}
						style={{
							background: isSelected
								? theme?.palette?.secondary?.main || color.blueKolibri
								: 'transparent',
							border: isSelected
								? `1px ${theme?.palette?.background?.paper} solid`
								: `1px ${theme?.palette?.text?.background} solid`,
							margin: 'auto 0px auto 25px',
						}}
					/>
				</HBox>
				<Separator />
			</VBox>
		</div>
	)
}
