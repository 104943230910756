import {useEffect, useState} from 'react'
import {ApiAmount, ApiEmbeddedPartnerTransactionOperationTypeEnum as TransactionType} from '@bakkt/api'

import {useDecimalNumberInputting, useNavigate} from 'hooks'
import {useSelectedCryptoAccountInfo} from 'hooks/useSelectedCryptoAccountInfo'
import {useStore} from 'store'
import {embeddedPartnerApi, apiCall} from 'api'
import {showTransactionCryptoErrorDialog} from 'components/ErrorDialog'
import {RoutesName} from 'constants/routes'

const {getExecutePrice} = embeddedPartnerApi

export enum InputType {
	Fiat = 'FIAT',
	Crypto = 'CRYPTO',
}

type Props = {
	transactionType: TransactionType
	inputType: InputType
	forceTrigger?: boolean
}

export const useCryptoCurrencyExecutePrices = ({transactionType, inputType, forceTrigger}: Props) => {
	const [approximate, setApproximate] = useState<boolean>()
	const [hasExecutePriceError, setHasExecutePriceError] = useState<boolean>()
	const [navigateToOverview, setNavigateToOverview] = useState<boolean>()
	const {selectedCryptoAccount, fiatCurrencyPreference, cryptoTransactionConfirm, setCryptoTransactionConfirm} =
		useStore()
	const {selectedCryptoCurrencyPrecision} = useSelectedCryptoAccountInfo()
	const navigate = useNavigate()

	const initialUnitPrice = selectedCryptoAccount?.accountBalance?.amount
		? (selectedCryptoAccount?.indicativeBalance?.amount || 0) / selectedCryptoAccount?.accountBalance?.amount
		: 0

	const initialTransactionDetails = cryptoTransactionConfirm || {
		price: {amount: 0, currency: fiatCurrencyPreference},
		totalPrice: {amount: 0, currency: fiatCurrencyPreference},
		quantity: {amount: 0, currency: selectedCryptoAccount?.accountBalance?.currency},
		unitPrice: {amount: initialUnitPrice, currency: fiatCurrencyPreference},
		transactionFee: {amount: 0, currency: fiatCurrencyPreference},
	}

	const {
		amount: fiatAmount,
		amountString: fiatAmountString,
		setAmount: setFiatAmount,
	} = useDecimalNumberInputting(initialTransactionDetails.totalPrice.amount)
	const fiatAmountObject: ApiAmount = {amount: fiatAmount, currency: fiatCurrencyPreference as any}

	const {
		amount: cryptoAmount,
		amountString: cryptoAmountString,
		setAmount: setCryptoAmount,
	} = useDecimalNumberInputting(initialTransactionDetails.quantity.amount, selectedCryptoCurrencyPrecision)

	const cryptoAmountObject: ApiAmount = {
		amount: cryptoAmount,
		currency: selectedCryptoAccount?.accountBalance?.currency,
	}

	const focusedInputAmount = inputType === InputType.Fiat ? fiatAmountString : cryptoAmountString

	const fetchPrice = async () => {
		const sourceAmount = inputType === InputType.Fiat ? fiatAmountObject : cryptoAmountObject
		if (!sourceAmount?.amount) return

		const {response, error} = await apiCall(() =>
			getExecutePrice({
				buySellIndicator: transactionType as any,
				source: sourceAmount,
				destinationCurrency:
					inputType === InputType.Fiat
						? selectedCryptoAccount?.accountBalance?.currency
						: (fiatCurrencyPreference as any),
			}),
		)

		if (error) {
			setHasExecutePriceError(true)
			await showTransactionCryptoErrorDialog(selectedCryptoAccount?.accountBalance?.currency)
			setNavigateToOverview(true)
		}

		if (response) {
			setCryptoTransactionConfirm({
				price: response?.price,
				quantity: response?.quantity,
				totalPrice: response?.total,
				transactType: transactionType,
				transactionFee: response?.fees,
				unitPrice: response?.unitPrice,
			})

			inputType === InputType.Fiat
				? setCryptoAmount(response?.quantity?.amount?.toString() || '0')
				: setFiatAmount(response?.total?.amount?.toString() || '0')

			setApproximate(false)
		}
	}

	// This navigation logic needs to be in a useEffect because it seems to not work consistently in setTimeout/setInterval
	useEffect(() => {
		if (navigateToOverview) navigate(`/${RoutesName.crypto.overview}`, {replace: true})
	}, [navigateToOverview])

	useEffect(() => {
		setApproximate(true)

		const estimatedCryptoAmount = cryptoTransactionConfirm?.unitPrice?.amount
			? fiatAmount / cryptoTransactionConfirm?.unitPrice.amount
			: 0
		const estimatedFiatAmount = cryptoAmount * (cryptoTransactionConfirm?.unitPrice?.amount || 0)

		inputType === InputType.Fiat
			? setCryptoAmount(estimatedCryptoAmount.toString())
			: setFiatAmount(estimatedFiatAmount.toString())

		if (!hasExecutePriceError) {
			const timeout = setTimeout(fetchPrice, 2000)
			const interval = setInterval(fetchPrice, 30000)
			return () => {
				clearTimeout(timeout)
				clearInterval(interval)
			}
		}
	}, [focusedInputAmount, hasExecutePriceError, forceTrigger])

	return {
		fiatAmount,
		setFiatAmount,
		cryptoAmount,
		setCryptoAmount,
		approximate,
		setApproximate,
	}
}
