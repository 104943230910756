import styled from '@emotion/styled'
import React, {HTMLAttributes} from 'react'
import {color} from 'src/color/color'

export const BrownPro =
	"'BrownPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen-Sans', 'Ubuntu', 'Cantarell', 'Helvetica Neue', sans-serif"

export const Intro = styled.div`
	margin-bottom: 20px;
`

export const Title = styled.div`
	font-size: 16px;
	color: #100722;
	margin-bottom: 8px;
`

export const InputLabel = styled.span`
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 130.02%;
	letter-spacing: 0.0025em;
	color: ${color.grey700};
	mix-blend-mode: normal;
`

export const H1 = styled.h1`
	font-family: ${props => props?.theme?.typography?.fontFamily || BrownPro};
	color: ${props => props?.theme?.palette?.text?.background || '#353b50'};
	font-size: 28px;
	font-weight: 600;
	line-height: 33px;
	margin: 0.7em 0;
`

export const H2 = styled.h2`
	font-family: ${props => props?.theme?.typography?.fontFamily || BrownPro};
	color: ${props => props?.theme?.palette?.text?.background || 'inherit'};
	font-size: 24px;
	font-weight: 700;
	margin-top: 0.7em;
	line-height: 29px;
	margin: 0.7em 0;
`

interface H3Props extends HTMLAttributes<HTMLHeadingElement> {
	underline?: boolean
}
export const H3: React.FC<H3Props> = ({children, underline, ...props}) => {
	const H3Text = styled.h3`
		font-family: ${props => props?.theme?.typography?.fontFamily || BrownPro};
		color: ${props => props?.theme?.palette?.text?.background || '#353b50'};
		font-size: 20px;
		margin-top: initial;
		margin-bottom: initial;
		line-height: 21px;
		letter-spacing: -0.2px;
		font-weight: 600;
		margin: 0.7em 0;
		padding-bottom: ${underline ? '12px' : '0'};
		border-bottom: ${underline ? `5px solid ${color.grey100}` : 'none'};
	`

	return <H3Text {...props}>{children}</H3Text>
}

export const H4 = styled.h4`
	font-family: ${props => props?.theme?.typography?.fontFamily || BrownPro};
	color: ${props => props?.theme?.palette?.text?.background || 'inherit'};
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	margin: 0.7em 0;
	letter-spacing: 0.09px;
`

export const H6 = styled.h6`
	font-family: ${props => props?.theme?.typography?.fontFamily || BrownPro};
	color: ${props => props?.theme?.palette?.text?.background || 'inherit'};
	font-weight: 400;
	font-size: 16px;
	line-height: 1.3;
	letter-spacing: -0.24px;
	margin: 0.5em 0;
`

export const Large = styled.p`
	font-family: ${props => props?.theme?.typography?.fontFamily || BrownPro};
	color: ${props => props?.theme?.palette?.text?.background || color.grey700};
	font-size: 16px;
	line-height: 1.45;
	letter-spacing: -0.02em;
`

export const Medium = styled.p`
	font-family: ${props => props?.theme?.typography?.fontFamily || BrownPro};
	color: ${props => props?.theme?.palette?.text?.background || 'inherit'};
	font-size: inherit;
	line-height: 1.45;
	letter-spacing: -0.24px;
	weight: 400;
`

export const Light = styled.p`
	font-family: ${props => props?.theme?.typography?.fontFamily || BrownPro};
	color: ${props => props?.theme?.palette?.text?.background || color.grey500};
	font-size: 14px;
	line-height: 1.45;
	letter-spacing: -0.24px;
`
export const DialogText = styled.p`
	font-size: 16px;
	text-align: center;
	line-height: 20.8px;
	letter-spacing: -0.24px;
	color: ${props => props?.theme?.palette?.text?.background || color.grey700};
	margin-top: 0;
	margin-bottom: 16px;
`

export const InlineBtn = styled.button`
	display: inline;
	border: none;
	background: none;
	padding: 0;
	margin: 0;
	-webkit-appearance: none;
	color: #0567de;
	:hover {
		cursor: pointer;
	}
`

export const CheckmarkList = styled.ul`
	padding-left: 0;
	li {
		padding-left: 36px;
		list-style: none;
		position: relative;
		margin-bottom: 1em;
		&::before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			height: 24px;
			width: 24px;
			background: url(/checkmark.svg) no-repeat center center / contain;
		}
	}
`

export const AlertDialogText = styled.p`
	font-size: 16px;
	text-align: center;
	line-height: 20.8px;
	letter-spacing: -0.24px;
	color: ${props => props?.theme?.palette?.text?.background || color.grey700};
	margin-top: 0;
	margin-bottom: 16px;
`

export const LI = styled.li`
	color: ${props => props?.theme?.palette?.text?.background};
`
