/* eslint-disable no-use-before-define */
import React from 'react'
import Autocomplete, {AutocompleteRenderOptionState, AutocompleteGetTagProps} from '@material-ui/lab/Autocomplete'
import {createStyles, makeStyles, createTheme, ThemeProvider} from '@material-ui/core/styles'
import TextField, {StandardTextFieldProps} from '@material-ui/core/TextField'
import {color} from 'src/color/color'
import {Icons} from '../..'
import {Badge} from '../badge/Badge'
import {Popper} from '@material-ui/core'
import {ShadowContainer} from '../shadowContainer/ShadowContainer'
import styled from '@emotion/styled'

const NO_OPTION_TEXT = 'No Options'

export interface SelectProps {
	// eslint-disable-next-line
	label?: string
	labelFunction?: (item: any) => string
	enableUnderline?: boolean
	// eslint-disable-next-line
	options: any[]
	// eslint-disable-next-line
	optionFunction?: (item: any) => string
	// eslint-disable-next-line
	onChange?: (event: any, value: any) => void
	onInputChange?: (event: any, value: any) => void
	multiple?: boolean
	textFieldProps?: StandardTextFieldProps
	popperStyle?: React.CSSProperties
	disableClearable?: boolean
	placeholder?: string
	disableSearchable?: boolean
	// eslint-disable-next-line
	defaultValue?: any
	shadowContainerStyle?: React.CSSProperties
	disabled?: boolean
	inputContainer?: React.CSSProperties
	// eslint-disable-next-line
	optionRenderer?: (item: any, state: AutocompleteRenderOptionState) => React.ReactNode
	// eslint-disable-next-line
	labelRenderer?: (option: any, index: number, getTagProps: AutocompleteGetTagProps) => React.ReactNode
	limitTags?: number
	disableShadow?: boolean
	// eslint-disable-next-line
	value?: any
	inputValue?: any
	containerStyle?: React.CSSProperties
	getOptionDisabledFunction?: (item: any) => boolean
	optionSelector?: (item: any) => string
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '16px',
			height: 48,
			'& label.Mui-focused': {
				color: color.grey700,
			},
			'& .MuiAutocomplete-inputRoot': {
				overflowY: 'auto',
				overflowX: 'hidden',
				maxHeight: 42,
			},
			'& .MuiFormLabel-root': {
				fontStyle: 'normal',
				fontWeight: 400,
				fontSize: '16px',
				letterSpacing: '0.25px',
				marginTop: -8,
			},
			'& .MuiInputLabel-shrink': {
				marginTop: 4,
			},
			'& label + div > .MuiAutocomplete-endAdornment': {
				top: 'calc(50% - 20px)',
			},
		},
		input: {
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '16px',
			lineHeight: '16px',
			letterSpacing: '0.25px',
			color: color.blue900,
		},
		option: {
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '16px',
			lineHeight: '16px',
			letterSpacing: '0.25px',
			color: color.blue900,
			padding: '8px',
			display: 'flex',
			justifyContent: 'space-between',
		},
		popper: {
			zIndex: 9999,
			minWidth: 200,
			marginLeft: -16,
		},
	}),
)

const theme = createTheme({
	typography: {
		fontFamily: 'BrownPro',
	},
	props: {
		MuiButtonBase: {
			disableRipple: true,
		},
	},
	overrides: {
		MuiInput: {
			root: {
				overflowY: 'auto',
				overflowX: 'hidden',
			},
		},
		MuiIconButton: {
			root: {
				'&:hover': {
					backgroundColor: 'transparent',
				},
			},
		},
	},
})

const TextStyleWrapper = styled.div`
	fontFamily: 'BrownPro',
	fontStyle: 'normal',
	fontWeight: 'normal',
	fontSize: '16px',
	color: ${color.blue900},
`

export const Select: React.FC<SelectProps> = (props: SelectProps) => {
	const {
		enableUnderline = false,
		labelFunction,
		options = [],
		optionFunction,
		getOptionDisabledFunction,
		textFieldProps,
		placeholder,
		disableSearchable,
		defaultValue,
		shadowContainerStyle,
		multiple,
		optionRenderer,
		labelRenderer,
		disableShadow,
		popperStyle,
		value,
		containerStyle,
		inputValue,
		onInputChange,
		optionSelector,
		...rest
	} = props
	const classes = useStyles()

	// eslint-disable-next-line
	const ComboBoxPopper = function (paperProps: any) {
		return (
			<Popper
				{...paperProps}
				style={{...paperProps.style, width: paperProps.style?.width + 32, ...props.popperStyle}}
				className={classes.popper}
				placement='bottom-start'
				key='popper-dropdown'
			/>
		)
	}

	// eslint-disable-next-line
	const checkDefaultSelected = (option: any, value: any) => {
		if (optionSelector) {
			return optionSelector(option) === optionSelector(value)
		}
		if (optionFunction) {
			return optionFunction(option) === optionFunction(value)
		}
		return option === value
	}

	return (
		<ThemeProvider theme={theme}>
			<ShadowContainer
				hideShadow={disableShadow}
				style={{
					padding: '4px 16px',
					borderBottom: enableUnderline ? `1px solid ${color.grey500}` : 'none',
					borderBottomLeftRadius: enableUnderline ? 0 : 4,
					borderBottomRightRadius: enableUnderline ? 0 : 4,
					overflow: 'auto',
					...shadowContainerStyle,
				}}>
				<Autocomplete
					classes={{...classes}}
					options={options}
					getOptionLabel={option => (labelFunction && labelFunction(option)) || option}
					defaultValue={defaultValue}
					value={value}
					inputValue={inputValue}
					onInputChange={(event, newInputValue) => {
						onInputChange && onInputChange(event, newInputValue)
					}}
					getOptionDisabled={getOptionDisabledFunction}
					disableCloseOnSelect={multiple}
					multiple={multiple}
					noOptionsText={NO_OPTION_TEXT}
					getOptionSelected={checkDefaultSelected}
					fullWidth={true}
					ListboxProps={{style: {...popperStyle}, position: 'bottom-start'}}
					renderTags={(value: string[], getTagProps) =>
						value.map((option: string, index: number) => {
							return labelRenderer ? (
								<TextStyleWrapper>{labelRenderer(option, index, getTagProps)}</TextStyleWrapper>
							) : (
								<Badge
									maxWidth='200px'
									label={(optionFunction && optionFunction(option)) || option}
									{...getTagProps({index})}
									key={(optionFunction && optionFunction(option)) || option}
								/>
							)
						})
					}
					renderOption={(item: any, state: AutocompleteRenderOptionState) => {
						return optionRenderer ? (
							optionRenderer(item, state)
						) : (
							<TextStyleWrapper style={{display: 'flex', width: '100%'}} className={classes.option}>
								<span>{(optionFunction && optionFunction(item)) || item}</span>
								{multiple && state.selected && (
									<span style={{width: 10, height: 10}}>
										<Icons.Check style={{transform: 'scale(0.75)'}} />
									</span>
								)}
							</TextStyleWrapper>
						)
					}}
					renderInput={params => (
						<TextField
							label={props.label}
							{...params}
							variant='standard'
							placeholder={placeholder}
							className={classes.input}
							InputProps={{
								...params.InputProps,
								disableUnderline: true,
								readOnly: disableSearchable,
							}}
							style={{
								minWidth: 200,
								width: '100%',
								height: '100%',
								justifyContent: 'center',
								...props.inputContainer,
							}}
							{...textFieldProps}
						/>
					)}
					popupIcon={<Icons.ChevronDownSmll style={{height: 18}} />}
					PopperComponent={ComboBoxPopper}
					style={containerStyle}
					{...rest}
				/>
			</ShadowContainer>
		</ThemeProvider>
	)
}
