import {useEffect, useState} from 'react'

import {MinimalList} from 'components/List'
import {Icons, MaskedValue} from '@bakkt/components'
import {formatMaskedSSN, formatSSN} from 'utils/formatters'
import {useTranslation} from 'react-i18next'
import {EmbeddedWebState, useStore} from 'store'
import {NA} from 'components/Text'
import {useTaxIdentifier} from 'hooks'
import {usePartyDetails} from 'hooks/usePartyDetails'
import {ApiParty} from '@bakkt/api'

export interface TaxpayerIdListProps {
	partyProfile: EmbeddedWebState['partyProfile']
	party: ApiParty
	missingProfileFields: EmbeddedWebState['missingProfileFields']
}

export const TaxpayerIdList = ({partyProfile, missingProfileFields, party}: TaxpayerIdListProps) => {
	const {t} = useTranslation()

	const {getTaxIdentifier, loading, taxIdentifier, resetTaxIdentifier} = useTaxIdentifier()
	const {unmaskTaxId, TinLoading, TinResponse} = usePartyDetails()
	const [taxIdentifierValue, setTaxIdentifierValue] = useState(
		taxIdentifier ||
			missingProfileFields?.taxIdentifier ||
			partyProfile?.Party?.taxIdentifier ||
			party?.taxIdNumber,
	)

	const onTaxIdentifierIconClick = (): boolean => {
		if (party?.ew9CertificationRequired) {
			if (taxIdentifierValue?.includes('XXXXX')) unmaskTaxId()
			return true
		}
		if (!taxIdentifier && !missingProfileFields?.taxIdentifier) getTaxIdentifier()
		else if (taxIdentifier) resetTaxIdentifier()
		return true
	}

	useEffect(() => {
		if (TinResponse) {
			setTaxIdentifierValue(TinResponse)
		}
	}, [TinResponse])

	return (
		<MinimalList
			data={[
				{
					primaryText: t('inputLabel.SSN'),
					contentRight: taxIdentifierValue ? (
						<MaskedValue
							value={formatSSN(taxIdentifierValue)}
							maskedValue={formatMaskedSSN(taxIdentifierValue)}
							unMaskedIcon={Icons.Eyeball}
							maskedIcon={Icons.Eyeball}
							textStyle={{
								background: 'none',
								cursor: 'default',
								padding: 0,
								paddingRight: 8,
								margin: 0,
								fontWeight: 'normal',
								fontSize: '14px',
								userSelect: 'none',
							}}
							timeVisible={30000}
							onClick={onTaxIdentifierIconClick}
							loading={loading || TinLoading}
						/>
					) : (
						''
					),
				},
				{
					primaryText: t('inputLabel.EIN'),
					contentRight: '',
				},
			]}
		/>
	)
}
