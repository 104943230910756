import {Icons, Link} from '@bakkt/components'
import {showDialog} from 'components/Dialog'
import {useTranslation} from 'react-i18next'
import {color} from '@bakkt/components'
import {isMobile, isBrowser} from 'react-device-detect'
import {logout} from '../api'
import {embeddedPartnerApi} from 'api'
import {useStore} from 'store'
import {CSSProperties} from 'react'
import {handleKeyPress} from 'utils/handleKeyPress'
import {logger} from 'utils/logger/datadogInit'

const {deleteProfile} = embeddedPartnerApi
interface ExitOnboardingButtonProps {
	style: CSSProperties | null
}
const ExitOnboardingButton = ({style}: ExitOnboardingButtonProps) => {
	const {t} = useTranslation()
	const {partyProfile = {}, partner} = useStore()

	const onSave = () => {
		logger.info('Activate Crypto exit dialog - onSave invoked', {action: 'Yes or cancel', isMobile, isBrowser})

		deleteProfile(partyProfile?.profileId || '')
			.then(() => logout())
			.then(() => {
				const defaultReturnUrl = 'https://www.bakkt.com'
				if (isMobile) {
					window.location.href = partner?.mobileReturnUrl || partner?.desktopReturnUrl || defaultReturnUrl
				} else if (isBrowser) {
					window.location.href = partner?.desktopReturnUrl || defaultReturnUrl
				} else {
					window.location.href = defaultReturnUrl
				}
			})
			.catch(error => console.error(error))
	}

	return (
		<Link
			style={{
				marginTop: '28px',
				textDecoration: 'none',
				fontSize: '16px',
				padding: '2px',
				...style,
			}}
			onClick={() => {
				logger.info('Activate Crypto exit dialog - showDialog invoked', {
					title: t('crypto.accountActivate.cancelActivationTitle'),
					message: t('crypto.accountActivate.cancelActivationMessage'),
				})
				showDialog({
					title: t('crypto.accountActivate.cancelActivationTitle'),
					message: t('crypto.accountActivate.cancelActivationMessage'),
					saveText: t('crypto.accountActivate.cancelActivationYes'),
					cancelText: t('crypto.accountActivate.cancelActivationNo'),
					icon: Icons.DialogInfo,
					cancelButtonStyle: {border: 'none', fontSize: '16px', color: color.blue700},
					onSave: onSave,
				})
			}}
			onKeyPress={event => {
				logger.info('Activate Crypto exit dialog - showDialog invoked', {
					title: t('crypto.accountActivate.cancelActivationTitle'),
					message: t('crypto.accountActivate.cancelActivationMessage'),
				})
				handleKeyPress(
					event,
					showDialog({
						title: t('crypto.accountActivate.cancelActivationTitle'),
						message: t('crypto.accountActivate.cancelActivationMessage'),
						saveText: t('crypto.accountActivate.cancelActivationYes'),
						cancelText: t('crypto.accountActivate.cancelActivationNo'),
						icon: Icons.DialogInfo,
						cancelButtonStyle: {border: 'none', fontSize: '16px', color: color.blue700},
						onSave: onSave,
					}),
				)
			}}
			tabIndex={0}
			aria-label='Cancel'
			className={'cancelShareInfo'}
			data-dma='Close'
		>
			{t('crypto.accountActivate.cancelCryptoActivation')}
		</Link>
	)
}
export default ExitOnboardingButton
