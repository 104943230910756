import React from 'react'
import {color} from 'src/color/color'
import styled from '@emotion/styled'
import {useTheme} from '@material-ui/core'
/*
The custom number pad we have in some of our action flows.

Note: this numpad works with strings, not numbers.  Use the useDecimalNumberInputting hook
to keep the value in state and have use of both string and numeric values.

*/

export interface CustomNumPadProps {
	noDecimal?: boolean
	onChangeValue: (value: string) => void
	value: string
}

const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0', 'Delete']
export const BrownPro =
	"'BrownPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen-Sans', 'Ubuntu', 'Cantarell', 'Helvetica Neue', sans-serif"
const CustomNumPad = ({noDecimal, onChangeValue, value}: CustomNumPadProps) => {
	const theme = useTheme()

	const handleKeyPress = (key: string) => {
		return onChangeValue(key === 'Delete' ? value.slice(0, -1) : `${value !== '0' ? value : ''}${key}`)
	}

	const TouchableOpacity = styled.button`
		transition: all ease-out 0.2s;
		border: none;
		padding: 0;
		background: transparent;
		@keyframes pulse {
			0% {
				transform: scale(1);
				opacity: 1;
			}
			50% {
				transform: scale(0.9);
				opacity: 0.7;
			}
			100% {
				transform: scale(1);
				opacity: 1;
			}
		}
		&:focus {
			animation: pulse 0.2s 1 ease-in-out;
		}
	`
	return (
		<div
			style={{
				flexDirection: 'row',
				flexWrap: 'wrap',
				width: '100%',
				display: 'flex',
			}}>
			{keys.map(key => (
				<div
					key={key}
					style={{
						alignItems: 'center',
						height: 65,
						justifyContent: 'center',
						width: '33%',
						display: 'flex',
					}}>
					<TouchableOpacity
						disabled={key === '.' && noDecimal}
						onClick={() => handleKeyPress(key)}
						style={{width: '100%', height: '100%'}}>
						<span
							style={{
								fontFamily: BrownPro,
								fontWeight: 600,
								marginTop: 'auto',
								color:
									key === '.' && noDecimal
										? 'transparent'
										: theme?.palette?.text?.background || color.blue900,
								fontSize: key === 'Delete' ? 12 : 18,
							}}>
							{'' + key}
						</span>
					</TouchableOpacity>
				</div>
			))}
		</div>
	)
}

export default CustomNumPad
