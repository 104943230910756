import React, {useState, useRef, useEffect, useCallback} from 'react'
import {AreaChart, Area, ResponsiveContainer, Tooltip, TooltipProps} from 'recharts'
import {NameType, ValueType} from 'recharts/types/component/DefaultTooltipContent'
import {makeStyles} from '@material-ui/styles'
import {useTheme} from '@material-ui/core'

import {color} from 'src'
import {VBox} from '../container/VBox'
import {InputLabel} from '../text/Text'
import {Value} from '../value'

const useThemeColor = () => {
	const theme = useTheme()

	return {
		gridColor: '#f3f3fb',
		chartControlText: theme?.palette?.text?.background || 'black',
		chartControlBackground: theme?.palette?.background.paper || '#F5FDFF',
		chartStrokeColor: color.crypto,
	}
}

const CHART_PADDING_LEFT = 0
const CHART_PADDING_RIGHT = 0
const CHART_MARGIN_TOP = 5

const useStyles = makeStyles(() => {
	const theme = useTheme()
	const colors = useThemeColor()
	return {
		chart: {
			height: 200,
			paddingLeft: CHART_PADDING_LEFT,
			paddingRight: CHART_PADDING_RIGHT,
			borderBottom: `1px solid ${color.grey100}`,
		},
		chartControl: {
			padding: '10px 0',
			backgroundColor: colors.chartControlBackground,
			margin: '0 16px',
			display: 'flex',
			justifyContent: 'space-around',
			paddingLeft: '0',
		},
		chartControlItem: {
			listStyle: 'none',
			height: '38px',
			width: '38px',
			borderRadius: '50%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			border: '1px solid #b7b5bd',
			backgroundColor: 'transparent',
			fontFamily: theme?.typography?.fontFamily || 'BrownPro',
			fontSize: '16px',
			color: colors.chartControlText,
			WebkitTapHighlightColor: 'transparent',
			'&.clickActive': {
				backgroundColor: colors.chartControlBackground,
				border: 'none',
				fontWeight: 'bold',
			},
			outline: 0,
			'&:focus': {
				// TODO: get Theme color for focus highlight
				boxShadow: '0 0 0 3pt #71b3ff',
			},
		},
	}
})

export interface TimeRangeButton {
	label: string
	value: string
	ariaLabel?: string
}
export interface TimeSeriesChartProps {
	onTimeRangeChange: Function
	data: {price: number; timeStamp?: string}[]
	timeRangeButtons?: Array<TimeRangeButton>
	timeRangeButtonActive?: string
}

const BUTTON_ARRAYS = [
	{label: '1D', value: 'DAY'},
	{label: '1W', value: 'WEEK'},
	{label: '1M', value: 'MONTH'},
	{label: '1Y', value: 'YEAR'},
]

export const TimeSeriesChart = (props: TimeSeriesChartProps) => {
	const {data: chartData = [], timeRangeButtons, timeRangeButtonActive} = props
	const classes = useStyles()
	const colors = useThemeColor()
	const theme = useTheme()
	const timeRangeButtonList: Array<TimeRangeButton> = timeRangeButtons || BUTTON_ARRAYS

	const chartRef = useRef<HTMLDivElement>(null)

	const [isKeyboardFocused, setKeyboardFocused] = useState(false)
	const [currentChartPoint, setCurrentChartPoint] = useState(-1)
	const [tooltipPosition, setTooltipPosition] = useState({x: 0, y: 0})

	const [currentActiveTimeRange, setCurrentActiveTimeRange] = useState(
		timeRangeButtonActive || BUTTON_ARRAYS[0]?.value,
	)

	const onTimeRangeChange = (timeRange: string) => {
		setCurrentActiveTimeRange(timeRange)
		props.onTimeRangeChange(timeRange)
	}

	const handleButtonKeyPress = (event: any, value: string) => {
		if (event.key === 'Enter') {
			onTimeRangeChange(value)
		}
	}

	const handleChartKeyPress = useCallback(
		(event: any) => {
			if (!isKeyboardFocused) return

			if (event.key === 'ArrowLeft') {
				setCurrentChartPoint(Math.max(0, currentChartPoint - 1))
			} else if (event.key === 'ArrowRight') {
				const maxChartDataIndex = chartData.length > 0 ? chartData.length - 1 : 0
				setCurrentChartPoint(Math.min(maxChartDataIndex, currentChartPoint + 1))
			}
		},
		[isKeyboardFocused, currentChartPoint],
	)

	const calculateTooltipPosition = useCallback(() => {
		const calculateTooltipX = () => {
			const {offsetWidth = 0, offsetLeft = 0} = chartRef.current || {}
			const chartLeft = offsetLeft + CHART_PADDING_LEFT

			if (chartData.length <= 1) return chartLeft

			const chartWidth = offsetWidth - CHART_PADDING_LEFT - CHART_PADDING_RIGHT
			const spaceBetweenPoints = chartWidth / (chartData.length - 1)

			return chartLeft + spaceBetweenPoints * currentChartPoint
		}

		const calculateTooltipY = () => {
			const {offsetHeight = 0, offsetTop = 0} = chartRef.current || {}
			const chartBottom = offsetTop + offsetHeight + CHART_MARGIN_TOP

			if (chartData.length === 0 || !chartData[currentChartPoint]) return chartBottom

			const highestChartPrice = chartData.reduce((previousData, currentData) =>
				previousData.price > currentData.price ? previousData : currentData,
			)

			if (!highestChartPrice.price) return chartBottom

			const distanceFromBottom = offsetHeight * (chartData[currentChartPoint].price / highestChartPrice.price)
			return chartBottom - distanceFromBottom
		}

		setTooltipPosition({x: calculateTooltipX(), y: calculateTooltipY()})
	}, [currentChartPoint])

	useEffect(() => {
		calculateTooltipPosition()
	}, [currentChartPoint])

	useEffect(() => {
		window.addEventListener('resize', calculateTooltipPosition)
		window.addEventListener('keydown', handleChartKeyPress)

		calculateTooltipPosition()

		return () => {
			removeEventListener('resize', calculateTooltipPosition)
			removeEventListener('keydown', handleChartKeyPress)
		}
	}, [calculateTooltipPosition, handleChartKeyPress])

	const CustomTooltip = ({active, payload}: TooltipProps<ValueType, NameType>) => {
		if (!active) return null
		const data = payload?.[0].payload || {}
		const time = new Date(data.time)
		const timeDisplay =
			currentActiveTimeRange === 'DAY'
				? time.toLocaleTimeString()
				: time.toLocaleDateString(undefined, {month: 'short', day: 'numeric', year: undefined})

		return (
			<VBox>
				{data.time && (
					<InputLabel
						data-testid={'tooltip-time'}
						style={{color: theme?.palette?.text?.background || 'grey', transform: 'translateY(13px)'}}>
						{timeDisplay}
					</InputLabel>
				)}
				{data.price && (
					<Value
						data-testid={'tooltip-value'}
						style={{
							color: theme?.palette?.text?.background || 'black',
						}}>{`$${new Intl.NumberFormat().format(data.price)}`}</Value>
				)}
			</VBox>
		)
	}

	const KeyboardTooltip = () => (
		<>
			<div
				style={{
					position: 'absolute',
					left: tooltipPosition.x,
					top: chartRef.current?.offsetTop,
					height: chartRef.current?.offsetHeight,
					width: 1,
					borderLeft: `1px dashed ${theme?.palette?.text?.background || 'grey'}`,
				}}
			/>
			<div
				style={{
					position: 'absolute',
					left: tooltipPosition.x + 5, // padding to ensure tooltip text is not flush with line
					top: tooltipPosition.y,
				}}>
				<CustomTooltip active payload={[{payload: chartData[currentChartPoint]}]} />
			</div>
		</>
	)

	return (
		<div>
			<div
				tabIndex={0}
				onFocus={() => setKeyboardFocused(true)}
				onBlur={() => {
					setKeyboardFocused(false)
					setCurrentChartPoint(-1)
				}}
				aria-label='Please use right and left arrow keys to navigate currency graph.'
				className={classes.chart}
				ref={chartRef}>
				<ResponsiveContainer width={'100%'} height={200}>
					<AreaChart
						data={chartData}
						margin={{
							top: CHART_MARGIN_TOP,
							right: 0,
							left: 0,
							bottom: 0,
						}}>
						<Area dataKey='price' stroke={colors.chartStrokeColor} strokeWidth={3} fill='url(#bgChart)' />
						<defs>
							<linearGradient id='bgChart' x1='0' y1='0' x2='0' y2='1'>
								<stop offset='5%' stopColor={colors.chartStrokeColor} stopOpacity={0.8} />
								<stop offset='95%' stopColor={colors.chartStrokeColor} stopOpacity={0.1} />
							</linearGradient>
						</defs>
						{!isKeyboardFocused && (
							<Tooltip
								cursor={{
									stroke: theme?.palette?.text?.background || 'grey',
									strokeWidth: 1,
									strokeDasharray: 5,
								}}
								content={CustomTooltip}
								wrapperStyle={{
									backgroundColor:
										(theme?.palette?.type === 'dark' && 'rgba(35, 35, 35,0.95)') ||
										'rgba(255, 255, 255,0.85)',
									padding: '0 10px',
									borderRadius: '10px',
								}}
							/>
						)}
					</AreaChart>
				</ResponsiveContainer>
				{isKeyboardFocused && chartData[currentChartPoint] && <KeyboardTooltip />}
			</div>
			<ul className={classes.chartControl} data-testid={'chart-control'}>
				{timeRangeButtonList.map(({label, value, ariaLabel}, index) => (
					<li
						style={{width: '53px', borderRadius: '99px'}}
						data-testid={`chart-select-${label}`}
						aria-label={ariaLabel ? ariaLabel : label}
						role='button'
						tabIndex={0}
						id={`timeRangeButton-${index}`}
						key={index}
						className={[classes.chartControlItem, currentActiveTimeRange === value ? 'clickActive' : null]
							.filter(item => item)
							.join(' ')}
						onClick={() => onTimeRangeChange(value)}
						onKeyPress={event => handleButtonKeyPress(event, value)}>
						{label}
					</li>
				))}
			</ul>
		</div>
	)
}
