import create, {StateCreator} from 'zustand'
import {devtools, persist, PersistOptions} from 'zustand/middleware'
import {
	ApiAmount,
	ApiEmbeddedFundingAccount,
	ApiEmbeddedPartnerPartyAccount,
	ApiEmbeddedPartnerPartyProfileResponse,
	BasicPartnerPartyLink,
	BasicPartyInfo,
	BasicPartnerInfo,
	ApiPartnerPartyEnrollmentData,
	ApiEmbeddedCryptoTransactionResponse,
	ApiEmbeddedPartnerTransactionOperationTypeEnum,
	ApiAmountCurrencyEnum,
	FiatAmountCurrencyEnum,
	FiatAmount,
	ApiEmbeddedCryptoTransactionRequestTransactTypeEnum,
	CryptoOrFiatAmountCurrencyEnum,
	ApiError,
	ApiCryptoCurrencyConfig,
} from '@bakkt/api'
import {setUserProperties} from 'utils/firebase/analytics'

import {Application as BakktComponentApplication} from '@bakkt/components'
import {loadOnStartup, embeddedPartnerApi, apiCall} from '../api'
import {AnalyticsFlags} from 'utils/firebase/analyticsProperties'
import {showOnboardingErrorDialog, showSuspendedErrorDialog} from 'components/ErrorDialog'

export type Amount = ApiAmount | FiatAmount | {amount?: number; currency?: CurrencyEnum}
export type TransactTypeEnum =
	| ApiEmbeddedPartnerTransactionOperationTypeEnum
	| ApiEmbeddedCryptoTransactionRequestTransactTypeEnum
export const TransactType = ApiEmbeddedPartnerTransactionOperationTypeEnum
export type FundingAccount = ApiEmbeddedFundingAccount
export type PartnerPartyProfile = ApiEmbeddedPartnerPartyProfileResponse
export type PartnerPartyAccount = ApiEmbeddedPartnerPartyAccount & {isDisabled?: boolean}
export type CurrencyEnum =
	| ApiAmountCurrencyEnum
	| ApiAmountCurrencyEnum
	| FiatAmountCurrencyEnum
	| CryptoOrFiatAmountCurrencyEnum
	| undefined

export type CryptoCurrencyConfig = ApiCryptoCurrencyConfig
export const Currency = ApiAmountCurrencyEnum || ApiAmountCurrencyEnum || FiatAmountCurrencyEnum
export interface CryptoTransactionConfirm {
	price: Amount
	quantity: Amount
	transactType?: TransactTypeEnum
	unitPrice?: Amount
	transactionFee: Amount
	totalPrice: Amount
}
export type HistoricalPeriod = 'YEAR' | 'MONTH' | 'WEEK' | 'DAY' | 'HOUR'

type NULL = null | undefined
export type EmbeddedWebState = {
	partner: BasicPartnerInfo | NULL
	partnerPartyLink: BasicPartnerPartyLink | NULL
	party: BasicPartyInfo | NULL
	partyProfile: PartnerPartyProfile | NULL
	lastError: ApiError | NULL
	loadProfile: () => void
	loadOnStartup: () => void
	missingProfileFields: Partial<ApiPartnerPartyEnrollmentData> | NULL
	updateMissingProfileFields: (missingProfileFields: Partial<ApiPartnerPartyEnrollmentData>) => void
	logout: () => void
	fundingAccounts: FundingAccount[]
	setFundingAccounts: (fundingAccounts: FundingAccount[]) => void
	selectedFundingAccount: FundingAccount | null
	setSelectedFundingAccount: (selectedFundingAccount: FundingAccount) => void
	selectedFundingAccountId: string | null
	setSelectedFundingAccountId: (selectedFundingAccountId: string) => void
	cryptoAccounts: PartnerPartyAccount[]
	setCryptoAccounts: (cryptoAccounts: PartnerPartyAccount[]) => void
	cryptoCurrencyDetails: CryptoCurrencyConfig[] | undefined
	setCryptoCurrencyDetails: (cryptoCurrencyDetails: CryptoCurrencyConfig[]) => void
	isEnrolled: Boolean
	fiatCurrencyPreference: CurrencyEnum
	selectedCryptoAccount: PartnerPartyAccount | null
	setSelectedCryptoAccount: (selectedCryptoAccount: PartnerPartyAccount) => void
	selectedCryptoAccountCurrency: string | null
	setSelectedCryptoAccountCurrency: (selectedCryptoAccountCurrency: string) => void
	cryptoTransaction: ApiEmbeddedCryptoTransactionResponse | null
	setCryptoTransaction: (cryptoTransaction: ApiEmbeddedCryptoTransactionResponse | null) => void
	cryptoTransactionConfirm: CryptoTransactionConfirm | null
	setCryptoTransactionConfirm: (cryptoTransactionConfirm: CryptoTransactionConfirm | null) => void
	resetToHome: () => void
	isSuspended: Boolean
	setIsSuspended: (isSuspended: boolean) => void
	loadDefaultFundingSource: () => void
	activePartnerTransactionId: string | null
	setActivePartnerTransactionId: (activePartnerTransactionId: string | null) => void
	timezone: string
	syncExecutePrices: boolean
	setSyncExecutePrices: (syncExecutePrices: boolean) => void
}

type EmbeddedWebPersist = (
	config: StateCreator<EmbeddedWebState>,
	options: PersistOptions<EmbeddedWebState>,
) => StateCreator<EmbeddedWebState>

export const useStore = create<EmbeddedWebState>(
	devtools(
		(persist as EmbeddedWebPersist)(
			(set, get) => ({
				syncExecutePrices: false,
				setSyncExecutePrices: (syncExecutePrices: boolean) => set({syncExecutePrices}),
				partner: null,
				partnerPartyLink: null,
				party: null,
				loadOnStartup: async () => {
					const partyInfo = await loadOnStartup()
					setUserProperties({
						[AnalyticsFlags.USERNAME]: partyInfo?.partnerPartyLink?.userName,
						[AnalyticsFlags.PARTY_PARTNER_LINK_ID]: partyInfo?.partnerPartyLink?.partnerPartyLink?.id,
						[AnalyticsFlags.PARTNER_ID]: partyInfo?.partner?.id,
						[AnalyticsFlags.PARTNER_NAME]: partyInfo?.partner?.name,
						[AnalyticsFlags.PARTY_ID]: partyInfo?.party?.id,
					})
					const TIME_ZONE = 'America/New_York'
					BakktComponentApplication.timezone = TIME_ZONE

					const isEnrolled = !!partyInfo?.partnerPartyLink?.partnerPartyLink
					if (isEnrolled) {
						get().loadDefaultFundingSource()
					}

					set({
						partner: partyInfo?.partner,
						partnerPartyLink: partyInfo?.partnerPartyLink,
						party: partyInfo?.party,
						isEnrolled,
						timezone: TIME_ZONE,
						cryptoCurrencyDetails: partyInfo?.currencyConfigs,
					})
				},
				timezone: '',
				partyProfile: null,
				lastError: null,
				isEnrolled: false, //TODO this is derived data, so make it function
				loadProfile: async () => {
					const {response, error}: any = await apiCall(embeddedPartnerApi.fetchPartnerPartyProfile)
					if (
						error &&
						!(error.code === 'PARTY_SUSPENDED' || error.code === 'ACCOUNT_EXISTS_ANOTHER_PARTNER')
					) {
						showOnboardingErrorDialog(get().partner)
					}

					set({
						partyProfile: response,
						lastError: error,
					})
				},
				missingProfileFields: null,
				updateMissingProfileFields: (missingProfileFields: Partial<ApiPartnerPartyEnrollmentData>) => {
					set({
						missingProfileFields,
					})
				},
				logout: () =>
					set({
						partner: null,
						partnerPartyLink: null,
						selectedCryptoAccount: null,
						selectedCryptoAccountCurrency: null,
						fundingAccounts: [],
						selectedFundingAccount: null,
						cryptoAccounts: [],
						cryptoCurrencyDetails: [],
					}),
				fundingAccounts: [],
				setFundingAccounts: (fundingAccounts: FundingAccount[]) => set({fundingAccounts}),
				selectedFundingAccount: null,
				setSelectedFundingAccount: (selectedFundingAccount: FundingAccount) => set({selectedFundingAccount}),
				selectedFundingAccountId: null,
				setSelectedFundingAccountId: (selectedFundingAccountId: string) => set({selectedFundingAccountId}),
				cryptoAccounts: [],
				setCryptoAccounts: (cryptoAccounts: PartnerPartyAccount[]) => set({cryptoAccounts}),
				cryptoCurrencyDetails: [],
				setCryptoCurrencyDetails: (cryptoCurrencyDetails: CryptoCurrencyConfig[]) =>
					set({cryptoCurrencyDetails}),
				selectedCryptoAccount: null,
				setSelectedCryptoAccount: (selectedCryptoAccount: PartnerPartyAccount) => set({selectedCryptoAccount}),
				selectedCryptoAccountCurrency: null,
				setSelectedCryptoAccountCurrency: (selectedCryptoAccountCurrency: string) =>
					set({selectedCryptoAccountCurrency}),
				fiatCurrencyPreference: ApiAmountCurrencyEnum.USD,
				cryptoTransaction: null,
				setCryptoTransaction: (cryptoTransaction: ApiEmbeddedCryptoTransactionResponse | null) => {
					set({
						cryptoTransaction,
					})
				},
				activePartnerTransactionId: null,
				setActivePartnerTransactionId: (activePartnerTransactionId: string | null) => {
					set({
						activePartnerTransactionId,
					})
				},
				cryptoTransactionConfirm: null,
				setCryptoTransactionConfirm: (cryptoTransactionConfirm: CryptoTransactionConfirm | null) => {
					set({
						cryptoTransactionConfirm,
					})
				},
				resetToHome: async () => {
					get().loadDefaultFundingSource()

					set({
						cryptoTransactionConfirm: null,
						cryptoTransaction: null,
					})
				},
				isSuspended: false,
				setIsSuspended: (isSuspended: boolean) => set({isSuspended}),
				loadDefaultFundingSource: async () => {
					const {selectedFundingAccountId, partner} = get()
					const {response, error} = await apiCall(() =>
						embeddedPartnerApi.fetchFundingAccounts(ApiAmountCurrencyEnum.USD),
					)
					if (error && error.code !== 'PARTY_SUSPENDED') {
						showSuspendedErrorDialog(partner)
					}

					const selectedAccount = (response || []).find(
						account => account.externalAccountRef === selectedFundingAccountId,
					)

					set({
						fundingAccounts: response || [],
						selectedFundingAccount: selectedAccount || response?.[0] || null,
						selectedFundingAccountId: selectedAccount?.externalAccountRef || null,
					})
				},
			}),
			{
				name: 'bakkt-embedded-web-storage',
				getStorage: () => sessionStorage,
				partialize: state => ({
					selectedCryptoAccount: state.selectedCryptoAccount,
					selectedCryptoAccountCurrency: state.selectedCryptoAccountCurrency,
					selectedFundingAccountId: state.selectedFundingAccountId,
				}),
			},
		),
	),
)
