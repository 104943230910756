import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
	typography: {paddingLeft: theme.spacing(1.5), paddingRight: theme.spacing(1)},
}))

export const PopOver = (props: {handleIconState?: (b: boolean) => void} & {children: any}) => {
	const classes = useStyles()
	const [anchorEl, setAnchorEl] = React.useState(null)
	const handleClick = (event: any) => {
		props.handleIconState && props.handleIconState(true)
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		props.handleIconState && props.handleIconState(false)
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	return (
		<div>
			<div onClick={handleClick}>{React.Children.toArray(props.children)[0]}</div>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				data-dma='Close'
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}>
				<Typography className={classes.typography}>{React.Children.toArray(props.children)[1]}</Typography>
			</Popover>
		</div>
	)
}
