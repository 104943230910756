import React from 'react'
import styled from '@emotion/styled'
import {color} from 'src/color/color'

const DefaultTitle = styled.span`
	font-family: ${props => props?.theme?.typography?.fontFamily || 'BrownPro'};
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 0.005em;
	color: ${color.green900};
`

export const DetailViewTitle: React.FC = ({children}) => <DefaultTitle>{children}</DefaultTitle>
