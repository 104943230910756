import {isBrowser, isMobile} from 'react-device-detect'
import {EmbeddedWebState} from 'store'

export const returnToPartner = (partner?: EmbeddedWebState['partner']) => {
	const defaultReturnUrl = 'https://www.bakkt.com'
	if (isMobile) {
		window.location.href = partner?.mobileReturnUrl || partner?.desktopReturnUrl || defaultReturnUrl
	} else if (isBrowser) {
		window.location.href = partner?.desktopReturnUrl || defaultReturnUrl
	} else {
		window.location.href = defaultReturnUrl
	}
}
