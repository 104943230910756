import * as React from 'react'
import {Button} from './components/button/Button'
import {H4} from './components/text/Text'

export type MultiPageProps = {
	selectedPageIndex?: number
	saveValid?: boolean
	nextValid?: boolean
	previousValid?: boolean
	onCancel: () => void
	onSave: () => void
	onNext: () => Promise<boolean>
	onPrevious: () => Promise<boolean>
	saveButtonText?: string
	cancelButtonText?: string
	nextButtonText?: string
	previousButtonText?: string
	titleList: string[]
	hideNext?: boolean
	hidePrevious?: boolean
} & React.HTMLProps<any>

export const MultiPage: React.FC<MultiPageProps> = (props: MultiPageProps) => {
	const [pageIndex, setPageIndex] = React.useState(props.selectedPageIndex || 0)

	const {
		onCancel: handleCancel,
		onSave: handleSave,
		saveButtonText = 'Save',
		cancelButtonText = 'Cancel',
		nextButtonText = 'Next',
		previousButtonText = 'Previous',
		titleList = [],
	} = props
	const noOfModals = React.Children.toArray(props.children).length || 0
	function onCancel() {
		handleCancel()
	}
	function onSave() {
		handleSave()
	}
	function nextPageIndex() {
		const currentPage = pageIndex
		return Number(currentPage) + 1
	}
	function previousPageIndex() {
		const currentPage = pageIndex
		return Number(currentPage) - 1
	}
	const onNext = async () => {
		const status = await props.onNext()
		const pageIndexToGo = nextPageIndex()
		if (status) {
			setPageIndex(pageIndexToGo)
		}
	}
	function onPrevious() {
		const status = props.onPrevious()
		const pageIndexToGo = previousPageIndex()
		// @ts-ignore
		if (status) {
			setPageIndex(pageIndexToGo)
		}
	}
	return (
		<div style={{...props.style, display: 'flex', flexDirection: 'column'}} className={props.className}>
			<H4 style={{color: '#100722', fontWeight: 700, marginTop: 0, marginBottom: '5%', lineHeight: '21.78px'}}>
				{titleList[pageIndex]}
			</H4>
			{React.Children.toArray(props.children)[pageIndex]}

			<div style={{display: 'flex', flexDirection: 'column', margin: '2rem 0 0 0'}}>
				<div style={{display: 'flex', flexDirection: 'row', marginBottom: '1rem'}}>
					<div style={{alignSelf: 'flex-start'}}>
						{pageIndex !== 0 && !props?.hidePrevious && (
							<Button
								outline={true}
								style={{
									marginRight: 30,
									minWidth: 75,
								}}
								disabled={!props.previousValid}
								onClick={() => onPrevious()}>
								{previousButtonText}
							</Button>
						)}
					</div>
					<div style={{float: 'right', marginLeft: 'auto'}}>
						<Button
							outline={true}
							style={{
								marginRight: 10,
								minWidth: 75,
							}}
							onClick={onCancel}>
							{cancelButtonText}
						</Button>

						<Button
							style={{
								marginRight: noOfModals > pageIndex + 1 ? 10 : 0,
								minWidth: 75,
							}}
							disabled={!props.saveValid}
							onClick={onSave}>
							{saveButtonText}
						</Button>

						{noOfModals !== pageIndex + 1 && !props?.hideNext && (
							<Button style={{minWidth: 75}} disabled={!props.nextValid} onClick={() => onNext()}>
								{nextButtonText}
							</Button>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
