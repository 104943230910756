import React from 'react'
import {Page as BakktPage} from '@bakkt/components'
import {PageProps as BakktPageProps} from '@bakkt/components/types/components/layout/Page'

export type PageProps = {
	title?: string
} & BakktPageProps

const Page = ({children, title, titleHeader, ...props}: PageProps) => {
	const getTitle = () => {
		if (title) {
			return `Bakkt - ${title}`
		} else if (titleHeader) {
			return `Bakkt - ${titleHeader}`
		} else {
			return 'Bakkt'
		}
	}

	return (
		<BakktPage title={getTitle()} titleHeader={titleHeader} {...props}>
			{children}
		</BakktPage>
	)
}

export default Page
