import {ButtonHTMLAttributes, useEffect, useState, CSSProperties} from 'react'
import {ApiAmount} from '@bakkt/api'
import {formatCrypto, formatUSD} from 'utils/currencyFormatters'
import {Icons, color, HBox, VBox, Light, Large, useTheme, Logo} from '@bakkt/components'
import {IndicativePriceDisplay} from '../../../../../components/IndicativePriceDisplay'
import {useCryptoCurrencyPrices, useCryptoHistoricalCurrency} from '../../../../../hooks'
import {Theme} from '@material-ui/core'
import {cryptoPercentChange} from 'utils/percentChange'
import {useCryptoCurrencyDetails} from 'hooks/useCryptoCurrencyDetails'

interface CryptoAccountItemProps extends ButtonHTMLAttributes<HTMLDivElement> {
	accountBalance?: ApiAmount
	indicativeBalance?: ApiAmount
	endIcon?: React.ReactElement
	style?: CSSProperties
	disabled?: boolean
}

const CryptoAccountItem = ({
	accountBalance,
	indicativeBalance,
	endIcon,
	style = {},
	disabled,
	...props
}: CryptoAccountItemProps) => {
	const cryptoCurrency: string = accountBalance?.currency || ''
	const currencyDetail = useCryptoCurrencyDetails(cryptoCurrency)
	const currencyName = currencyDetail ? currencyDetail?.name : cryptoCurrency
	const cryptoAccountImage = currencyDetail ? currencyDetail?.iconURL : null
	const testIdPrefix = currencyName?.toLowerCase().replace(/\s/g, '-')
	const {cryptoCurrencyPrice} = useCryptoCurrencyPrices(cryptoCurrency)
	const {historicalCryptoCurrency} = useCryptoHistoricalCurrency(cryptoCurrency)
	const [changeInPrice, setChangeInPrice] = useState<number>(0)
	const theme = useTheme() as Theme

	useEffect(() => {
		if (!historicalCryptoCurrency.length) return
		setChangeInPrice(Number(cryptoPercentChange(historicalCryptoCurrency)))
	}, [historicalCryptoCurrency])

	return (
		<div
			className='crypto-account'
			data-testid={`${testIdPrefix}-row`}
			style={{
				cursor: 'pointer',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				borderBottom: `1px solid ${color.grey100}`,
				padding: '28px 0 18px 0',
				paddingLeft: 3,
				paddingRight: 2,
				...style,
			}}
			{...props}
		>
			<HBox horizontalGap={5}>
				<span
					className='cryptoLogoContainer'
					style={{width: 30, height: 30, opacity: disabled ? 0.4 : 1}}
					data-testid='crypto-logo'
				>
					<style>{`.cryptoLogoContainer svg { transform: scale(0.65) translate(-10px, -10px); }`}</style>
					{cryptoAccountImage && <Logo currencyDetail={currencyDetail} width='28' />}{' '}
				</span>

				<Large
					data-testid={`${testIdPrefix}-label`}
					style={{margin: 'auto', marginBottom: 0, paddingBottom: 0}}
				>
					<span data-testid='crypto-name'>{currencyName}</span>
					<span
						style={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'row',
							fontSize: '12px',
							lineHeight: '14.32px',
						}}
					>
						<span data-testid='crypto-value'>
							<IndicativePriceDisplay
								selectedCurrency={accountBalance?.currency}
								indicativePrice={cryptoCurrencyPrice?.indicativePrice}
							/>
						</span>
						<span
							data-testid='crypto-change'
							style={{
								marginLeft: 8,
								color: changeInPrice >= 0 ? theme?.palette.success.main : theme?.palette.warning.main,
							}}
						>
							{changeInPrice >= 0 ? '+' : ''}
							{`${changeInPrice}%`}
						</span>
					</span>
				</Large>
			</HBox>
			<div style={{display: 'inline-flex', alignItems: 'center'}}>
				<HBox horizontalGap={15}>
					<VBox verticalGap={1}>
						<Light
							data-testid={`${testIdPrefix}-balance-usd`}
							style={{
								fontSize: '16px',
								lineHeight: '19.09px',
								marginLeft: 'auto',
								marginBottom: 'auto',
								marginTop: '5px',
							}}
						>
							{formatUSD(indicativeBalance?.amount, indicativeBalance?.currency)}
						</Light>
						{accountBalance && (
							<Light
								data-testid={`${testIdPrefix}-balance-crypto`}
								style={{fontSize: '11px', marginBottom: 'auto', marginTop: 0, textAlign: 'right'}}
							>{`${formatCrypto(
								accountBalance?.amount,
								accountBalance?.currency,
								currencyDetail?.supportedPrecision,
							)}`}</Light>
						)}
					</VBox>
					<VBox style={{margin: 'auto'}}>
						{!!endIcon ? (
							endIcon
						) : (
							<Icons.ChevronRightSmll
								fill={theme?.palette?.primary?.main}
								data-testid={`navigate-to-${testIdPrefix}`}
							/>
						)}
					</VBox>
				</HBox>
			</div>
		</div>
	)
}

export default CryptoAccountItem
