import {ReactNode, useEffect, useMemo, useState} from 'react'
import {defaultLightTheme, BakktThemeProvider, createThemeFromApi, defaultDarkTheme} from '@bakkt/components'

import {useStore} from 'store'
import {getSessionStorageKey} from 'utils/sessionStorage'
import {SessionStorageKeys} from 'utils/sessionStorageProperties'

type Props = {
	children: ReactNode
}

export const EmbeddedThemeProvider = ({children}: Props) => {
	const {partner} = useStore()
	const [themeFromLS, setThemeFromLS] = useState('')

	useEffect(() => {
		window.addEventListener('storage', checkThemeFromStorage)
		return () => {
			window.removeEventListener('storage', checkThemeFromStorage)
		}
	}, [])

	const currentTheme = useMemo(() => {
		const themeFromLocalStorage = localStorage.getItem('theme')
		if (themeFromLocalStorage) {
			const themeObj = JSON.parse(themeFromLocalStorage as string)
			console.log(`Rendering theme ${themeObj?.name} from Local storage.`)
			return themeObj
		}
		let isDarkTheme =
			getSessionStorageKey(SessionStorageKeys.URL_THEME) ||
			new URLSearchParams(window.document.location.search).get('theme')
		let theme
		if (isDarkTheme) {
			theme = isDarkTheme === 'dark' ? partner?.darkTheme : partner?.lightTheme
			console.log(`Rendering theme ${isDarkTheme} from URLSearchParams / Session storage`)
		} else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
			theme = partner?.darkTheme
			isDarkTheme = 'dark'
			console.log(`Rendering  dark theme from OS`)
		} else {
			theme = partner?.lightTheme
			console.log(`Rendering light Theme from 'lightTheme'`)
		}
		const finalTheme = createThemeFromApi(theme, isDarkTheme === 'dark' ? defaultDarkTheme : defaultLightTheme)
		console.log(
			finalTheme.name,
			window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'OS dark' : 'OS light',
		)

		return finalTheme
	}, [partner, themeFromLS])

	const checkThemeFromStorage = () => {
		setThemeFromLS(localStorage.getItem('theme') as string)
	}

	return <BakktThemeProvider theme={currentTheme}>{children}</BakktThemeProvider>
}
